import React, { useEffect } from "react";
import { Input, Modal, Form, message } from "antd";
import { DatePicker, Space } from 'antd';
import { ApiHelper } from "../../../../utils/Helpers";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { messages } from "../../../../utils/Common/Messages";
import moment from "moment";
const EditClockTime = (props) => {
  const {editData,lan} = props
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      clockIn:moment(editData.clockIn),
      clockOut:moment(moment(editData.clockOut)),
      notes:editData.note
    });
  }, [props.editData]);

  return (
    <>
      <Modal
        title={"Edit Time Clock"}
        visible={props.isModalVisible}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              let payload = {
                timeClockManagementId:editData.timeClockManagementId,
                clockIn:moment(values.clockIn).format("YYYY-MM-DD HH:mm"),
                clockOut:moment(values.clockOut).format("YYYY-MM-DD HH:mm"),
                notes:values.notes
              }
              
              const response = await new ApiHelper().FetchFromServer(
                ApiRoutes.TIME_CLOCK_EDIT.service,
                ApiRoutes.TIME_CLOCK_EDIT.url,
                ApiRoutes.TIME_CLOCK_EDIT.method,
                ApiRoutes.TIME_CLOCK_EDIT.authenticate,
                undefined,
                payload
              );
              if(response.code===200)
              {
                form.resetFields();
                message.success({
                  content: response.messages[0],
                  duration: 2
                });
                props.handleCancel()
              }
              else {
                message.error({
                  content: response.messages[0],
                  duration: 2
                });
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        okText={messages.ok[lan]}
        cancelText={messages.cancel[lan]}
        onCancel={props.handleCancel}
      >
        <Form
            form={form}
            layout="vertical"
            name="reporting_hours_form"
          >
            <>
              <Form.Item
                name="clockIn"
                label={"ClockIn"}
                rules={[
                  {
                    required: true,
                    message:"Clock In is required"
                  }
                ]}
              >
                <DatePicker 
                showTime 
                format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
              <Form.Item
                name="clockOut"
                label={"Clock Out"}
                rules={[
                  {
                    required: true,
                    message:"Clock out is required"
                  }
                ]}
              >
                <DatePicker 
                showTime
                format="YYYY-MM-DD HH:mm"
                />

              </Form.Item>
              <Form.Item
                name="notes"
                label={"Notes"}
                rules={[
                  {
                    required: true,
                    message:"Notes is required"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              
            </>
          </Form>
      </Modal>
    </>
  );
};
export default EditClockTime;
