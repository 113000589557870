import React from "react";
import { Select } from "antd";
import { messages } from "../../../utils/Common/Messages";
import { getUserData } from "../../../utils/Helpers";
const { Option } = Select;

const Itemsrank = (props) => {
  const lan = getUserData().language ?? "EN";
  const { selectvalue, updateLimit, filterData } = props;
  const selectOnChange = (value) => {
    updateLimit(
      filterData.startDate,
      filterData.endDate,
      filterData.shiftId,
      filterData.interval,
      filterData.sId,
      value
    );
  };
  return (
    <Select
      value={selectvalue}
      style={{ width: 120 }}
      onChange={selectOnChange}
    >
      <Option value="5">{messages.topFiveItems[lan]}</Option>
      <Option value="10">{messages.topTenItems[lan]}</Option>
    </Select>
  );
};
export default Itemsrank;
