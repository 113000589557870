import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Row, Select, message } from 'antd'
import { useHistory } from 'react-router-dom';
import AxiosService from "../../../../utils/Config/ApiService";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import SupportLoader from '../../../../utils/Config/SupportLoader';

const AddDistributor = () => {

  const history = useHistory();
  
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [loader, setLoader] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [phoneCode, setPhoneCode] = useState([]);

  const handleFinish = async ( values ) => {
    values['businessPhoneCode'] = "+" + phoneCode.find(e => e.countryId == form.getFieldValue("country"))?.phoneCode
    values['isAgent'] = values['isAgent'] === true ? 1 : 0
    console.log("ADD DISTRIBUTOR", values)
    setLoader(true)
    try {
      let res = await AxiosService.post(`${ApiRoutes.ADD_DISTRIBUTOR.service}${ApiRoutes.ADD_DISTRIBUTOR.url}`, values);
      if(res.status === 201){
        setLoader(false);
        history.goBack();
      }
    } catch (error) {
        setLoader(false);
        messageApi.open({
          type: "error",
          content: error.response.data.message ?? error.response.data.error
        })
      console.log(error)
    }
  }

  const handleGetCountries = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.COUNTRY.service}${ApiRoutes.COUNTRY.url}`);
      if (res.data.statusCode === 200) {
        setCountries([
          ...res.data.data?.map((ele) => ({
            value: ele?.countryId,
            label: ele?.name,
          })),
        ]);
        setPhoneCode([
          ...res.data.data?.map((ele) => ({
            countryId: ele?.countryId,
            phoneCode: ele?.phoneCode,
          })),  
        ])        
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetStates = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.STATE.service}${ApiRoutes.STATE.url}/${id}`);
      if (res.data.statusCode === 200) {
        setStates([
          ...res.data.data?.map((ele) => ({
            value: ele?.stateId,
            label: ele?.stateName,
          })),
        ])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCities = async (id) => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.CITY.service}${ApiRoutes.CITY.url}/${id}`);
      if (res.data.data.length > 0) {
        setCities([
          ...res.data.data?.map((ele) => ({
            value: ele?.cityId,
            label: ele?.cityName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    handleGetCountries() 
    // By Default USA Is Selected, Hence We Are Getting States Of USA
    handleGetStates(1)
  },[])

  return (
    <>
    {contextHolder}
    <div className='bg-white' style={{ padding: "20px" }}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        className="editProfileForm"
        style={{ width: "100%" }}
        initialValues={{...form, 
        country: 1
        }}
        onFinish={handleFinish}
      >
        <Row gutter={8} >
            <Col span={12}>
              <Form.Item
                label="Business Name"
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Business Name",
                  },
                ]}
              >
                <Input placeholder="Business Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Name"
                name="contactName"
                type="text"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contact Name",
                  },
                ]}
              >
                <Input placeholder="Contact Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Email",
                  },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please Enter Valid Email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Business Phone"
                name="businessPhone"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Phone Number",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please Enter Valid Phone Number"
                  }
                ]}
              >
                <Input
                  placeholder="Business Phone"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please Select Country",
                  },
                ]}
              >
                <Select
                  placeholder="Country"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  disabled
                  options={countries}
                  onChange={(value) => (
                    handleGetStates(value),
                    form.setFieldsValue({
                      state: null,
                      city: null,
                    }),
                    setCities([])
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}
              >
                <Select
                  placeholder="State"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  options={states}
                  onChange={(value) => (
                    handleGetCities(value),
                    form.setFieldsValue({
                      city: null,
                    })
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please Select City",
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                  showSearch={true}
                  options={cities}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Street Address"
                name="streetAddress"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Street Address",
                  },
                ]}
              >
                <Input
                  placeholder="Street Address"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Zip Code"
                name="zipcode"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Zip Code",
                  },
                  {
                    pattern: /^[0-9]{5}$/,
                    message:"Please Enter Valid Zip Code"
                  }
                ]}
              >
                <Input
                  placeholder="Zip Code"
                />
              </Form.Item>
            </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24} className="mb-1 custom--checkbox">
            <Form.Item
              name="isAgent"
              valuePropName="checked"
            >
              <Checkbox
              >
                Agent
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="text-right mb-1">
          <Button
            type="primary"
            className="mr-3 btn--large"
            onClick={()=>history.goBack()}
          >
            Back
          </Button>
          <Button
            type="warning"
            htmlType="submit"
            className="btn--large"
          >
            { loader ? <SupportLoader/> : "Submit" }
          </Button>
        </div>
      </Form>        
    </div>
    </>
  )
}

export default AddDistributor