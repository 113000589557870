import { Button, Col, Row, Form, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import PaginatedTable from "../../../common/PaginatedTable";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import popupclose from "../../../../assets/images/support/popupclose-icon.svg";
import AxiosService from "../../../../utils/Config/ApiService";
import SupportLoader from "../../../../utils/Config/SupportLoader";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
export const StoreNotes = ({storeid, parentLoader, toggle,type}) => {
  const pageNumberLimit = 3;
  const [pageloader, setpageloader] = useState(true);
  const [isloader, setisloader]= useState(false)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [addToggle, setAddToggle] = useState(false);
  const [storenotes, setstorenotes] = useState([])

  let [isLoading, setIsLoading] = useState(true);

  //useState is required if we need column filter
  const [column, setColumn] = useState([
    {
      heading: () => "Notes Type",
      cell: (row) => row["title"],
      hidden: false,
      width: "200",
      id: 2,
    },
    {
      heading: () => "User Id",
      cell: (row) => row["userName"],
      hidden: false,
      width: "200",
      id: 3,
    },
    {
      heading: () => "Comment",
      cell: (row) => row["comment"],
      hidden: false,
      width: "100",
      id: 4,
    },
    {
      heading: () => "Date/Time",
      cell: (row) => {
      const date  = new Date(row['createdDt']).toString().slice(4,15)
      const requiredDate = date.substring(0,6)+","+date.substring(7)+" "+ new Date(row['createdDt']).toLocaleString('en-US', { hour: '2-digit', minute: 'numeric',second:'numeric',  hour12: true })
      return requiredDate;
    },
      hidden: false,
      width: "100",
      id: 5,
    },
  ]);
  //required to get the filtered column
  let filterOptions = [];
  //default checked values of select options
  let filterDefaultValue = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 13 },
    { value: 14 },
  ];

  //generate the options of multi select
  column.forEach((e) => {
    let option = {
      label: e.id === 13 ? "Status" : e.heading(),
      value: e.id,
    };
    filterOptions.push(option);
  });

  //to handle the change of the react-multi select
  const handleFilterSelection = (options) => {
    let newColumn = [...column];
    for (let i = 0; i < newColumn.length; i++) {
      let data = options.filter((e) => newColumn[i].id === e.value);
      data.length === 1
        ? (newColumn[i].hidden = false)
        : (newColumn[i].hidden = true);
    }
    setColumn(newColumn);
  };



  const handleGetStoreNotes =   async () =>{
    try{
        let res = await AxiosService.get(`${ApiRoutes.STORE_NOTES.service}${ApiRoutes.STORE_NOTES.url}/${storeid}`,{
            params : {
              pgno : currentPage,
              limit : itemsPerPage,
              searchKey : searchKey
          }
          })
          if(res.status === 200){
      
            setstorenotes(res.data.data)
            setTotalCount(res.data.count)
            setIsLoading(true)
            setpageloader(false)
        }
    }catch(error){
        console.log(error)
    }
  }

    //add store notes

    const handleAddStoreNotes = async(values) =>{
        setisloader(true)
  
        try{
    const userData = JSON.parse(sessionStorage.getItem('userData'))

            let res = await AxiosService.post(`${ApiRoutes.STORE_NOTES.service}${ApiRoutes.STORE_NOTES.url}`, {
                organizationId : Number(storeid),
                title : values?.title,
                notes : values?.notes,
                createdBy : Number(userData['employeeId'])
            })

            if(res.status === 200){
                setisloader(false)
                setAddToggle(false)
                setpageloader(true)
                handleGetStoreNotes()
            }
        }catch(error){
            console.log(error)
        }
      }

  useEffect(() =>{
    handleGetStoreNotes()
  },[currentPage,itemsPerPage,searchKey, storeid , parentLoader, toggle])
  return (
    <>
      <Row gutter={8}>
        <Col span={24}>
          <Col span={4}></Col>
          <div className="text-right">
            <div className="displayFlex alignCenter spaceBetween gap15">
              {" "}
              <span>
              Show    &nbsp;
          <select
            defaultValue={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
              setMaxPageNumberLimit(pageNumberLimit);
              setMinPageNumberLimit(0);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          &nbsp;
         Entries
      
              </span>
              <div className='displayFlex alignCenter'>
                <div className="searchpage mr-3">
                  <SearchOutlined />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    style={{ outline: "none" }}
                    onChange={(e) => {
                      setpageloader(true)
                      setSearchKey(e.target.value);
                    }}
                  />
                </div>
                <Button
                  type="primary"
                  className="btn--large d-flex align-items-center"
                  onClick={() => setAddToggle(true)}
                >
                  <PlusOutlined /> Add {type} Notes
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {isLoading ? (
        <PaginatedTable
          row={storenotes}
          column={column}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          isSearch={false}
          isFilterHeader={true}
          isvisible={false}
          filterOptions={filterOptions}
          handleFilterSelection={handleFilterSelection}
          filterDefaultValue={filterDefaultValue}
          minPageNumberLimit={minPageNumberLimit}
          setMinPageNumberLimit={setMinPageNumberLimit}
          setpageloader={setpageloader}
          pageloader={pageloader}
        />
      ) : (
        <div style={{ textAlign: "center" }}>Loading...</div>
      )}

      {addToggle ? (
        <div>
          <div className="divoverlay">
            <div className="divoverlayBlock">
              <div className="popupheaderblock">
                <h5> Add Store Note </h5>
                <img
                  src={popupclose}
                  alt="Close"
                  style={{ cursor: "pointer" }}
                  className="cursorPointer"
                  onClick={() => setAddToggle(false)}
                />
              </div>
              <div className="container-fluid">
                <div className="overdivScroll">
                  <div className="row mb40">
                    <Form
                      name="basic"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 16,
                      }}
                      style={{
                        maxWidth: 600,
                        margin: "10px 20px",
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={handleAddStoreNotes}
                      autoComplete="off"
                      className="supportForm"
                    >
                      <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the title",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Title" />
                      </Form.Item>

                      <Form.Item
                        label="Description"
                        name="notes"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the description",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Description" />
                      </Form.Item>

                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                        className="text-right"
                      >
                        <div className='displayFlex justifyflexend alignCenter'>
                        <Button
                          type="default"
                          className="mr-3 btn--cancel btn--large"
                          onClick={() => setAddToggle(false)}
                        >
                          Cancel
                        </Button>
                        
                       <Button
                            type="primary"
                            htmlType="submit"
                            className=" btn--large"
                          
                          >
                            {isloader ? <SupportLoader/> : "Submit" }
                          </Button>
                          </div>
                      
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
