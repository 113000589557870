let data;
export const EnviornmentType = {
  DEV: "development",
  PROD: "production"
};

export const env = EnviornmentType.PROD;
data = {
  API_ENDPOINT:
    env === EnviornmentType.DEV
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_LIVE_URL,
  AP0I_VERSION: "",
  DATEFOMAT: "YYYY-MM-DD",
  DEFAULTCURRENC: "$",
  RETAILERURL: process.env.REACT_APP_RETAILER_URL,
  SUPERMERCHANTURL: process.env.REACT_APP_SUPERMERCHANT_URL,
  ROOTURL: process.env.REACT_APP_ROOT_URL,
  BASENAME: process.env.REACT_APP_BASENAME,
  API_URL_CI: process.env.REACT_APP_API_URL_CI, //process.env.REACT_APP_API_URL_CI,
  CART_PAYMENT_PAGE: process.env.REACT_APP_CART_PAYMENT_PAGE,
  CSRF_TEST_NAME: process.env.REACT_APP_CSRF_TEST_NAME
};

data.DEFAULT_DATE_FORMAT = "LLL";

export const AppConfig = data;
