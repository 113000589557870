import { Row, Col, Checkbox, Skeleton } from "antd";
import React, { useEffect, useState } from 'react';
import {
  TagsOutlined,
  LaptopOutlined,
  UserOutlined,
  EyeOutlined
} from "@ant-design/icons";
import InventoryDetails from "./inventoryDetails";
import productIcon from '../../../../assets/images/Logo/logo-blue.svg';
import { useParams } from "react-router-dom";
import { ApiHelper, getUserData } from "../../../../utils/Helpers";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";

const ViewProductExpiry = () => {

  const params = useParams();
  const currencySign = getUserData()?.currencySign ?? "$";

  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const foo = searchParams?.get('variant');

  const [ loading, setLoading ] = useState(true);

  const [ productData, setProductData ] = useState(null)
  const [ modifiers, setModifiers ] = useState([])
  const [ isModalVisible,setIsModalVisible ] = useState(false)
  const [ modalData, setModalData ] = useState([])
  const [ assignedCheckbox, setAssignedCheckbox ] = useState([])

  const getProductDetails = async () => {
    try {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.PRODUCT_INVENTORY_DETAILS.service,
        ApiRoutes.PRODUCT_INVENTORY_DETAILS.url.replace(":id", params?.id),
        ApiRoutes.PRODUCT_INVENTORY_DETAILS.method,
        ApiRoutes.PRODUCT_INVENTORY_DETAILS.authenticate
      );
      console.log(response)
      if(response.code === 200){
        setProductData(response.data.details)
        setModifiers(response.data.details.productModifier)
        setAssignedCheckbox(response.data.details.productTaxListAssigned)
        foo && modelFilter(foo, response.data.details)
      }  
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }
  const handleCancel = ()=>{
      setIsModalVisible(false);
  }

  const showModal = ()=>{
    setIsModalVisible(true);
  }

  const modelFilter = ( id, inventory ) => {
    let data = inventory?.productInventory?.filter(e => e.productInventoryId == id)
    setModalData(data);
    showModal();
  }

  useEffect(()=>{
    getProductDetails()
  },[])

  return (
    <>
      {
      loading ?
      <div style={{ padding: "10px"}}>
      <Skeleton avatar={{ size: 200, shape: "square" }} paragraph={{ rows: 4 }} loading={loading}/>
      <Skeleton paragraph={{ rows: 5 }} loading={loading}/>
      <Skeleton paragraph={{ rows: 1 }} loading={loading}/>
      </div>
      :  
      <div className="adminContentSection">
        <Row gutter={8} className="mt10 mb-05">
          <Col span={8}>
            <div className="profileDetailsLeft bg-white">
              {/* <div className="shortname">
                LB
              </div> */}
              <div className="shortname text-center">
                <img src={productData?.imageUrl ?? productIcon} alt='Product Icon' className="p-3" width={200}/>
              </div>
              <div className="fullname">
                <p>
                  {productData?.code}
                </p>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <div className="profileDetailsRight bg-white">
              <Row gutter={8}>
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <LaptopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Product Name</p>
                      <p>{productData?.code}</p>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <TagsOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Category </p>
                      <p>
                        {productData?.categoryCode}
                      </p>
                    </div>
                  </div>
                </Col>
                { productData?.productModifier?.length > 0 &&
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <TagsOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Modifiers</p>
                      <p>
                        {modifiers?.map((e,i) => <span key={i}>{`${e?.name} (${currencySign}${e?.amount}) `}</span>)}
                      </p>
                    </div>
                  </div>
                </Col>
                }
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <TagsOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Product Type</p>
                      <p>{productData?.productTypeId}</p>
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <LaptopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">EBT Product</p>
                      <p>{productData?.isEBTProduct == 1 ? "Yes" : "No"}</p>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <LaptopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">
                        ID Check required
                      </p>
                      <p>
                        {productData?.isIdCheckProduct == 1 ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <LaptopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Weighable</p>
                      <p>{productData?.isWeighable == 1 ? "Yes" : "No"}</p>
                    </div>
                  </div>
                </Col>
                { !productData?.hasVariants && productData?.productDetails[0]?.plu &&
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <LaptopOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">PLU</p>
                      <p>{productData?.productDetails[0]?.plu}</p>
                    </div>
                  </div>
                </Col>
                }
                { productData?.vendorName &&
                <Col span={12}>
                  <div className="displayFlex">
                    <div className="mr-3">
                      <UserOutlined />
                    </div>
                    <div>
                      <p className="fw-700 mb-0">Vendor Name</p>
                      <p>{productData?.vendorName}</p>
                    </div>
                  </div>
                </Col>
                }
              </Row>
            </div>
          </Col>
        </Row>
        { !productData?.hasVariants &&
        <Row gutter={8} className="mt10 mb-05">
          <Col span={24}>
            <div className="bg-white pt-3 px-3 productViewTable">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>Product UPC</th>
                    <th>SKU</th>
                    <th>Cost Price</th>
                    <th>Sell Price</th>
                    { !productData?.isExpirable &&
                      <th>Inventory</th>
                    }
                  </tr>
                </thead>
                <tbody> 
                  <tr>
                    <td><span className="text--grey">{productData?.productDetails[0]?.upc}</span></td>
                    <td>{productData?.productDetails[0]?.sku}</td>
                    <td>{currencySign}{productData?.productDetails[0]?.costPrice ? parseInt(productData?.productDetails[0]?.costPrice) : "0"}</td>
                    <td>{currencySign}{productData?.productDetails[0]?.salePrice ? parseInt(productData?.productDetails[0]?.salePrice) : "0"}</td>
                    { !productData?.isExpirable &&
                    <td>{productData?.productDetails[0]?.currentQty ? parseInt(productData?.productDetails[0]?.currentQty) : "0"}</td>
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        }
        { !productData?.hasVariants && productData?.productInventory?.length > 0 && productData?.isExpirable &&
        <Row gutter={8} className="d-flex">
          <Col span={24}>
            <div className="bg-white pt-3 px-3 productViewTable">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>Inventory Count</th>
                    <th>Expiration Date</th>
                  </tr>
                </thead>
                <tbody>
                  { productData?.productInventory.map((e,i) => {
                    return <tr key={i}>
                    <td>{e?.currentStock ? parseInt(e?.currentStock) : "0"}</td>
                    <td>{e?.expiryDt ?? '-'}</td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        }
        { productData?.hasVariants && 
        <Row gutter={8} className="mt10 mb-05">
          <Col span={24}>
            <div className="bg-white pt-3 px-3 productViewTable">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>Variant Name</th>
                    <th>Product UPC</th>
                    <th>SKU</th>
                    <th>Alert At</th>
                    <th>Cost Price</th>
                    <th>Sell Price</th>
                    <th>Inventory</th>
                    { productData?.isExpirable &&
                    <th className="text-center">Inventory Details</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  { productData?.productDetails?.map((e,i) => {
                    return <tr key={i}>
                    <td><span className="text--grey">{e?.variationName}</span></td>
                    <td>{e?.upc}</td>
                    <td>{e?.sku}</td>
                    <td>{e?.inventoryAlert ? parseInt(e?.inventoryAlert) : '0'}</td>
                    <td>{currencySign}{e?.costPrice}</td>
                    <td>{currencySign}{e?.salePrice}</td>
                    <td>{e?.currentQty ? parseInt(e?.currentQty) : '0'}</td>
                    { productData?.isExpirable &&
                    <td className="text-center"><span className="btn--small btn--view"><EyeOutlined onClick={()=>modelFilter(e?.productInventoryId, productData)}/></span></td>
                    }
                  </tr>
                  })
                  }
                </tbody>
              </table>
            </div>

          </Col>
        </Row>
        }
        { productData?.productTaxList?.length > 0 && 
        <Row gutter={8} className="d-flex">
          <Col span={24}>
            <div className="bg-white p-3 h-100">
              <div className="border-bottom mb-1">
                <h6 className="fw-600">Tax</h6>
              </div>
              <Row gutter={8}>
                { productData?.productTaxList?.map((e,i) => {
                  return <Col span={8} key={i}>
                  <Checkbox className="text--grey" checked={assignedCheckbox.includes(e?.orgTaxId)}>
                    <p className="mb-0">{e?.taxName}</p>
                    <span>({e?.tax}%)</span>
                  </Checkbox>
                </Col>
                })
                }
              </Row>
            </div>
          </Col>
        </Row>
        }
      </div>
      }
      {isModalVisible ? (
        <InventoryDetails
          data = {modalData}
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          lan={"EN"}
          loading={loading}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default ViewProductExpiry