import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import PaginatedTable from '../../../common/PaginatedTable';
import popupclose from "../../../../assets/images/support/popupclose-icon.svg";
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';

import {
    EyeOutlined,
    EditOutlined,
    PlusOutlined
} from "@ant-design/icons";
import AxiosService from '../../../../utils/Config/ApiService';
import SupportLoader from '../../../../utils/Config/SupportLoader';

export const Terminal = ({storeid}) => {
    const[isloader, setisloader] = useState(true)
    const [subloader, setsubloader] = useState(false)
    const [pageloader, setpageloader] = useState(undefined)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    let [totalCount, setTotalCount] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(10)
    let [searchKey, setSearchKey] = useState(null)
    let [addToggle, setAddToggle] = useState(false);
    let [isLoading, setIsLoading] = useState(true)
    let [users, setUsers] = useState([
        { DeviceId: '5680755E-FF09-65D0-61C0-39996DFC25F3', Name: 'Bala', DeviceType: 'Web', TerminalId: 'Poi00001W' },
        { DeviceId: '5680755E-FF09-65D0-61C0-39996DFC25F3', Name: 'Bala', DeviceType: 'Web', TerminalId: 'Poi00001W' }

    ]);
    let [terminals, setterminals] = useState([])
    let [terminalbyid, setterminalbyid] = useState({})

    const getStoreTerminalList = useCallback(async()=>{
        try{
            let res = await AxiosService.get(`${ApiRoutes.STORE_TERMINAL.service}${ApiRoutes.STORE_TERMINAL.url}/${storeid}`,{
              params : {
                pgno : currentPage,
                limit : itemsPerPage,
                searchKey : searchKey
            }
            })
            if(res.data.statusCode === 200){
          
                setterminals(res.data.data)
                setTotalCount(res.data.count)
                setIsLoading(true)
                setisloader(false)
                setpageloader(false)
            }
        }catch(error){
            console.log(error)
        }
    },[currentPage, itemsPerPage, searchKey,storeid])
    //useState is required if we need column filter
    const [column, setColumn] = useState([
        {
            heading: () => "Device Id",
            cell: row => {return <span className='terminalIdText' style={{cursor:"pointer"}}> {row['terminalGuid']} </span>},
            hidden: false,
            width: "20",
            id: 2
        },
        {
            heading: () => "Name",
            cell: row => {

                return row['terminalName']!== null ? 
                 <span style={{cursor:"pointer"}}
                
                 onClick={() =>{
                    setterminalbyid(row)
                 setAddToggle(true)}}
                > {row['terminalName']}  <EditOutlined  className="" />  </span>  :<Button type='primary' onClick={() => {
                    setterminalbyid(row)
                    setAddToggle(true)}}><PlusOutlined className='d-flex align-items-center'/></Button>
            },
            hidden: false,
            width: "200",
            id: 3
        },
        {
            heading: () => "Device Type",
            cell: row => row['terminalType'],
            hidden: false,
            width: "200",
            id: 4
        },
        {
            heading: () => "Terminal Id",
            cell: row => row['terminalId'],
            hidden: false,
            width: "200",
            id: 7
        }
    ])
    //required to get the filtered column
    let filterOptions = []
    //default checked values of select options
    let filterDefaultValue = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 13 }, { value: 14 }]

    //generate the options of multi select
    column.forEach((e) => {
        let option = {
            label: e.id === 13 ? 'Status' : e.heading(),
            value: e.id
        }
        filterOptions.push(option)
    })

    //to handle the change of the react-multi select
    const handleFilterSelection = (options) => {
        let newColumn = [...column]
        for (let i = 0; i < newColumn.length; i++) {
            let data = options.filter((e) => newColumn[i].id === e.value)
            data.length === 1 ? newColumn[i].hidden = false : newColumn[i].hidden = true
        }
        setColumn(newColumn)
    }
    useEffect(() =>{
        getStoreTerminalList()
    }, [currentPage, itemsPerPage, searchKey, storeid, pageloader])

    const onFinish = async (values) =>{
       
        try{
            let res = await AxiosService.put(`${ApiRoutes.STORE_TERMINAL.service}${ApiRoutes.STORE_TERMINAL.url}/${terminalbyid?.id}`,{
                terminalName : values?.terminalName
            })
           
            if(res.status === 200){
                setsubloader(false)
                setAddToggle(false)
                getStoreTerminalList()
                setpageloader(true)
            }
        }catch(error){
            console.log(error)
        }
    }
    return (
        <>
            {!isloader ? <PaginatedTable
                row={terminals}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                isFilterHeader={true}
                filterOptions={filterOptions}
                handleFilterSelection={handleFilterSelection}
                filterDefaultValue={filterDefaultValue}
                isMultiSelect= {false}
                isSearch={true}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                setpageloader={setpageloader}
                pageloader={pageloader}
            /> : <SupportLoader/>}


            {addToggle ? (
                <div>
                    <div className="divoverlay">
                        <div className="divoverlayBlock">
                            <div className="popupheaderblock">
                                <h5> Terminals </h5>
                                <span onClick={() => setAddToggle(false)}>
                                <img
                                    src={popupclose}
                                    alt="Close"
                                    className="cursorPointer"
                                    
                                />
                                </span>
                               
                            </div>
                            <div className="container-fluid">
                                <div className="overdivScroll">
                                    <div className="row mb40">
                                        <Form
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            wrapperCol={{
                                                span: 16,
                                            }}
                                            style={{
                                                maxWidth: 600,
                                                margin: '10px 20px'
                                            }}
                                            // initialValues={{
                                            //     remember: true,
                                            // }}
                                            autoComplete="off"
                                            className='supportForm'
                                            initialValues={
                                                
terminalbyid
                                            }
                                            onFinish={onFinish}
                                        >
                                            <Form.Item
                                                label="Name"
                                                name="terminalName"
                                            >
                                                <Input placeholder='Enter Name'
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Device Serial Number"
                                                name="terminalGuid"
                                                
                                                
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Device Serial Number!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder='Enter Device Serial Number' disabled/>
                                            </Form.Item>



                                            <Form.Item
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 16,
                                                }}
                                                className='text-right'
                                            >
                                                <div className='displayFlex justifyflexend alignCenter'>
                                                    <Button type="default" htmlType="submit" 
                                                    onClick={() =>  setAddToggle(false)}
                                                    className='mr-3 btn--cancel btn--large'>
                                                        Cancel
                                                    </Button>
                                                    <Button type="primary" htmlType="submit" className=' btn--large'
                                                    onClick={() => setsubloader(true)}
                                                    >
                                                        {subloader ? <SupportLoader/> : "Submit"}
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

        </>
    )
}
