/* eslint-disable no-lone-blocks */
import { Button, Col, Row, Form, Input } from 'antd';
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import popupclose from "../../../../assets/images/support/popupclose-icon.svg";
import {
  PhoneOutlined,
  LockOutlined,
  FlagOutlined,
  EnvironmentOutlined,
  MailOutlined,
  UserOutlined,
  EditOutlined ,
  ArrowLeftOutlined,
  ExclamationCircleOutlined ,
  EyeOutlined,
  NumberOutlined,
  CheckCircleFilled 
} from "@ant-design/icons";
import DisableIcon from "../../../../assets/images/support/ic_outline-disabled-visible.png";
import AxiosService from '../../../../utils/Config/ApiService';
import SupportLoader from '../../../../utils/Config/SupportLoader';
import axios from 'axios';
import '../support.less';
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';

const AdminStaffProfile = () => {
  let [addToggle, setAddToggle] = useState(false);
  // let [resetToggle, setResetToggle] = useState(false);
  let [isloader, setisloader] = useState(true)

  const [staff, setstaff] = useState({})
  const history = useHistory()
  const staffId = useParams()?.id;
  const [country, setcountry] = useState("")
  const [state, setstate] = useState("")
  const [city, setcity] =  useState("")

  const [resetPasswordToggle, setresetPasswordToggle]=useState(false)
  const [formData, setFormData] = useState({})
  const [resetToggle, setresetToggle] = useState(false)
  const [passwordToggle, setPasswordToggle] = useState(false)
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false)
  const [verifyPassword, setVerifyPassword] = useState(false)
  // const [employeeId, setEmployeeId] = useState()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [conPassValue, setConPassValue]= useState("")
  const [submitloader,setsubmitloader] = useState(false)
  const [pin, setPin] = useState()
  const [form] = Form.useForm();
  const userData = JSON.parse(sessionStorage.getItem('userData'))
  const [rolesList, setRolesList] = useState([]);
  const supportAdminId = userData.employeeId
  const [revealLoader, setRevealLoader] = useState(false)
  sessionStorage.setItem('activePageKey',"1")
  let [statusKey, setStatusKey] = useState("")
  const [status, setstatus] = useState();

  const getStaffById = async () => {
    try{
      let res = await AxiosService.get(`${ApiRoutes.ADMIN_STAFF.service}${ApiRoutes.ADMIN_STAFF.url}/${staffId}`)
      if(res.data.statusCode === 200){
        setstaff(res.data.employeeDetails)
        setRolesList(res.data.roleList)
        setisloader(false)
      }
      

    }catch(error){
      console.log(error)
    }
  }

  const handleRevealPin = async() =>{
    try{
      let res = await AxiosService.post(`${ApiRoutes.STAFF_REVEAL_PIN.service}${ApiRoutes.STAFF_REVEAL_PIN.url}`,{
        "employeeId" : staffId,
        "supportAdminId" : supportAdminId
      }
      )
      if(res.status === 200){
       setPin(res.data.employeePin)
       setAddToggle(true)
       setRevealLoader(false)
      }
    }catch(error){
      console.log(error)
      setRevealLoader(false)
    }
  }
  const handleResetPassword = async() =>{
    try{
      const formData = new FormData()
      formData.append('employeeId', Number(staffId)) ;
      formData.append('organizationId', Number(staff?.organizationId) ) ;
      formData.append('password', password ) ;
              //to generate store notes
              let data = { 
                "organizationId":Number(staff?.organizationId),
                "title":"Password Reset",
                "notes":`Password Reset of ${staff?.userName}`,
                "createdBy":Number(supportAdminId)
            }

      let res  =  await axios.post(`${process.env.REACT_APP_ROOT_URL}/apiv11/auth/changePassword`,formData,
      {
       headers:{
           'Content-Type': 'multipart/form-data',
           authorization: `Basic ${process.env.REACT_APP_PHP_Auth_Token}`
       }
      }
      )
        
      if(res.data.success === 1){
        let notesRes = await AxiosService.post(`${ApiRoutes.STORE_NOTES.service}${ApiRoutes.STORE_NOTES.url}`,data )
        if(notesRes.status === 200){
          form.resetFields()
          setresetToggle(false)
          setresetPasswordToggle(false)
          setsubmitloader(false)
          alert(res?.data?.response?.message)
        }

      }else{
        alert("Password reset failed")
        // form.resetFields()
        // setresetToggle(false)
        setsubmitloader(false)
        // setresetPasswordToggle(false)
      }

    }catch(error){
        // console.log(error)
        setsubmitloader(false)
    }
}
// console.log("token", `${process.env.REACT_APP_PHP_Auth_Token}`)
  useEffect(() =>{
    getStaffById()
  },[])

  const handleDisableAdminStaff = async (values) => {
    try {
      setsubmitloader(true);
      const employeeId = JSON.parse(
        sessionStorage.getItem("userData")
      ).employeeId;
      let res = await AxiosService.put(`${ApiRoutes.STAFF_TOGGLE.service}${ApiRoutes.STAFF_TOGGLE.url}/${staffId}`,{
          supportAdminId: employeeId,
          reason: values?.reason,
          status: status,
        }
      );
      if (res.status === 200) {
        setsubmitloader(false);
        setAddToggle(false);

        setisloader(true);
        getStaffById();
        form.resetFields()       
      }
    } catch (error) {
      console.log(error);
      setsubmitloader(false);
    }
  };
  return (
    <>
      <div className="adminContentSection">
        <Row gutter={8}>
          <Col span={24}>
            <div className='displayFlex mt10 mb-05 spaceBetween'>
              <div>
                <Button type="warning" className='mr-3 btn--large d-flex align-items-center'
                onClick={() => {
                  history.goBack()
                }}
                ><ArrowLeftOutlined /> Back</Button>
              </div>
              <div className='d-flex align-items-center'>
                <Button type="primary" className='mr-3 btn--large d-flex align-items-center' onClick={() => {
                  history.push(`/support/adminStaffProfileEdit/${staffId}`)
                }}><EditOutlined  /> Edit</Button>
                {/* <Button type="warning" className='btn--large d-flex align-items-center' onClick={() => setresetPasswordToggle(true)}><LockOutlined /> Reset Password</Button> */}
                {staff?.active === 1 ? (
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => {
                    setAddToggle(true);
                    // setstatus(false);
                    setStatusKey("disable")

                  }}
                >
                  <img
                    src={DisableIcon}
                    width="17px"
                    height="17px"
                    className="mr-1"
                    alt="disicon"
                  />{" "}
                  Disable
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="btn--large"
                  onClick={() => {
                    setAddToggle(true);
                    // setstatus(true);
                    setStatusKey("enable")
                  }}
                >
                  <img
                    src={DisableIcon}
                    width="17px"
                    height="17px"
                    className="mr-1"
                    alt="enableicon"
                  />{" "}
                  Enable
                </Button>
              )}
                
              </div>

            </div>
          </Col>
        </Row>
{
  isloader ? <Row>
  <SupportLoader/>
  </Row>
 : 
 <>
  <Row gutter={8} className='mb-05'>
  <Col span={8}>
    <div className='profileDetailsLeft bg-white'>
      <div className='shortname'>
        {staff?.firstName?.slice(0,1).toUpperCase()}{staff?.lastName?.slice(0,1).toUpperCase()}
      </div>
      <div className='fullname'>
      {staff?.firstName} {staff?.lastName}
      </div>
    </div>
  </Col>
  <Col span={16}>
    <div className='profileDetailsRight bg-white'>
      <Row gutter={8}>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <UserOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Staff Name</p>
            <p>{staff?.firstName}{" "}{staff?.lastName}</p>
              {/* <Link to= {`/support/store/${staff['organizationId']}`} className="btn--small btn--view"><EyeOutlined /></Link> */}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <UserOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>User Name</p>
              <p>{staff?.userName}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <UserOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Full Name</p>
              <p>{staff?.firstName}{" "}{staff?.lastName}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <MailOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Email</p>
              <p>{staff?.email}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <PhoneOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Business Phone</p>
              <p>{staff?.businessPhoneCode+"-"+staff?.businessPhone}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <FlagOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Country / State / City Name</p>
              <p>{staff?.countryName?staff?.countryName:"-" } { " / " } {staff?.stateName !== null ? staff?.stateName : "-" }  {" / "} {staff?.cityName !== null ? staff?.cityName : "-" }</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <EnvironmentOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Address</p>
              <p>{staff?.addressLine1}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <NumberOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Zipcode</p>
              <p>68301</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Col>
</Row>
<Row gutter={8}>
    {
        rolesList?.length > 0 ?
        <>
          <Col span={24}>
      <h4 className='m-tb1 fw-600'>Roles</h4>
  </Col>  
  <Col span={24}>
  {rolesList?.map((role) => {
    return <div className='p-2 bg-white mb-05' key={role.roleId}>
    <div className='displayFlex alignCenter'>
       <span className='mr-1 text--primary'> <CheckCircleFilled  /> </span>
       <span className='pt-1'>{role.Description}</span>
    </div>
</div>
  })}
      
 


  </Col>
        </>
        :<></>
    }

</Row>
</>
}
        {addToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock divoverlaySmallBlock">
                <div className="container-fluid">
                  <div className="text-center p-x8-y16">
                    <h2 className='m-10 fs-15'>Employee Pin</h2>
                    <h3 className='m-10 text--grey'>{pin?.length  ? pin : "No data available"}</h3>
                    <Button type="primary" className='btn--large m-10' onClick={() => setAddToggle(false)}>Ok</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

{resetPasswordToggle ? (
                <div>
                    <div className="divoverlay">
                        <div className="divoverlayBlock">
                            <div className="popupheaderblock">
                                <h5> Reset Password </h5>
                                <img
                                    src={popupclose}
                                    alt="Close"
                                    className="cursorPointer"
                                    style={{cursor : "pointer"}}
                                    onClick={() => {setresetPasswordToggle(false)
                                    form.resetFields()
                                    }}
                                />
                            </div>
                            <div className="container-fluid">
                                <div className="overdivScroll">
                                    <div className="row mb40">
                                        <Form
                                        form={form}
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            wrapperCol={{
                                                span: 16,
                                            }}
                                            style={{
                                                maxWidth: 600,
                                                margin: '10px 20px'
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            autoComplete="off"
                                            className='supportForm'
                                            onFinish={(values) =>{
                                                setFormData(values)
                                            }}
                                        >
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                type="password"
                                               
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the new password',
                                                    },
                                                ]}
                                            >
                                                <span  style={{display : "flex"}}>
                                                <Input
                                                 type={passwordToggle ?"text": "password"}
                                                 onChange={(e) =>{
                                                    setPassword(e.target.value)
                                              
                                                    setVerifyPassword(e.target.value === conPassValue);
                                                 }}
                                                placeholder='Enter New Password'/>
                                                <EyeOutlined
                                                  onClick={() =>{
                                                    setPasswordToggle(prev => !prev)
                                                 }} className='passwordIcon'
                                                />
                                                </span>
                                               
                                            </Form.Item>

                                            <Form.Item
                                                label="Confirm Password"
                                                name="confirm Password"
                                                style={{display : "flex"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the confirm password',
                                                    },
                                                ]}
                                            >
                                                <span style={{display : "flex"}}>
                                                <Input
                                                  type={confirmPasswordToggle ? "text" :"password"}
                                                  // name="confirm Password"
                                                  value={conPassValue}
                                                  onChange={(e) => {
                                                    setConfirmPassword(true);
                                                    setConPassValue(e.target.value);
                                                    setVerifyPassword(e.target.value === password && e.target.value?.length !== 0);
                                                }}
                                                 placeholder='Enter Confirm Password'/>
                                                 <EyeOutlined 
                                                 onClick={() =>{
                                                    setConfirmPasswordToggle(prev => !prev)
                                                 }} className='passwordIcon'
                                                 />

                                                </span>

                                               {
                                    (            !verifyPassword && confirmPassword) && (
                                                    <span style={{color : "red"}}>Passwords doesnot match</span>
                                                )
                                               }
                                               
                                            </Form.Item>
                                     

                                            <Form.Item
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 16,
                                                }}
                                                className='text-right'
                                            >
                                                 <div className='displayFlex justifyflexend alignCenter'>
                                                <Button type="default" className='mr-3 btn--cancel btn--large'
                                                onClick={() => {
                                                    form.resetFields()
                                                    setresetPasswordToggle(false)}}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit" 
                                                onClick={(e)=>{
                                                    if(verifyPassword && (password.length !== 0) && (conPassValue !== 0)){
                                                        setresetToggle(true)
                                                    }
                                                  
                                                }}
                                                className=' btn--large'>
                                                   {/* {submitloader ? <SupportLoader/> : "Submit"}  */}
                                                   Submit
                                                </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )
        }
        {resetToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock divoverlaySmallBlock">
                <div className="container-fluid">
                  <div className="text-center p-x8-y16">
                    <ExclamationCircleOutlined color='#30649B' className='text--primary fs--5'/>
                    <h1 className='m-10 fs-15'>Are you sure?</h1>
                    <p className='m-10 fs--1'>You want to reset password</p>
                    <div className='d-flex alignCenter justify-content-center'>
                    <Button type="primary" className='btn--large btn--cancel m-10' onClick={() => {{
                        form.setFieldsValue({
                            "confirm Password" : conPassValue
                        })
                        setresetToggle(false)}}}>Cancel</Button>
                    <Button type="primary" className='btn--large m-10' onClick={() => {
                        // eslint-disable-next-line no-lone-blocks
                            {
                                setsubmitloader(true)
                                handleResetPassword()
                            }}
                        }>
                                       {submitloader ? <SupportLoader/> : "OK"} 
                            </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

{addToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock">
                <div className="popupheaderblock">
                  <h5> Reason </h5>
                  <img
                    src={popupclose}
                    alt="Close"
                    className="cursorPointer"
                    onClick={() => setAddToggle(false)}
                  />
                </div>
                <div className="container-fluid">
                  <div className="overdivScroll">
                    <div className="row mb40">
                      <Form
                      form={form}
                        name="basic"
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        style={{
                          maxWidth: 600,
                          margin: "10px 20px",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        autoComplete="off"
                        className="supportForm"
                        onFinish={handleDisableAdminStaff}
                      >
                        <Form.Item
                          label="Reason"
                          name="reason"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the reason",
                            },
                            
                          ]}
                        >
                          <Input
                          name="reason"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                          
                            if (inputValue.startsWith(' ')) {
                              form.setFieldsValue({ reason: inputValue.trim() });
                            } else {
                              form.setFieldsValue({ reason: inputValue });
                            }
                          }}

                           placeholder="Enter Reason" />
                        </Form.Item>

                        <Form.Item
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                          className="text-right"
                        >
                          <div className="displayFlex justifyflexend alignCenter">
                            <Button
                              type="default"
                              onClick={() => {setAddToggle(false);   form.resetFields()  }}
                              className="mr-3 btn--cancel btn--large"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className=" btn--large"
                              onClick={() => {
                                statusKey === "enable" ? setstatus(true) : setstatus(false);
                              }}
                            >
                              {submitloader ? <SupportLoader /> : "Submit"}
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export default AdminStaffProfile