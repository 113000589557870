import axios from "axios";
// import loader from "../../assets/images/loader.gif"
// import "../Common/loader.css"


console.log("process.env.REACT_APP_LIVE_URL", process.env.REACT_APP_LIVE_URL,)
const isExclude = (url)=>{
  return true
}
const AxiosService = axios.create({
  baseURL: process.env.REACT_APP_LIVE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

AxiosService.interceptors.request.use(
  config => {
    // console.log(config)
    const token = sessionStorage.getItem('supportToken')
    if (token) {
      config.headers.Authorization = token?`Bearer ${token}`:null
    } 

    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

// AxiosService.interceptors.response.use(
//   response => {
//     const element = document.getElementById("loader");
//     element.remove();
//     return response;
//   },
//   error => {
//     const element = document.getElementById("loader");
//     element.remove();
//    return Promise.reject(error)
//   }
// );


export default AxiosService;