import React from "react";
import { Select } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import { messages } from "../../../utils/Common/Messages";
import { getUserData } from "../../../utils/Helpers";
const { Option } = Select;

const Location = (props) => {
  const lan = getUserData().language ?? "EN";
  const selectOnChange = (value) => {
    props.filterdata("", "", "", "", value);
    props.setEmpSelected([])
    props.setSelectedStore(value)
  };
  return (
    <Select
      mode="multiple"
      placeholder={messages.selectLocation[lan]}
      allowClear
      showSearch
      showArrow="true"
      className="locationSelect"
      dropdownClassName="locationDropdown"
      style={{ width: 200 }}
      optionFilterProp="children"
      onChange={selectOnChange}
      maxTagCount={1}
    >
      {props.storeLoction &&
        props.storeLoction.map((store, key) => (
          <Option value={store.organizationId} key={key}>
            <ShopOutlined /> {store.organizationName}
          </Option>
        ))}
    </Select>
  );
};
export default Location;
