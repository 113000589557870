import React, { useState } from "react";
import { Popover, Button, Row, Col, Space } from "antd";
import Paymenttype from "../layout/filter/Paymenttype.js";
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { AppConfig } from "../../utils/Config/AppConfig";
import { ApiRoutes } from "../../utils/Config/ApiRoutes";
import { getUserData } from "../../utils/Helpers";
import { messages } from "../../utils/Common/Messages";
import { getToken } from "../../utils/Helpers";

const Exportform = (props) => {
  const { startDate, endDate, organizationId, employeeId, lan,storeId,currencySign } = props;
  const [paymentType, setPaymentType] = useState("all");
  const raiseInvoiceClicked = () => {
    props.hidePopover(false);
    let token = getToken()
    window.open(
      `${AppConfig.API_ENDPOINT}${ApiRoutes.SALEEXPORTEXCEl.service}${
        ApiRoutes.SALEEXPORTEXCEl.url
      }?startDt=${startDate}&endDt=${endDate}&paymentType=${paymentType}&organizationId=${organizationId}&employeeId=${employeeId}&gmtTime=${
        getUserData().time_gmt
      }&shiftReport=${props.shiftId}&lan=${lan}&storeId=${storeId}&token=${token}`,
      "_blank"
    ); //to open new page
  };
  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical" className="mb-1">
        <Paymenttype setPayment={setPaymentType} lan={lan} currencySign={currencySign} />
      </Space>
      <Row gutter={12}>
        <Col lg={24} xs={24} align="right">
          <Button
            type="default"
            className="btn-mr8"
            onClick={() => props.hidePopover(false)}
          >
            {messages.cancel[lan]}
          </Button>
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={raiseInvoiceClicked}
          >
            {messages.Download[lan]}
          </Button>
        </Col>
      </Row>
    </>
  );
};

const Exportbutton = (props) => {
  const { startDate, endDate, organizationId, employeeId, shiftId, lan,storeId,currencySign } =
    props;
  const [visible, setVisible] = React.useState(false);
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };
  return (
    <>
      <Popover
        content={
          <Exportform
            startDate={startDate}
            endDate={endDate}
            organizationId={organizationId}
            employeeId={employeeId}
            storeId={storeId}
            shiftId={shiftId}
            hidePopover={setVisible}
            lan={lan}
            currencySign={currencySign}
          />
        }
        currencySign={currencySign}
        title={messages.Export[lan]}
        trigger="click"
        visible={visible}
        placement="bottomRight"
        onVisibleChange={handleVisibleChange}
      >
        <Button type="primary" icon={<DownloadOutlined />}>
          {messages.Export[lan]}
        </Button>
      </Popover>
    </>
  );
};
export default Exportbutton;
