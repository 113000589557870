import React, { useState } from 'react'
import { Button, Col, Row, message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { DownloadOutlined } from '@ant-design/icons';
import Sample from "../../../../assets/sample.xlsx"
import * as XLSX from "xlsx"
import AxiosService from '../../../../utils/Config/ApiService';
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import SupportLoader from "../../../../utils/Config/SupportLoader";

const BulkAgent = () => {

    const history = useHistory();
    const [messageApi, contextHolder] = message.useMessage();

    const headers = ['agentName', 'distributorId', 'email', 'phone', 'state', 'city', 'address', 'zipcode']

    const [toValidateData, setToValidateData] = useState([]);
    const [validation, setValidation] = useState(false);
    const [validationList, setValidationlist] = useState([]);
    const [loader, setLoader] = useState(false);

    const columnValidator = ( array ) => {
        let total = 0
        array.forEach((e,i) => {
            if(e === headers[i]){
                total++
            }
        })
        return total === 8 ? false : true
    }

    const getFile = async ( e ) => {
        e.preventDefault();
        console.log("FILE",e)
        setValidation(false);
        setValidationlist([]);
        let file = e.target.files[0]
        var reader = new FileReader();
        reader.onload = function(e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, {"type": "binary"})
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            const headerCheck = XLSX.utils.sheet_to_json(ws, {header: 1})

            if(headerCheck[0].length < 8 || headerCheck[0].length != 8 || headerCheck[0].length > 8){
                setToValidateData([])
                messageApi.open({
                    type: "error",
                    content: "Some Headers Are Missing"
                })
            }else if(columnValidator(headerCheck[0])){
                setToValidateData([])
                messageApi.open({
                    type: "error",
                    content: "Arrange Headers As Per Sample"
                })
            }else{
                const dataParse = XLSX.utils.sheet_to_json(ws)
                let fieldCount = 0;
                let validated = true;

                dataParse.forEach((e,i) => {
                    if(Object.keys(e).length < 8){
                        if(validated){
                            messageApi.open({
                                type: "error",
                                content: `Empty Fields At Row ${i+1}`
                            })
                            validated= false;
                        }
                    }else if(Object.keys(e).length > 8){
                        if(validated){
                            messageApi.open({
                                type: "error",
                                content: `Unnecessary Fields At Row ${i+1}`
                            })
                            validated= false;
                        }
                    }else{
                        fieldCount++
                    }
                })
                if(dataParse.length === fieldCount){
                    if(dataParse.map(e => e.email).length != [...new Set(dataParse.map(e => e.email))].length)
                    {
                        messageApi.open({
                            type: "error",
                            content: `Remove Duplicate Emails`
                        })
                    }else if(dataParse.map(e => e.phone).length != [...new Set(dataParse.map(e => e.phone))].length){
                        messageApi.open({
                            type: "error",
                            content: `Remove Duplicate Phone Numbers`
                        })
                    }else{
                        setToValidateData(dataParse)
                    }
                }
            }
        }
        reader.readAsBinaryString(file)
    }

    const handleVerify = async () => {
        setLoader(true)
        try {
            let res = await AxiosService.post(`${ApiRoutes.BULK_AGENT_VERIFY.service}${ApiRoutes.BULK_AGENT_VERIFY.url}`, 
                { bulk: JSON.stringify(toValidateData) }
            );
            if(res.status === 200){
              setValidation(true)
              setValidationlist([])
              setLoader(false)
            }
          } catch (error) {
            if(error.response.status === 400){
              setValidation(false)
              setValidationlist(error.response.data.errorList)
              setLoader(false);
            //   messageApi.open({
            //     type: "error",
            //     content: error.response.data.message
            //   })
            }
            console.log(error)
          }
    }

    const handleUpload = async () => {
        setLoader(true)
        try {
            let res = await AxiosService.post(`${ApiRoutes.BULK_AGENT_UPLOAD.service}${ApiRoutes.BULK_AGENT_UPLOAD.url}`, 
                { agents: JSON.stringify(toValidateData) }
            );
            if(res.status === 201){
                setLoader(false);
                history.goBack();
            }
          } catch (error) {
              setLoader(false);
              messageApi.open({
                type: "error",
                content: error.response.data.error ?? error.response.data.message
              })
            console.log(error)
          }
    }

    const reselectSameFile = (e) => {
        e.target.value = "";
        setValidation(false);
        setToValidateData([]);
        setValidationlist([]);
    }   

  return (
    <>
        {contextHolder}
      <div className="text-right mb-1">
        <Button
          type="warning"
          className="btn--large"
          href={Sample}
        >
          <DownloadOutlined />
          Sample xlsx
        </Button>
      </div>    
      <Row gutter={8} className='mb-1'>
        <Col span={24}>
            <div className="bg-white profileDetailsRight displayFlex spaceBetween">
                <div>
                <label className='mr-3'>Upload</label>
                <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type='file'
                    onChange={getFile} onClick={(e)=> reselectSameFile(e)}
                />
                </div>

                <div>
                <label className='mr-3'>Date</label>    
                <input type='date' style={{ padding: "5px"}}/>
                </div>
            </div>
        </Col>
      </Row>
      <Row gutter={8} className='bg-white profileDetailsRight mt10 mb-1'>
        <Col span={3}>
            Agent Name
        </Col>
        <Col span={3}>
            Agent Address
        </Col>
        <Col span={3}>
            Distributor Id
        </Col>
        <Col span={3}>
            Phone
        </Col>
        <Col span={5}>
            Email
        </Col>
        <Col span={2}>
            State
        </Col>
        <Col span={2}>
            City
        </Col>
        <Col span={2}>
            Zipcode
        </Col>
      </Row>
      { toValidateData.length > 0 &&
      toValidateData.map((e,i) => {
        return <Row gutter={8} key={i} className='bg-white profileDetailsRight mb-05'>
        <Col span={3}>
            {e.agentName}
        </Col>
        <Col span={3}>
            {e.address}
        </Col>
        <Col span={3}>
            {e.distributorId}
        </Col>
        <Col span={3}>
            {e.phone}
        </Col>
        <Col span={5}>
            {e.email}
        </Col>
        <Col span={2}>
            {e.state}
        </Col>        
        <Col span={2}>
            {e.city}
        </Col>
        <Col span={2}>
            {e.zipcode}
        </Col>
      </Row>
      })}
      {
        validationList.length > 0 && 
        <ul style={{ color: "red" }}>
            {
                validationList.map((e,i) => {
                    return <li key={i}>{e}</li>
                })
            }
        </ul>
      }
      {
        toValidateData.length > 0 &&
        ( !validation ?
        <div className="text-right mb-1">
            <Button
            type="warning"
            htmlType="submit"
            className="btn--large"
            onClick={handleVerify}
            >
                {loader ? <SupportLoader/> : "Validate"}
            </Button>
        </div> : 
        <div className="text-right mb-1">
            <Button
            type="primary"
            htmlType="submit"
            className="btn--large"
            onClick={handleUpload}
            >
            Upload
        </Button>
        </div> )
      }
    </>
  )
}

export default BulkAgent