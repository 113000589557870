import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { AppConfig } from "../../utils/Config/AppConfig";
import { ApiRoutes } from "../../utils/Config/ApiRoutes";
import { getUserData } from "../../utils/Helpers";
import { messages } from "../../utils/Common/Messages";
import { getToken } from "../../utils/Helpers";

const Exportbuttonwithoutdropdown = (props) => {
  const { startDate, endDate, organizationId, employeeId, lan,storeId } = props;
  const raiseInvoiceClicked = () => {
    let token = getToken()
    window.open(
      `${AppConfig.API_ENDPOINT}${ApiRoutes.ITEMEXPORTEXCEl.service}${
        ApiRoutes.ITEMEXPORTEXCEl.url
      }?startDt=${startDate}&endDt=${endDate}&paymentType=all&organizationId=${organizationId}&employeeId=${employeeId}&gmtTime=${
        getUserData().time_gmt
      }&shiftReport=${props.shiftId}&lan=${lan}&storeId=${storeId}&token=${token}`,
      "_blank"
    ); //to open new page
  };
  return (
    <>
      <Button
        type="primary"
        onClick={raiseInvoiceClicked}
        icon={<DownloadOutlined />}
      >
        {messages.Export[lan]}
      </Button>
    </>
  );
};
export default Exportbuttonwithoutdropdown;
