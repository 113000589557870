export const messages = {
  dashboard: {
    EN: "Dashboard",
    ES: "Tablero",
    FR: "Tableau de bord",
    PT: "Painel",
    GU: "ડેશબોર્ડ",
    ZH: "仪表板",
  },
  amount: {
    EN: "Amount",
    ES: "Monto",
    FR: "Montante",
    PT: "Resultar",
    GU: "રકમ",
    ZH: "数量",
  },
  salesSummary: {
    EN: "Sales Summary",
    ES: "Resumen de ventas",
    FR: "Résumé des ventes",
    PT: "Resumo de Vendas",
    GU: "વેચાણ સારાંશ",
    ZH: "销售概要",
  },
  itemSales: {
    EN: "Item Sales",
    ES: "Ventas de artículos",
    FR: "Ventes d'articles",
    PT: "Vendas de Itens",
    GU: "વસ્તુનું વેચાણ",
    ZH: "物品销售",
  },
  giftCards:{
    EN:"Gift Cards",
    ES:'Tarjetas de regalo',
    FR:'Cartes cadeaux',
    PT:'Cartões de presente',
    GU:'ભેટ માં આપવાના કાર્ડ્સ',
    ZH:'礼品卡'
  },
  totalConsumed:{
    EN:"Total Consumed",
    ES:'Total consumido',
    FR:'Total consommé',
    PT:'Total Consumido',
    GU:'કુલ વપરાશ',
    ZH:'总消耗量'
  },
  totalActive:{
    EN:"Total Active",
    ES:'Total activo',
    FR:'Actif total',
    PT:'Totalmente Ativo',
    GU:'કુલ સક્રિય',
    ZH:'总活跃度'
  },
  totalInactive:{
    EN:"Total Inactive",
    ES:'Total inactivo',
    FR:'Total inactif',
    PT:'Total Inativo',
    GU:'કુલ નિષ્ક્રિય',
    ZH:'完全不活跃'
  },
  totalCards:{
    EN:"Total Cards",
    ES:'Tarjetas totales',
    FR:'Total des cartes',
    PT:'Total de cartas',
    GU:'કુલ કાર્ડ્સ',
    ZH:'总牌数'
  },
  productName:{
    EN:"Product Name",
    ES:'Nombre del producto',
    FR:'Nom du produit',
    PT:'Nome do Produto',
    GU:'ઉત્પાદન નામ',
    ZH:'产品名称'
  },
  cardTypeDescription:{
    EN:"Type",
    ES:'Tipo',
    FR:'Taper',
    PT:'Tipo',
    GU:'પ્રકાર',
    ZH:'类型'
  },
  fixedAmount:{
    EN:"Fixed Amount",
    ES:'Cantidad fija',
    FR:'Montant fixé',
    PT:'Quantia fixa',
    GU:'નિશ્ચિત રકમ',
    ZH:'固定值'
  },
  minAmount:{
    EN:"Min Amount",
    ES:'Monto minimo',
    FR:'Montant minimal',
    PT:'Quantidade mínima',
    GU:'ન્યૂનતમ રકમ',
    ZH:'最低金额'
  },
  maxAmount:{
    EN:"Max Amount",
    ES:'Monto Máximo',
    FR:'Quantité maximale',
    PT:'Quantia máxima',
    GU:'મહત્તમ રકમ',
    ZH:'最高金额'
  },
  isActive:{
    EN:"Active Status",
    ES:'Estado activo',
    FR:'Statut actif',
    PT:'Status Ativo',
    GU:'સક્રિય સ્થિતિ',
    ZH:'活跃状态'
  },
  isConsumed:{
    EN:"Consumed Status",
    ES:'Estado consumido',
    FR:'Statut consommé',
    PT:'Status Consumido',
    GU:'વપરાશની સ્થિતિ',
    ZH:'消费状态'
  },
  creationDate:{
    EN:"Created Date",
    ES:'Fecha de creación',
    FR:'Date de création',
    PT:'Data de criação',
    GU:'તારીખ બનાવી',
    ZH:'创建日期'
  },
  activationDate:{
    EN:"Activation Date",
    ES:'Fecha de activacion',
    FR:`Date d'activation`,
    PT:'Data de ativação',
    GU:'સક્રિયકરણ તારીખ',
    ZH:'激活日期'
  },
  disabledDate:{
    EN:"Disabled Date",
    ES:'Fecha de desactivación',
    FR:'Date de désactivation',
    PT:'Data de desativação',
    GU:'અક્ષમ તારીખ',
    ZH:'禁用日期'
  },
  merchantManagement: {
    EN: "Merchant Management",
    ES: "Gestión de comerciantes",
    FR: "Gestion des marchands",
    PT: "Gestão de Comerciantes",
    GU: "મર્ચન્ટ મેનેજમેન્ટ",
    ZH: "商户管理",
  },
  brokerManagement: {
    EN: "Broker Management",
    ES: "Gestión de corredores",
    FR: "Gestion des courtiers",
    PT: "Gestão da Corretora",
    GU: "બ્રોકર મેનેજમેન્ટ",
    ZH: "经纪人管理",
  },
  orderManagement: {
    EN: "Order Management",
    ES: "Gestión de pedidos",
    FR: "La gestion des commandes",
    PT: "Gestão de Pedidos",
    GU: "ઓર્ડર મેનેજમેન્ટ",
    ZH: "订单管理",
  },
  productManagement: {
    EN: "Product Management",
    ES: "Gestión de productos",
    FR: "Gestion des produits",
    PT: "Gestão de produtos",
    GU: "ઉત્પાદન વ્યવસ્થાપન",
    ZH: "产品管理",
  },
  catalogManagement: {
    EN: "Catalog Management",
    ES: "Gestión de catálogos",
    FR: "Gestion de catalogue",
    PT: "Gestão de Catálogo",
    GU: "કેટલોગ મેનેજમેન્ટ",
    ZH: "目录管理",
  },
  groceryManagement: {
    EN: "Grocery Management",
    ES: "Gestión de abarrotes",
    FR: "Gestion d'épicerie",
    PT: "Gestão de Mercearia",
    GU: "ગ્રોસરી મેનેજમેન્ટ",
    ZH: "杂货管理",
  },
  employeeManagement: {
    EN: "Employee Management",
    ES: "Gestión de empleados",
    FR: "Gestion des employés",
    PT: "Gestão de Funcionários",
    GU: "કર્મચારી વ્યવસ્થાપન",
    ZH: "员工管理",
  },
  kardstashStoreQR: {
    EN: "Kardstash Store QR",
    ES: "Tienda Kardstash QR",
    FR: "QR du magasin Karstash",
    PT: "Kardstash Store QR",
    GU: "Kardstash સ્ટોર QR",
    ZH: "Kardstash 商店二维码",
  },
  advertManagement: {
    EN: "Advert Management",
    ES: "Gestión de anuncios",
    FR: "Gestion des publicités",
    PT: "Gerenciamento de anúncios",
    GU: "એડવર્ટ મેનેજમેન્ટ",
    ZH: "广告管理",
  },
  listing: {
    EN: "Listing",
    ES: "Listado",
    FR: "Référencement",
    PT: "Listagem",
    GU: "લિસ્ટિંગ",
    ZH: "清单",
  },
  pendingList: {
    EN: "Pending List",
    ES: "Lista de pendientes",
    FR: "Liste en attente",
    PT: "Lista Pendente",
    GU: "બાકી યાદી",
    ZH: "待处理清单",
  },
  financeManagement: {
    EN: "Finance Management",
    ES: "Gestión financiera",
    FR: "Gestion financière",
    PT: "Gestão Financeira",
    GU: "ફાયનાન્સ મેનેજમેન્ટ",
    ZH: "财务管理",
  },
  receivables: {
    EN: "Receivables",
    ES: "Cuentas por cobrar",
    FR: "Créances",
    PT: "Recebíveis",
    GU: "પ્રાપ્તિપાત્ર",
    ZH: "应收账款",
  },
  payables: {
    EN: "Payables",
    ES: "Cuentas a pagar",
    FR: "Dettes",
    PT: "Pagáveis",
    GU: "ચૂકવવાપાત્ર",
    ZH: "应付款",
  },
  activityLog: {
    EN: "Activity Log",
    ES: "Registro de actividades",
    FR: "Journal d'activité",
    PT: "Registro de atividade",
    GU: "પ્રવૃત્તિ લોગ",
    ZH: "活动日志",
  },
  ads: {
    EN: "Ads",
    ES: "Anuncios",
    FR: "Les publicités",
    PT: "Publicidades",
    GU: "જાહેરાતો",
    ZH: "广告",
  },
  allday: {
    EN: "All day",
    ES: "Todo el dia",
    FR: "Toute la journée",
    PT: "Dia todo",
    GU: "બધા દિવસ",
    ZH: "一整天",
  },
  summary: {
    EN: "Summary",
    ES: "Resumen",
    FR: "Résumé",
    PT: "Resumo",
    GU: "સારાંશ",
    ZH: "概括",
  },
  monthly: {
    EN: "Monthly",
    ES: "Mensual",
    FR: "Mensuelle",
    PT: "Por mês",
    GU: "માસિક",
    ZH: "每月",
  },
  weekly: {
    EN: "Weekly",
    ES: "Semanal",
    FR: "Hebdomadaire",
    PT: "Semanalmente",
    GU: "સાપ્તાહિક",
    ZH: "每周",
  },
  daily: {
    EN: "Daily",
    ES: "Diario",
    FR: "du quotidien",
    PT: "Diariamente",
    GU: "દૈનિક",
    ZH: "日常的",
  },
  dayOfWeek: {
    EN: "Day of week",
    ES: "Día de la semana",
    FR: "Jour de la semaine",
    PT: "Dia da semana",
    GU: "અઠવાડિયાનો દિવસ",
    ZH: "星期几",
  },
  timeOfDay: {
    EN: "Time of day",
    ES: "Hora del día",
    FR: "Moment de la journée",
    PT: "Hora do dia",
    GU: "દિવસનો સમય",
    ZH: "一天中的时间",
  },
  summaryReport: {
    EN: "Summary Report",
    ES: "Informe resumido",
    FR: "Rapport sommaire",
    PT: "Relatório de Resumo",
    GU: "સારાંશ અહેવાલ",
    ZH: "总结报告",
  },
  sales: {
    EN: "Interval",
    ES: "Ventas",
    FR: "Ventes",
    PT: "Vendas",
    GU: "અંતરાલ",
    ZH: "间隔",
  },
  netSale: {
    EN: "Net Sale",
    ES: "Venta neta",
    FR: "Vente nette",
    PT: "Venda Líquida",
    GU: "નેટ સેલ",
    ZH: "净销售额",
  },
  return: {
    EN: "Return",
    ES: "Regreso",
    FR: "Revenir",
    PT: "Retornar",
    GU: "પરત",
    ZH: "返回",
  },
  paymentCollected: {
    EN: "Payment Collected",
    ES: "Pago cobrado",
    FR: "Paiement perçu",
    PT: "Pagamento Recebido",
    GU: "ચુકવણી એકત્રિત કરી",
    ZH: "已收款",
  },
  totalCollected: {
    EN: "Total Collected",
    ES: "Total recaudado",
    FR: "Total collecté",
    PT: "Total coletado",
    GU: "કુલ એકત્રિત",
    ZH: "总收集",
  },

  returnAmt: {
    EN: "Return",
    ES: "Regreso",
    FR: "Revenir",
    PT: "Retornar",
    GU: "પરત",
    ZH: "返回",
  },
  cash: {
    EN: "Cash",
    ES: "Dinero en efectivo",
    FR: "En espèces",
    PT: "Dinheiro",
    GU: "રોકડ",
    ZH: "现金",
  },
  card: {
    EN: "Card",
    ES: "Tarjeta",
    FR: "Carte",
    PT: "Cartão",
    GU: "કાર્ડ",
    ZH: "卡片",
  },
  wallet: {
    EN: "Wallet",
    ES: "Cartera",
    FR: "Portefeuille",
    PT: "Carteira",
    GU: "વૉલેટ",
    ZH: "钱包",
  },
  other: {
    EN: "Other",
    ES: "Otra",
    FR: "Autre",
    PT: "Outra",
    GU: "અન્ય",
    ZH: "其他",
  },
  customerInvoice: {
    EN: "Invoice",
    ES: "Invoice",
    FR: "Invoice",
    PT: "Invoice",
    GU: "Invoice",
    ZH: "Invoice",
  },
  tax: {
    EN: "Tax",
    ES: "Tax",
    FR: "Tax",
    PT: "Tax",
    GU: "Tax",
    ZH: "Tax",
  },
  addReportingHours: {
    EN: "Add reporting hours",
    ES: "Agregar horas de informes",
    FR: "Ajouter des heures de rapport",
    PT: "Adicionar horas de relatório",
    GU: "રિપોર્ટિંગ કલાક ઉમેરો",
    ZH: "添加报告时间",
  },
  save: {
    EN: "Save",
    ES: "Ahorrar",
    FR: "Sauvegarder",
    PT: "Salve ",
    GU: "સાચવો",
    ZH: "节省",
  },
  cancel: {
    EN: "Cancel",
    ES: "Cancelar",
    FR: "Annuler",
    PT: "Cancelar",
    GU: "રદ કરો",
    ZH: "取消",
  },
  selecttime: {
    EN: "Select Time",
    ES: "Seleccionar hora",
    FR: "Sélectionnez l'heure",
    PT: "Selecione o tempo",
    GU: "સમય પસંદ કરો",
    ZH: "选择时间",
  },
  shiftName: {
    EN: "Shift name",
    ES: "Nombre de turno",
    FR: "Nom de l'équipe",
    PT: "Nome do turno",
    GU: "શિફ્ટ નામ",
    ZH: "班次名称",
  },
  shiftNameRequired: {
    EN: "Please enter shift name",
    ES: "Ingrese el nombre del turno",
    FR: "Veuillez saisir le nom de l'équipe",
    PT: "Por favor, insira o nome do turno",
    GU: "કૃપા કરીને શિફ્ટનું નામ દાખલ કરો",
    ZH: "请输入班次名称",
  },
  shiftNamePlaceholder: {
    EN: "Enter shift name",
    ES: "Ingrese el nombre del turno",
    FR: "Entrez le nom de l'équipe",
    PT: "Insira o nome do turno",
    GU: "શિફ્ટ નામ દાખલ કરો",
    ZH: "输入班次名称",
  },
  startTime: {
    EN: "Start time",
    ES: "Hora de inicio",
    FR: "Heure de début",
    PT: "Hora de início",
    GU: "પ્રારંભ સમય",
    ZH: "开始时间",
  },
  startTimeRequired: {
    EN: "Please enter start time",
    ES: "Ingrese la hora de inicio",
    FR: "Veuillez saisir l'heure de début",
    PT: "Por favor, insira a hora de início",
    GU: "કૃપા કરીને પ્રારંભ સમય દાખલ કરો",
    ZH: "请输入开始时间",
  },
  endTime: {
    EN: "End time",
    ES: "Hora de finalización",
    FR: "Heure de fin",
    PT: "Fim do tempo",
    GU: "સમાપ્તિ સમય",
    ZH: "时间结束",
  },
  endTimeMessage: {
    EN: "Please enter end time",
    ES: "Ingrese la hora de finalización",
    FR: "Veuillez saisir l'heure de fin",
    PT: "Por favor, insira o horário de término",
    GU: "કૃપા કરીને સમાપ્તિ સમય દાખલ કરો",
    ZH: "请输入结束时间",
  },
  timezone: {
    EN: "Timezone",
    ES: "Zona horaria",
    FR: "Fuseau horaire",
    PT: "Fuso horário",
    GU: "સમય ઝોન",
    ZH: "时区",
  },
  timezoneRequired: {
    EN: "Please enter timezone",
    ES: "Por favor ingrese la zona horaria",
    FR: "Veuillez saisir le fuseau horaire",
    PT: "Por favor, insira o fuso horário",
    GU: "કૃપા કરીને ટાઇમઝોન દાખલ કરો",
    ZH: "请输入时区",
  },
  timezonePlaceholder: {
    EN: "Select timezone",
    ES: "Selecciona la zona horaria",
    FR: "Sélectionnez le fuseau horaire",
    PT: "Selecione o fuso horário",
    GU: "ટાઇમઝોન પસંદ કરો",
    ZH: "选择时区",
  },
  selectLocation: {
    EN: "Select Location",
    ES: "Seleccionar ubicación",
    FR: "Sélectionnez l'emplacement",
    PT: "Selecione a localização",
    GU: "સ્થાન પસંદ કરો",
    ZH: "选择地点",
  },
  Download: {
    EN: "Download",
    ES: "Descargar",
    FR: "Télécharger",
    PT: "Download",
    GU: "ડાઉનલોડ કરો",
    ZH: "下载",
  },
  Export: {
    EN: "Export",
    ES: "Exportar",
    FR: "Exportation",
    PT: "Exportar",
    GU: "નિકાસ કરો",
    ZH: "出口",
  },
  selectPaymentType: {
    EN: "Select Payment Type",
    ES: "Seleccione el tipo de pago",
    FR: "Sélectionnez le type de paiement",
    PT: "Selecione o tipo de pagamento",
    GU: "ચુકવણીનો પ્રકાર પસંદ કરો",
    ZH: "选择付款方式",
  },
  all: {
    EN: "All",
    ES: "Todas",
    FR: "Tout",
    PT: "Tudo",
    GU: "બધા",
    ZH: "全部",
  },
  emptyTitleWallet: {
    EN: "No Wallet Transaction History Available.",
    ES: "No hay historial de transacciones de billetera disponible.",
    FR: "Aucun historique des transactions de portefeuille disponible.",
    PT: "Nenhum histórico de transações da carteira disponível.",
    GU: "કોઈ વૉલેટ વ્યવહાર ઇતિહાસ ઉપલબ્ધ નથી.",
    ZH: "没有可用的钱包交易历史记录。",
  },
  emptyTitleProductInventory: {
    EN: "Product Inventory Not Available",
    ES: "Inventaire de produits non disponible",
    FR: "Inventaire de produits non disponible",
    PT: "Nenhum histórico de transações da carteira disponível.",
    GU: "પ્રોડક્ટ ઇન્વેન્ટરી ઉપલબ્ધ નથી",
    ZH: "产品库存不可用",
  },
  emptyTitle: {
    EN: "No Sales in This Time Frame.",
    ES: "No hay ventas en este período de tiempo.",
    FR: "Aucune vente dans ce laps de temps.",
    PT: "Nenhuma venda neste período de tempo.",
    GU: "આ સમયની ફ્રેમમાં કોઈ વેચાણ નહીં.",
    ZH: "在此时间范围内没有销售。",
  },
  emptyDescription: {
    EN: "No sales took place during the time frame you selected.",
    ES: "No hubo ventas durante el período de tiempo que seleccionó.",
    FR: "Aucune vente n'a eu lieu pendant la période que vous avez sélectionnée.",
    PT: "Nenhuma venda ocorreu durante o período de tempo que você selecionou.",
    GU: "તમે પસંદ કરેલ સમયમર્યાદા દરમિયાન કોઈ વેચાણ થયું નથી.",
    ZH: "在您选择的时间范围内没有发生销售。",
  },

  quantity: {
    EN: "Quantity",
    ES: "Cantidad",
    FR: "Quantité",
    PT: "Quantidade",
    GU: "જથ્થો",
    ZH: "数量",
  },
  topFiveItems: {
    EN: "Top 5 Items",
    ES: "5 artículos principales",
    FR: "Top 5 des articles",
    PT: "5 itens principais",
    GU: "ટોચની 5 આઇટમ્સ",
    ZH: "前 5 项",
  },
  topTenItems: {
    EN: "Top 10 Items",
    ES: "10 artículos principales",
    FR: "Top 10 des articles",
    PT: "10 principais itens",
    GU: "ટોચની 10 આઇટમ્સ",
    ZH: "前 10 项",
  },
  itemName: {
    EN: "Item name",
    ES: "Nombre del árticulo",
    FR: "Nom de l'article",
    PT: "Nome do item",
    GU: "વસ્તુનુ નામ",
    ZH: "项目名",
  },
  category: {
    EN: "Category",
    ES: "Categoría",
    FR: "Catégorie",
    PT: "Categoria",
    GU: "શ્રેણી",
    ZH: "类别",
  },
  unitsSold: {
    EN: "Units sold",
    ES: "Unidades vendidas",
    FR: "Unités vendues",
    PT: "Unidades vendidas",
    GU: "એકમો વેચાયા",
    ZH: "售出单位",
  },
  salesAmount: {
    EN: "Sales amount",
    ES: "Cantidad de ventas",
    FR: "Montant des ventes",
    PT: "Total de vendas",
    GU: "વેચાણની રકમ",
    ZH: "销售额",
  },
  Jan: {
    EN: "Jan",
    ES: "enero",
    FR: "janvier",
    PT: "Janeiro",
    GU: "જાન્યુ",
    ZH: "简",
  },
  Feb: {
    EN: "Feb",
    ES: "febrero",
    FR: "février",
    PT: "fevereiro",
    GU: "ફેબ્રુ",
    ZH: "ફેબ્રુ",
  },
  Mar: {
    EN: "Mar",
    ES: "marcha",
    FR: "Mars",
    PT: "Março",
    GU: "માર",
    ZH: "三月",
  },
  Apr: {
    EN: "Apr",
    ES: "abril",
    FR: "avril",
    PT: "abril",
    GU: "એપ્રિલ",
    ZH: "4月",
  },
  May: {
    EN: "May",
    ES: "Mayo",
    FR: "Mai",
    PT: "Maio",
    GU: "મે",
    ZH: "可能",
  },
  Jun: {
    EN: "Jun",
    ES: "junio",
    FR: "juin",
    PT: "Junho",
    GU: "જુન",
    ZH: "君",
  },
  Jul: {
    EN: "Jul",
    ES: "mes de julio",
    FR: "juillet",
    PT: "julho",
    GU: "જુલાઇ",
    ZH: "七月",
  },
  Aug: {
    EN: "Aug",
    ES: "agosto",
    FR: "août",
    PT: "agosto",
    GU: "ઓગસ્ટ",
    ZH: "八月",
  },
  Sep: {
    EN: "Sep",
    ES: "septiembre",
    FR: "septembre",
    PT: "setembro",
    GU: "સપ્ટે",
    ZH: "九月",
  },
  Oct: {
    EN: "Oct",
    ES: "octubre",
    FR: "octobre",
    PT: "Outubro",
    GU: "ઑક્ટો",
    ZH: "十月",
  },
  Nov: {
    EN: "Nov",
    ES: "noviembre",
    FR: "novembre",
    PT: "novembro",
    GU: "નવે",
    ZH: "十一月",
  },
  Dec: {
    EN: "Dec",
    ES: "diciembre",
    FR: "décembre",
    PT: "dezembro",
    GU: "ડિસે",
    ZH: "十二月",
  },
  Yes: {
    EN: "Yes",
    ES: "sí",
    FR: "Oui",
    PT: "sim",
    GU: "હા",
    ZH: "是的",
  },
  No: {
    EN: "No",
    ES: "No",
    FR: "Non",
    PT: "Não",
    GU: "ના",
    ZH: "不",
  },
  deleteReportingHoursMessage: {
    EN: "Are you sure to delete this reporting hours?",
    ES: "¿Estás seguro de eliminar este horario de informes?",
    FR: "Voulez-vous vraiment supprimer ces heures de rapport?",
    PT: "Tem certeza de que deseja excluir este horário de relatório?",
    GU: "શું તમે આ રિપોર્ટિંગ કલાકો કાઢી નાખવાની ખાતરી કરો છો?",
    ZH: "您确定要删除此报告时间吗？",
  },
  Delete: {
    EN: "Delete",
    ES: "Borrar",
    FR: "Effacer",
    PT: "Excluir",
    GU: "કાઢી નાખો",
    ZH: "删除",
  },
  CopyRight: {
    EN: "Ekikart.All Rights Reserved",
    ES: "Ekikart.Todos los derechos reservados",
    FR: "Ekikart.Tous droits réservés",
    PT: "Ekikart.Todos os direitos reservados",
    GU: "Ekikart.સર્વ અધિકાર સુરક્ષિત",
    ZH: "Ekikart.保留所有权利",
  },
  Today: {
    EN: "Today",
    ES: "Hoy",
    FR: "Aujourd'hui",
    PT: "Hoje",
    GU: "આજે",
    ZH: "今天",
  },
  Yesterday: {
    EN: "Yesterday",
    ES: "Ayer",
    FR: "Hier",
    PT: "Ontem",
    GU: "ગઇકાલે",
    ZH: "昨天",
  },
  thisWeek: {
    EN: "This Week",
    ES: "Esta semana",
    FR: "Cette semaine",
    PT: "Esta semana",
    GU: "આ અઠવાડિયે",
    ZH: "本星期",
  },
  lastWeek: {
    EN: "Last Week",
    ES: "La semana pasada",
    FR: "La semaine dernière",
    PT: "Semana passada",
    GU: "ગયા સપ્તાહે",
    ZH: "上周",
  },
  thisMonth: {
    EN: "This Month",
    ES: "Este mes",
    FR: "Ce mois-ci",
    PT: "Este mês",
    GU: "આ મહિને",
    ZH: "这个月",
  },
  lastMonth: {
    EN: "Last Month",
    ES: "El mes pasado",
    FR: "Le mois dernier",
    PT: "Mês passado",
    GU: "ગયા મહિને",
    ZH: "上个月",
  },
  thisYear: {
    EN: "This Year",
    ES: "Este año",
    FR: "Cette année",
    PT: "Este ano",
    GU: "આ વર્ષ",
    ZH: "今年",
  },
  lastYear: {
    EN: "Last Year",
    ES: "El año pasado",
    FR: "L'année dernière",
    PT: "Ano passado",
    GU: "ગયું વરસ",
    ZH: "去年",
  },
  noData: {
    EN: "No Data",
    ES: "Sin datos",
    FR: "Pas de données",
    PT: "Sem dados",
    GU: "કોઈ ડેટા નથી",
    ZH: "没有数据",
  },
  transactionSummary: {
    EN: "Transaction Summary",
    ES: "Resumen de Transacciones",
    FR: "récapitulatif des transactions",
    PT: "Resumo transação",
    GU: "વ્યવહાર સારાંશ",
    ZH: "交易概要",
  },
  transactionType: {
    EN: "Transaction Type",
    ES: "tipo de transacción",
    FR: "Type de transaction", 
    PT: "Tipo de transação",
    GU: "વ્યવહારનો પ્રકાર",
    ZH: "交易类型",
  },
  dateTime: {
    EN: "Date & Time",
    ES: "Fecha y hora",
    FR: "Date et heure",
    PT: "Data hora",
    GU: "તારીખ સમય",
    ZH: "约会时间",
  },
  balance: {
    EN: "Balance",
    ES: "Equilibrio",
    FR: "Équilibre",
    PT: "Equilíbrio",
    GU: "સંતુલન",
    ZH: "平衡",
  },
  orderId: {
    EN: "Order ID",
    ES: "Solicitar ID",
    FR: "Numéro de commande",
    PT: "Id do pedido",
    GU: "ઓર્ડર આઈડી",
    ZH: "订单编号",
  },
  walletloaded: {
    EN: "Wallet loaded",
    ES: "Cartera cargada",
    FR: "Portefeuille chargé",
    PT: "Carteira carregada",
    GU: "વૉલેટ લોડ કર્યું",
    ZH: "钱包加载",
  },
  paidForOrder: {
    EN: "Paid for order Id",
    ES: "Pagué por la orden que yo",
    FR: "Payé pour la commande, je le ferais",
    PT: "Pago pelo pedido que eu",
    GU: "ઓર્ડર આઈડી માટે ચૂકવણી",
    ZH: "支付订单ID",
  },
  cashback: {
    EN: "Cashback",
    ES: "Devolución de dinero",
    FR: "Remise en argent",
    PT: "Dinheiro de volta",
    GU: "પાછા આવેલા પૈસા",
    ZH: "返现金",
  },
  organizationName: {
    EN: "Organization Name",
    ES: "Nombre de la Organización",
    FR: "nom de l'organisation",
    PT: "Nome da organização",
    GU: "સંસ્થા નુ નામ",
    ZH: "机构名称",
  },
  EBT: {
    EN: "EBT",
    ES: "EBT",
    FR: "EBT",
    PT: "EBT",
    GU: "EBT",
    ZH: "EBT",
  },
  customPayment: {
    EN: "Custom Payment",
    ES: "Pago personalizado",
    FR: "Paiement personnalisé",
    PT: "Pagamento personalizado",
    GU: "કસ્ટમ ચુકવણી",
    ZH: "自定义付款",
  },
  productInventory: {
    EN: "Product Inventory",
    ES: "Inventario de productos",
    FR: "Inventaire des produits",
    PT: "Inventário de produtos",
    GU: "ઉત્પાદન ઈન્વેન્ટરી",
    ZH: "产品库存",
  },
  upc: {
    EN: "UPC/PLU",
    ES: "UPC/PLU",
    FR: "UPC/PLU",
    PT: "UPC/PLU",
    GU: "UPC/PLU",
    ZH: "UPC/PLU",
  },
  inventoryCount: {
    EN: "Inventory",
    ES: "Inventario",
    FR: "Inventaire",
    PT: "Inventário",
    GU: "ઇન્વેન્ટરી",
    ZH: "存货",
  },
  costPrice: {
    EN: "Cost Price",
    ES: "Precio de costo",
    FR: "Prix coûtant",
    PT: "Preço de custo",
    GU: "પડતર કિંમત",
    ZH: "成本价",
  },
  sellPrice: {
    EN: "Sell Price",
    ES: "Precio de venta",
    FR: "Prix de vente",
    PT: "Preço de venda",
    GU: "વેચાણ કિંમત",
    ZH: "售价",
  },
  selectVendor: {
    EN: "Select vendor",
    ES: "Seleccionar proveedor",
    FR: "Choisir un fournisseur",
    PT: "Selecionar Fornecedor",
    GU: "વેન્ડર પસંદ કરો",
    ZH: "选择供应商",
  },
  action: {
    EN: "Action",
    ES: "Acción",
    FR: "Action",
    PT: "Ação",
    GU: "ક્રિયા",
    ZH: "行动",
  },
  lowStock: {
    EN: "Low stock",
    ES: "Pocas existencias",
    FR: "Stock faible",
    PT: "Baixo estoque",
    GU: "ઓછો જથ્થો",
    ZH: "低库存",
  },
  alertAt: {
    EN: "Alert At",
    ES: "Alerta en",
    FR: "Alerte à",
    PT: "Alerta em",
    GU: "એલર્ટ મુ",
    ZH: "警报时间",
  },
  editProductInventory: {
    EN: "Edit The Product Data",
    ES: "Editar los datos del producto",
    FR: "Modifier les données du produit",
    PT: "Editar os dados do produto",
    GU: "ઉત્પાદન ડેટા સંપાદિત કરો",
    ZH: "编辑产品数据",
  },
  lastUpdated: {
    EN: "Last Updated",
    ES: "Última actualización",
    FR: "Dernière mise à jour",
    PT: "Ultima atualização",
    GU: "છેલ્લું અપડેટ",
    ZH: "最近更新时间",
  },
  selectCategory: {
    EN: "Select Category",
    ES: "Selecciona una categoría",
    FR: "Choisir une catégorie",
    PT: "Selecione a Categoria",
    GU: "શ્રેણી પસંદ કરો",
    ZH: "选择类别",
  },
  selectSubCategory: {
    EN: "Select Sub Category",
    ES: "Seleccionar subcategoría",
    FR: "Sélectionnez la sous-catégorie",
    PT: "Selecione a subcategoria",
    GU: "શ્સબ કેટેગરી પસંદ કરો",
    ZH: "选择子类别",
  },
  searchBy: {
    EN: "Search by: Item Name, UPC",
    ES: "Buscar por: Nombre del artículo, UPC",
    FR: "Rechercher par : Nom de l'article, UPC",
    PT: "Pesquisar por: Nome do item, UPC",
    GU: "આના દ્વારા શોધો: વસ્તુનું નામ, UPC",
    ZH: "搜索依据：商品名称、UPC",
  },
  currentQty: {
    EN: "Current Qty",
    ES: "Cantidad actual",
    FR: "Quantité actuelle",
    PT: "Quantidade atual",
    GU: "વર્તમાન જથ્થો",
    ZH: "当前数量",
  },
  alertAtQty: {
    EN: "Alert At Qty",
    ES: "Alerta en cantidad",
    FR: "Alerte à la quantité",
    PT: "Alerta na quantidade",
    GU: "પ્રમાણ પર ચેતવણી",
    ZH: "数量警报",
  },
  ok: {
    EN: "OK",
    ES: "OK",
    FR: "D'ACCORD",
    PT: "OK",
    GU: "પ્બરાબર",
    ZH: "好的",
  },
  page: {
    EN: "page",
    ES: "página",
    FR: "page",
    PT: "página",
    GU: "પાનું",
    ZH: "页",
  },
  sellPriceRequired: {
    EN: "Sell Price is required.",
    ES: "El precio de venta es obligatorio.",
    FR: "Le prix de vente est requis.",
    PT: "Preço de venda é obrigatório.",
    GU: "વેચાણ કિંમત જરૂરી છે.",
    ZH: "售价为必填项。",
  },
  sellPriceNumber: {
    EN: "Sell Price has to be a number.",
    ES: "El precio de venta tiene que ser un número.",
    FR: "Le prix de vente doit être un nombre.",
    PT: "O preço de venda deve ser um número.",
    GU: "વેચાણ કિંમત એક નંબર હોવો જોઈએ.",
    ZH: "售价必须是一个数字。",
  },
  costPriceNumber: {
    EN: "Cost Price has to be a number.",
    ES: "El precio de costo tiene que ser un número.",
    FR: "Le prix de revient doit être un nombre.",
    PT: "O preço de custo deve ser um número.",
    GU: "કિંમત કિંમત એક નંબર હોવો જોઈએ.",
    ZH: "成本价必须是一个数字。",
  },
  alertAtNumber: {
    EN: "Alert At Qty has to be a number.",
    ES: "Alert At Qty tiene que ser un número.",
    FR: "L'alerte à la quantité doit être un nombre.",
    PT: "Alert At Qty tem que ser um número.",
    GU: "Alert At Qty એક નંબર હોવો જોઈએ.",
    ZH: "Alert At Qty 必须是一个数字。",
  },
  currentQtyNumber: {
    EN: "Current Qty has to be a number.",
    ES: "La cantidad actual tiene que ser un número.",
    FR: "La quantité actuelle doit être un nombre.",
    PT: "A quantidade atual deve ser um número.",
    GU: "વર્તમાન જથ્થો એક નંબર હોવો જોઈએ.",
    ZH: "当前数量必须是一个数字。",
  },
  tip: {
    EN: "Tip",
    ES: "Consejo",
    FR: "Pointe",
    PT: "Dica",
    GU: "ટીપ",
    ZH: "小费",
  },
  timeClockManagement: {
    EN: "Time Clock Management",
    ES: "Gestión del reloj de tiempo",
    FR: "Gestion de l'horloge",
    PT: "Gerenciamento de relógio de ponto",
    GU: "સમય ઘડિયાળ વ્યવસ્થાપન",
    ZH: "时钟管理",
  },
  payroll: {
    EN: "Payroll",
    ES: "Gestión del reloj de tiempo",
    FR: "Gestion de l'horloge",
    PT: "Gerenciamento de relógio de ponto",
    GU: "સમય ઘડિયાળ વ્યવસ્થાપન",
    ZH: "时钟管理",
  },
  emptyTitleTimeClockManagement: {
    EN: "Time Clock Management Not Available",
    ES: "Gestión de reloj de tiempo no disponible",
    FR: "Gestion de l'horloge non disponible",
    PT: "Gerenciamento de relógio de ponto não disponível",
    GU: "સમય ઘડિયાળ વ્યવસ્થાપન ઉપલબ્ધ નથી",
    ZH: "时钟管理不可用",
  },
  employeeName: {
    EN: "Employee Name",
    ES: "Nombre de empleado",
    FR: "Nom de l'employé",
    PT: "Nome do empregado",
    GU: "કર્મચારીનું નામ",
    ZH: "员工姓名",
  },
  userPin: {
    EN: "User Pin",
    ES: "PIN de usuario",
    FR: "NIP de l'utilisateur",
    PT: "PIN do usuário",
    GU: "વપરાશકર્તા પિન",
    ZH: "用户密码",
  },
  clockIn: {
    EN: "Clock-in",
    ES: "Reloj en",
    FR: "Pointage",
    PT: "Hora de entrada",
    GU: "ઘડિયાળ-ઇન",
    ZH: "签到",
  },
  clockOut: {
    EN: "Clock-out",
    ES: "Reloj de salida",
    FR: "Horloge",
    PT: "Hora de saída",
    GU: "ક્લોક-આઉટ",
    ZH: "下班",
  },
  notes: {
    EN: "Notes",
    ES: "Notas",
    FR: "Remarques",
    PT: "Notas",
    GU: "નોંધો",
    ZH: "笔记",
  },
  clockOutType: {
    EN: "Clock-In/Clock-Out Type",
    ES: "Tipo de entrada/salida",
    FR: "Type d'horloge d'entrée/sortie",
    PT: "Tipo de entrada/saída",
    GU: "ક્લોક-ઇન/ક્લોક-આઉટ પ્રકાર",
    ZH: "上班/下班类型",
  },
  workingHours: {
    EN: "Working Hours",
    ES: "Horas Laborales",
    FR: "Heures d'ouverture",
    PT: "Jornada de trabalho",
    GU: "કામ નાં કલાકો",
    ZH: "工作时间",
  },
  breakHours:{
    EN:"Break Hours",
    ES: "Horas de descanso",
    FR: "Heures de pause",
    PT: "Horário de intervalo",
    GU: "બ્રેક અવર્સ",
    ZH: "休息时间",
  },
  totalProductValue : {
    EN: "Total Product Value",
    ES: "Valor Total del Producto",
    FR: "Valeur Totale du Produit",
    PT: "Valor Total do Produto",
    GU: "કુલ ઉત્પાદ મૂલ્ય",
    ZH: "总产品价值",
  },
  dateRange:{
    EN: "Date Range",
    ES: "Rango de fechas",
    FR: "Plage de dates",
    PT: "Período",
    GU: "તારીખ શ્રેણી",
    ZH: "日期范围",
  },
  ratePerHour:{
    EN: "Salary Per Hour",
    ES: "Salario por hora",
    FR: "Salaire par heure",
    PT: "Salário por hora",
    GU: "કલાક દીઠ પગાર",
    ZH: "每小时工资",
  },
  noOfHoursWorked:{
    EN: "No Of Hours Worked",
    ES: "Número de horas trabajadas",
    FR: "Nombre d'heures travaillées",
    PT: "Nº de horas trabalhadas",
    GU: "કામના કલાકોની સંખ્યા",
    ZH: "工作时间",
  },
  totalWages:{
    EN:"Total Wages",
    ES: "Salarios totales",
    FR: "Salaires totaux",
    PT: "Salários totais",
    GU: "કુલ વેતન",
    ZH:"工资总额"
  },
  totalAmount:{
    EN:"Total Amount",
    ES: "Cantidad total",
    FR: "Montant total",
    PT: "Montante total",
    GU: "કુલ રકમ",
    ZH:"总金额"
  },
  contractorName:{
    EN:"Contractor Name",
    ES: "El nombre del contratista",
    FR: "Nom de l'entrepreneur",
    PT: "Nome do contratante",
    GU: "કોન્ટ્રાક્ટરનું નામ",
    ZH:"承包商名称"
  },
  serviceRendered:{
    EN:"Service Rendered",
    ES: "Servicio prestado",
    FR: "Service rendu",
    PT: "Serviço prestado",
    GU: "સેવા પ્રદાન કરી",
    ZH:"服务呈现"
  },
  percentage:{
    EN:"Percentage",
    ES: "Porcentaje",
    FR: "Pourcentage",
    PT: "Percentagem",
    GU: "ટકાવારી",
    ZH:"百分比"
  },
  mailReport:{
    EN:"Mail Report",
    ES: "Informe por correo",
    FR: "Rapport de courrier",
    PT: "Relatório de correio",
    GU: "મેલ રિપોર્ટ",
    ZH:"邮件报告"
  }
};
