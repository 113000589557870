import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Card,
  Input,
  Skeleton,
  Modal,
  Select,
  message,
  Spin,
  InputNumber,
  Radio,
  Menu,
  Checkbox
} from "antd";
import {
  ApiHelper,
  getUserData,
  message as cMessage
} from "../../../../utils/Helpers";
import { messages } from "../../../../utils/Common/Messages";
import Emptymsg from "../../../layout/message/Emptymsg";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { AppConfig } from "../../../../utils/Config/AppConfig";
import "./sell.less";
import {
  DeleteOutlined,
  PlusOutlined,
  MinusOutlined,
  LoadingOutlined,
  CodepenOutlined,
  TranslationOutlined,
  BarcodeOutlined,
  SearchOutlined,
  CloseOutlined
} from "@ant-design/icons";
const { Content } = Layout;
const defaultAmount = [1.0, 5.0, 10.0, 15.0, 24.0];
const defaultCart = {
  totalAmount: 0,
  showTotalAmount: 0,
  showSubTotalAmount: 0,
  totalTaxAmount: 0,
  totalProductAmountReturn: 0,
  totalProductAmountPurchase: 0,
  totalDiscountAmount: 0,
  totalFee: 0,
  feeDiscount: 0,
  feeDiscountType: 0,
  feeDiscountAmount: 0,
  feeAmountDiscounted: 0,
  valueOfPerOrFixed: 0,
  products: []
};
const antIcon = <LoadingOutlined style={{ fontSize: "2.6rem" }} spin />;
const SellPage = () => {
  const { Option } = Select;
  const ref = useRef();
  const [itemCount, setItemCount] = useState(1);
  const [activeTab, setActiveTab] = useState("newItem");
  const [invoiceNoReturn, setInvoiceNoReturn] = useState("");

  const [returnProductTab, setReturnProductTab] = useState(1);
  const {
    currencySign,
    parentOrganizationId,
    organizationId,
    language,
    employeeId,
    isShowReturn,
    EBT
    // organizationTypeId,
    // time_gmt
  } = getUserData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const lan = language ?? "EN";
  const [loadings, setLoadings] = React.useState(true);
  const [spinLoadings, setSpinLoadings] = React.useState(true);
  const [amount, setAmount] = React.useState("");
  const [inputError, setInputError] = useState("");
  const [inputErrorInvoiceNo, setInputErrorInvoiceNo] = useState("");
  const [taxSelect, setTaxSelect] = useState([]);
  const [tax, setTax] = useState();
  const [items, setItems] = useState(defaultCart);
  const [returnItems, setReturnItems] = useState([]);
  const [returnItemSelected, setReturnItemSelected] = useState([]);

  useEffect(() => {
    getLiteInfo();
    const data = localStorage.getItem("cartData")
      ? JSON.parse(localStorage.getItem("cartData"))
      : defaultCart;
    setItems(data);
    setItemCount(
      localStorage.getItem("cartData")
        ? localStorage.getItem("cartItemCount")
        : 1
    );
  }, []);

  const getLiteInfo = async () => {
    const api = new ApiHelper();
    await api.setHost(AppConfig.API_URL_CI);
    const response = await api.FetchFromServer(
      ApiRoutes.LIST_INFO.service,
      `${ApiRoutes.LIST_INFO.url}/${organizationId}/${parentOrganizationId}/${
        localStorage.getItem("pointePayOrderId") ?? ""
      }`,
      ApiRoutes.LIST_INFO.method,
      ApiRoutes.LIST_INFO.authenticate
    );
    if (response.code === 200 && parseInt(response.data.success) === 1) {
      setTax(response.data.data);
      if (parseInt(response.data.payment)) {
        localStorage.removeItem("cartData");
        localStorage.removeItem("cartItemCount");
        localStorage.removeItem("pointePayOrderId");

        setItems(defaultCart);
        setItemCount(1);
      }
    } else {
      setTax([]);
    }
    setLoadings(false);
    setSpinLoadings(false);
  };

  const handleAddToCart = (
    taxStatus = false,
    isEBT = false,
    selectedTax = "",
    returnType = 1
  ) => {
    if (parseFloat(amount) < 0 || amount === "") {
      setInputError("Enter the amount");
      return;
    }
    setLoadings(true);
    setSpinLoadings(true);
    let taxList = [];
    let totalTax = 0;
    let tempTax = 0;
    if (taxStatus) {
      tax.forEach((element) => {
        if (selectedTax.includes(element.taxId)) {
          tempTax = (parseFloat(amount) * parseFloat(element.tax)) / 100;
          totalTax += tempTax;
          taxList.push({
            ...element,
            orgProductTaxId: 0,
            productTax: tempTax
          });
        }
      });
    }
    const tempData = addToCartCalculation([
      ...items.products,
      {
        id: itemCount,
        productId: "",
        productName: returnType === 2 ? "MISC Return" : `ITEM ${itemCount} `,
        organizationProductId: "",
        productPriceId: "",
        productTypeId: 19,
        quantity: 1,
        productPrice: parseFloat(amount),
        productAmount: parseFloat(amount),
        discountId: "",
        discountAmount: 0,
        returnProductPurchaseQty: "",
        discountName: "",
        isIncomProduct: "",
        upc: "",
        orderProductId: "",
        isReturn: returnType === 2 ? true : false,
        isEBT: isEBT,
        productVariationId: "",
        variationName: "",
        productInventoryId: "",
        refPointepayOrderId: "",
        refOrderProductId: "",
        productPurchaseType: returnType === 2 ? 2 : 1, // return 2
        productImageId: "",
        tax: totalTax,
        taxAmount: totalTax,
        isWeighable: 0,
        weightUnitId: 0,
        plu: "",
        taxList: taxList
      }
    ]);
    setItems(tempData);

    setAmount("");
    setInputError("");
    setLoadings(false);
    setIsModalOpen(false);
    setSpinLoadings(false);
    setTaxSelect([]);
    localStorage.setItem("cartData", JSON.stringify(tempData));
    if (returnType === 1) {
      setItemCount(parseFloat(itemCount) + 1);
      localStorage.setItem("cartItemCount", parseFloat(itemCount) + 1);
    }
  };

  const handleQuantity = (i, type, returnProductPurchaseQty = "") => {
    let tempProducts = items.products;

    let tempQuantity =
      type === "add"
        ? returnProductPurchaseQty != ""
          ? parseInt(returnProductPurchaseQty) ===
            parseInt(items.products[i].quantity)
            ? parseInt(items.products[i].quantity)
            : parseInt(items.products[i].quantity) + 1
          : parseInt(items.products[i].quantity) + 1
        : parseInt(items.products[i].quantity) > 1
        ? parseInt(items.products[i].quantity) - 1
        : parseInt(items.products[i].quantity);
    if (type === "delete") {
      tempProducts.splice(i, 1);
      if (tempProducts.length > 0) {
        let count = 1;
        tempProducts = [
          ...tempProducts?.map((item) => {
            item.productName =
              item.productPurchaseType === 1
                ? `ITEM ${count++} `
                : item.productName;

            return item;
          })
        ];
      }
      const tempLeng = [
        ...tempProducts?.filter((item) => item.productPurchaseType === 1)
      ];
      console.log(tempLeng);
      setItemCount(tempLeng.length > 0 ? tempLeng.length + 1 : 1);
      localStorage.setItem(
        "cartItemCount",
        tempLeng.length > 0 ? tempLeng.length + 1 : 1
      );
    } else {
      tempProducts[i].taxAmount =
        parseFloat(items.products[i].tax) * tempQuantity;

      tempProducts[i].productAmount =
        parseFloat(items.products[i].productPrice) * tempQuantity;
      tempProducts[i].quantity = tempQuantity;
    }

    const tempData = addToCartCalculation(tempProducts);
    setItems(tempData);
    localStorage.setItem("cartData", JSON.stringify(tempData));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleShowTaxModel = () => {
    if (amount < 1 || amount === "") {
      setInputError("Enter the amount");
      return;
    }
    setInputError("");
    if (tax.length > 1) setIsModalOpen(true);
    else {
      handleAddToCart(true, false, [tax[0].taxId]);
    }
  };

  const taxHandleChange = (value) => {
    setTaxSelect(value);
  };

  const createOrder = async (type) => {
    setLoadings(true);
    setSpinLoadings(true);
    const cartData = { ...items };
    cartData.productPurchaseType = type;
    cartData.totalAmount = parseFloat(cartData.totalAmount).toFixed(2);
    cartData.showTotalAmount = parseFloat(cartData.showTotalAmount).toFixed(2);
    cartData.showSubTotalAmount = parseFloat(
      cartData.showSubTotalAmount
    ).toFixed(2);
    cartData.totalTaxAmount = parseFloat(cartData.totalTaxAmount).toFixed(2);
    cartData.totalProductAmountReturn = parseFloat(
      cartData.totalProductAmountReturn
    ).toFixed(2);
    cartData.totalProductAmountPurchase = parseFloat(
      cartData.totalProductAmountPurchase
    ).toFixed(2);
    cartData.csrf_test_name = AppConfig.CSRF_TEST_NAME;
    const api = new ApiHelper();
    await api.setHost(AppConfig.API_URL_CI);
    const response = await api.FetchFromServer(
      ApiRoutes.CREATE_ORDER.service,
      ApiRoutes.CREATE_ORDER.url,
      ApiRoutes.CREATE_ORDER.method,
      ApiRoutes.CREATE_ORDER.authenticate,
      undefined,
      cartData
    );

    if (response.code === 200) {
      // localStorage.removeItem("cartData");
      // localStorage.removeItem("cartItemCount");
      localStorage.removeItem(
        "pointePayOrderId",
        response.data.data.pointepayOrderId
      );
      window.location.href = `${AppConfig.CART_PAYMENT_PAGE}${response.data.data.pointepayOrderId}`;
    } else {
      message.error({
        content: cMessage.reportHourError,
        duration: 2
      });
      setLoadings(false);
      setSpinLoadings(false);
    }
  };

  const returnInvoiceHandle = async () => {
    if (invoiceNoReturn === "") {
      setInputErrorInvoiceNo("Enter the return invoice no.");
      return;
    }
    setInputErrorInvoiceNo("");

    const api = new ApiHelper();
    await api.setHost(AppConfig.API_URL_CI);

    const response = await api.FetchFromServer(
      ApiRoutes.RETURN_INVOICE.service,
      ApiRoutes.RETURN_INVOICE.url,
      ApiRoutes.RETURN_INVOICE.method,
      ApiRoutes.RETURN_INVOICE.authenticate,
      undefined,
      {
        search: invoiceNoReturn,
        request: true,
        returnType: "IR",
        searchProduct: "",
        csrf_test_name: AppConfig.CSRF_TEST_NAME,
        productNotFound: ""
      }
    );

    if (response.code === 200 && parseInt(response.data.success) === 1) {
      setReturnItems(response.data.list);
    } else {
      message.error({
        content: response.data.message,
        duration: 2
      });
      setLoadings(false);
      setSpinLoadings(false);
    }
  };
  const returnItemSelectHandler = (item = "", status) => {
    if (item === "" && status) {
      setReturnItemSelected(returnItems?.map((item) => item.orderProductId));
    } else if (item === "" && !status) setReturnItemSelected([]);
    else if (status) {
      if (!returnItemSelected.includes(item))
        setReturnItemSelected([...returnItemSelected, item]);
    } else {
      setReturnItemSelected(returnItemSelected.filter((e) => e !== item));
    }
  };

  const invoiceItemAddToCart = () => {
    setLoadings(true);
    setSpinLoadings(true);
    let totalTax = 0;
    let itemProduct = items?.products?.filter(
      (product) =>
        (product.isReturn && product.orderProductId === "") || !product.isReturn
    );

    returnItems.forEach((item) => {
      if (returnItemSelected.includes(item.orderProductId)) {
        itemProduct.push({
          id: item.orderProductId,
          productId: "",
          productName: item.productName,
          organizationProductId: "",
          productPriceId: "",
          productTypeId: 19,
          quantity: parseInt(item.currentQty),
          productPrice: parseFloat(item.salePrice),
          productAmount: parseFloat(item.salePrice) * parseInt(item.currentQty),
          discountId: "",
          discountAmount: 0.0,
          discountName: "",
          isIncomProduct: "",
          returnProductPurchaseQty: parseInt(item.currentQty),
          upc: "",
          isReturn: true,
          orderProductId: item.orderProductId, // orderProductId
          isEBT: false,
          productVariationId: "",
          variationName: "",
          productInventoryId: "",
          refPointepayOrderId: item.pointepayOrderId,
          refOrderProductId: item.orderProductId,
          productPurchaseType: 2, // return 2
          productImageId: "",
          tax: item.tax,
          taxAmount: parseFloat(item.tax) * parseInt(item.currentQty),
          isWeighable: 0,
          weightUnitId: 0,
          plu: "",
          taxList: item.taxList
        });
      }
    });
    const tempData = addToCartCalculation(itemProduct);
    setItems({ ...tempData });
    setAmount("");
    setInputError("");
    setLoadings(false);
    setIsModalOpen(false);
    setSpinLoadings(false);
    setTaxSelect([]);
    localStorage.setItem("cartData", JSON.stringify(tempData));
  };

  const clearInvoiceItem = () => {
    let itemProduct = items?.products?.filter(
      (product) =>
        (product.isReturn && product.orderProductId === "") || !product.isReturn
    );
    const tempData = addToCartCalculation(itemProduct);
    localStorage.setItem("cartData", JSON.stringify(tempData));
    setItems(tempData);
    setInvoiceNoReturn("");
    setReturnItems([]);
    setReturnItemSelected([]);
  };
  const addToCartCalculation = (itemProduct) => {
    const showSubTotalAmount =
      itemProduct.length > 0
        ? itemProduct
            ?.map((item) =>
              item.isReturn === false ? parseFloat(item.productAmount) : 0
            )
            ?.reduce((prev, next) => prev + next)
        : 0;
    const totalAmount =
      itemProduct.length > 0
        ? itemProduct
            ?.map((item) =>
              item.isReturn === false
                ? parseFloat(item.productAmount) + parseFloat(item.taxAmount)
                : item.isReturn === true
                ? -(parseFloat(item.productAmount) + parseFloat(item.taxAmount))
                : 0
            )
            ?.reduce((prev, next) => prev + next)
        : 0;
    const totalTaxAmount =
      itemProduct.length > 0
        ? itemProduct
            ?.map((item) =>
              item.isReturn === false
                ? parseFloat(item.taxAmount)
                : -parseFloat(item.taxAmount)
            )
            ?.reduce((prev, next) => prev + next)
        : 0;
    const totalProductAmountReturn =
      itemProduct.length > 0
        ? itemProduct
            ?.map((item) =>
              item.isReturn === true ? parseFloat(item.productAmount) : 0
            )
            ?.reduce((prev, next) => prev + next)
        : 0;
    return {
      ...items,
      employeeId,
      products: [...itemProduct],
      totalAmount: totalAmount,
      showTotalAmount: totalAmount,
      showSubTotalAmount: showSubTotalAmount,
      totalTaxAmount: totalTaxAmount,
      totalProductAmountReturn: totalProductAmountReturn,
      totalProductAmountPurchase: showSubTotalAmount
    };
  };
  const amountHandle = (value) => {
    let num = value.toString().split(".");
    if (
      num.length > 1 &&
      num[0].toString().length >= 6 &&
      num[1].toString().length > 1
    ) {
      setInputError("Amount Field Can not Exceed 6 Digits In length.");
      return;
    }
    setInputError("");
    if (isNaN(value) || value === "" || parseFloat(value) === 0) setAmount("");
    else if (value.toString().length <= 2 || num.length === 1)
      setAmount(parseFloat(value / 100).toFixed(2) ?? "");
    else {
      if (num.length > 1 && num[1].toString().length > 2) {
        console.log("elseif");
        const number = parseInt(
          String(1).padEnd(num[1].toString().length - 2 + 1, "0")
        );
        setAmount(parseFloat(value * number).toFixed(2) ?? "");
      } else if (num.length > 1 && num[1].toString().length < 2) {
        console.log("else1");
        setAmount(
          parseFloat(
            value / (num[1].toString().length == 1 ? 10 : 100)
          ).toFixed(2) ?? ""
        );
      } else setAmount(parseFloat(value).toFixed(2) ?? "");
    }
  };
  const totalRefund =
    items?.products.length > 0
      ? items?.products
          ?.map((item) => {
            return item.isReturn === true ? parseFloat(item.productAmount) : 0;
          })
          .reduce((prev, next) => prev + next)
          .toFixed(2)
      : parseFloat(0).toFixed(2);

  const subTotal =
    items?.products.length > 0
      ? items?.products
          ?.map((item) => {
            return item.isEBT === false
              ? item.isReturn === false
                ? parseFloat(item.productAmount)
                : item.isReturn === true
                ? -parseFloat(item.productAmount)
                : 0
              : 0;
          })
          .reduce((prev, next) => prev + next)
      : parseFloat(0);
  console.log("items.totalTaxAmount ", items.totalTaxAmount);
  return (
    <>
      <Modal
        title="Tax"
        visible={isModalOpen}
        refs={ref}
        onOk={() => handleAddToCart(true, false, taxSelect)}
        onCancel={handleCancel}
      >
        {isModalOpen ? (
          <Select
            mode="multiple"
            style={{
              width: "100%"
            }}
            placeholder="Select Tax"
            value={taxSelect}
            //   defaultValue={taxSelect}
            onChange={taxHandleChange}
            optionLabelProp="label"
          >
            {tax.map((t, key) => (
              <Option key={key} value={t.taxId} label={t.name}>
                <div className="demo-option-label-item">
                  {t.name.charAt(0).toUpperCase() +
                    t.name.slice(1).toLowerCase()}
                </div>
              </Option>
            ))}
          </Select>
        ) : (
          ""
        )}
      </Modal>
      <Content
        className="content-body sell-wrp"
        style={{ minHeight: 200, padding: "30px" }}
      >
        {spinLoadings ? (
          <Spin
            indicator={antIcon}
            style={{ display: "flex", justifyContent: "center" }}
          />
        ) : (
          <Row>
            <Col md={12} xs={24}>
              <div className="sell-dtl-lft">
                <Menu mode="horizontal" defaultSelectedKeys={[activeTab]}>
                  <Menu.Item
                    key="newItem"
                    onClick={() =>{
                      setAmount('');
                      setActiveTab("newItem")
                    } }
                  >
                    <CodepenOutlined /> PRODUCTS
                  </Menu.Item>
                  {isShowReturn ? (
                    <Menu.Item
                      key="returnProduct"
                      onClick={() =>{
                        setAmount('');
                        setActiveTab("returnProduct")
                      } }
                    >
                      <TranslationOutlined /> RETURN
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
                <Card className="bg-white ">
                  {activeTab === "returnProduct" ? (
                    <div className="return-product">
                      <div className="btn-group">
                        <Radio.Group
                          onChange={(e) =>{
                            setAmount('');
                            setReturnProductTab(e.target.value);
                          } }
                          value={returnProductTab}
                        >
                          <Radio value={1}>Invoice Return</Radio>
                          <Radio value={2}>Amount Return</Radio>
                        </Radio.Group>
                      </div>
                      {returnProductTab === 2 ? (
                        <div className="amount-return">
                          <div className="btn-group">
                            <div style={{ margin: "15px 0", width: "100%" }}>
                              <Input
                                size="large"
                                type="number"
                                autoFocus="autofocus"
                                status={inputError ? "error" : ""}
                                placeholder="Enter the amount"
                                value={amount ?? ""}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  amountHandle(e.target.value);
                                }}
                              />
                              {/* <InputNumber
                                size="large"
                                //   type="number"
                                autoFocus="autofocus"
                                id="amountInput"
                                status={inputError ? "error" : ""}
                                placeholder="Enter the return amount"
                                value={amount ?? ""}
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                  amountHandle(parseFloat(value));
                                }}
                              /> */}
                              {inputError ? (
                                <span className="error">{inputError}</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="btn-group">
                            <Button
                              type="primary"
                              onClick={() =>
                                handleAddToCart(false, false, [], 2)
                              }
                            >
                              ADD TO CART
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="invoice-return">
                          <div className="btn-group">
                            <div style={{ margin: "15px 0", width: "100%" }}>
                              <InputNumber
                                size="large"
                                //   type="number"
                                autoFocus="autofocus"
                                id="invoiceNoReturn"
                                disabled={returnItems.length > 0 ? true : false}
                                status={inputError ? "error" : ""}
                                placeholder="Search"
                                value={invoiceNoReturn}
                                style={{
                                  width: returnItems.length > 0 ? "80%" : "100%"
                                }}
                                onChange={(value) =>
                                  setInvoiceNoReturn(parseFloat(value))
                                }
                                prefix={
                                  <SearchOutlined className="site-form-item-icon" />
                                }
                                suffix={
                                  <BarcodeOutlined className="site-form-item-icon" />
                                }
                              />
                              {returnItems.length > 0 ? (
                                <Button
                                  type="primary"
                                  className="ml-10"
                                  onClick={() => clearInvoiceItem()}
                                >
                                  <CloseOutlined />
                                </Button>
                              ) : (
                                ""
                              )}
                              {inputErrorInvoiceNo ? (
                                <span className="error">
                                  Please Enter Invoice Number
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {returnItems.length === 0 ? (
                            <div className="btn-group">
                              <Button
                                type="primary"
                                onClick={() => returnInvoiceHandle()}
                              >
                                NEXT
                              </Button>
                            </div>
                          ) : (
                            <div className="cart-section">
                              <table>
                                <thead>
                                  <tr>
                                    <td colSpan={2}>
                                      <Checkbox
                                        checked={
                                          returnItemSelected.length ===
                                          returnItems.length
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          returnItemSelectHandler(
                                            "",
                                            e.target.checked
                                          );
                                        }}
                                      >
                                        Select All
                                      </Checkbox>
                                    </td>
                                    <td colSpan={2}>
                                      {returnItemSelected.length > 0 ? (
                                        <Button
                                          type="primary"
                                          className="add-to-cart-return"
                                          onClick={() => invoiceItemAddToCart()}
                                        >
                                          ADD TO CART
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {returnItems?.map((item, i) => {
                                    return (
                                      <tr key={i}>
                                        <td style={{ width: "25px" }}>
                                          <Checkbox
                                            value={item.orderProductId}
                                            onChange={(e) => {
                                              returnItemSelectHandler(
                                                item.orderProductId,
                                                e.target.checked
                                              );
                                            }}
                                            checked={returnItemSelected.includes(
                                              item.orderProductId
                                            )}
                                          ></Checkbox>
                                        </td>
                                        <td>{item.productName}</td>

                                        <td style={{ width: "100px" }}>
                                          {item.currentQty}
                                        </td>
                                        <td>
                                          {currencySign}{" "}
                                          {parseFloat(item.salePrice).toFixed(
                                            2
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="new-item">
                      <div className="btn-group">
                        {defaultAmount.map((value, key) => (
                          <Button
                            type="primary btn-yellow"
                            key={key}
                            onClick={() => {
                              setAmount(value);
                              setInputError("");
                            }}
                          >
                            {currencySign} {value.toFixed(2)}
                          </Button>
                        ))}
                      </div>
                      <div style={{ margin: "15px 0" }}>
                        <Input
                          size="large"
                          type="number"
                          autoFocus="autofocus"
                          status={inputError ? "error" : ""}
                          placeholder="Enter the amount"
                          value={amount ?? ""}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            amountHandle(e.target.value);
                          }}
                        />
                        {inputError ? (
                          <span className="error">{inputError}</span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="btn-group">
                        <Button
                          type="primary"
                          disabled={tax?.length > 0 ? false : true}
                          onClick={() => handleShowTaxModel()}
                        >
                          ADD WITH TAX
                        </Button>

                        <Button
                          type="primary"
                          onClick={() => handleAddToCart()}
                        >
                          ADD WITHOUT TAX
                        </Button>
                        <Button
                          type="primary"
                          disabled={!EBT}
                          onClick={() => handleAddToCart(false, true)}
                        >
                          ADD EBT ITEM
                        </Button>
                      </div>
                    </div>
                  )}

                  <table
                    style={{
                      width: "100%",
                      marginTop: "50px",
                      fontWeight: "600"
                    }}
                  >
                    <tbody>
                      {totalRefund > 0 ? (
                        <tr>
                          <td>Total Refund Amount</td>
                          <td style={{ textAlign: "right" }}>
                            - {currencySign}{" "}
                            {items?.products.length > 0
                              ? items?.products
                                  ?.map((item) => {
                                    return item.isReturn === true
                                      ? parseFloat(item.productAmount)
                                      : 0;
                                  })
                                  .reduce((prev, next) => prev + next)
                                  .toFixed(2)
                              : parseFloat(0).toFixed(2)}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td>Subtotal</td>
                        <td style={{ textAlign: "right" }}>
                          {subTotal > 0
                            ? `${currencySign} ${subTotal.toFixed(2)}`
                            : subTotal < 0
                            ? `- ${currencySign} ${Math.abs(subTotal).toFixed(
                                2
                              )}`
                            : `${currencySign} ${Math.abs(subTotal).toFixed(
                                2
                              )}`}
                        </td>
                      </tr>
                      {tax?.length > 0 ||
                      items.totalTaxAmount > 0 ||
                      items.totalTaxAmount < 0 ? (
                        <tr>
                          <td>Tax</td>
                          <td style={{ textAlign: "right" }}>
                            {items.totalTaxAmount > 0
                              ? `${currencySign} ${items.totalTaxAmount.toFixed(
                                  2
                                )}`
                              : items.totalTaxAmount < 0
                              ? `- ${currencySign} ${Math.abs(
                                  items.totalTaxAmount
                                ).toFixed(2)}`
                              : `${currencySign} ${Math.abs(
                                  items.totalTaxAmount
                                ).toFixed(2)}`}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td>EBT</td>
                        <td style={{ textAlign: "right" }}>
                          {currencySign}{" "}
                          {items?.products.length > 0
                            ? items?.products
                                ?.map((item) => {
                                  return item.isEBT === true
                                    ? parseFloat(item.productAmount)
                                    : 0;
                                })
                                .reduce((prev, next) => prev + next)
                                .toFixed(2)
                            : parseFloat(0).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total Amount</td>
                        <td style={{ textAlign: "right" }}>
                          {items.totalAmount > 0
                            ? `${currencySign} ${items.totalAmount.toFixed(2)}`
                            : items.totalAmount < 0
                            ? `- ${currencySign} ${Math.abs(
                                items.totalAmount
                              ).toFixed(2)}`
                            : `${currencySign} ${Math.abs(
                                items.totalAmount
                              ).toFixed(2)}`}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Card>
              </div>
            </Col>

            <Col md={12} xs={24}>
              {items?.products?.length > 0 ? (
                <div className="sell-dtl-right">
                  <Card className="bg-white ">
                    <div className="cart-section">
                      <table>
                        <tbody>
                          {items?.products?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.productName}</td>
                                <td
                                  style={item.isReturn ? { color: "red" } : {}}
                                >
                                  {currencySign}{" "}
                                  {item?.productAmount
                                    ? parseFloat(item?.productAmount).toFixed(2)
                                    : ""}
                                </td>
                                <td style={{ width: "100px" }}>
                                  {item.isReturn &&
                                  item.productName === "MISC Return" ? (
                                    <div style={{ color: "red" }}>
                                      {" "}
                                      {item.quantity}
                                    </div>
                                  ) : (
                                    <>
                                      <button
                                        className="btn quantity-btn"
                                        onClick={() =>
                                          handleQuantity(i, "remove")
                                        }
                                      >
                                        <MinusOutlined />
                                      </button>
                                      <span className="quantity-input">
                                        {item.quantity}
                                      </span>
                                      <button
                                        type="button"
                                        disabled={
                                          item.returnProductPurchaseQty ===
                                          item.quantity
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          handleQuantity(
                                            i,
                                            "add",
                                            item.returnProductPurchaseQty
                                          )
                                        }
                                      >
                                        <PlusOutlined />
                                      </button>
                                    </>
                                  )}
                                </td>
                                <td
                                  style={{ width: "20px", textAlign: "right" }}
                                >
                                  <button
                                    className="btn delete-btn"
                                    onClick={() => handleQuantity(i, "delete")}
                                  >
                                    <DeleteOutlined color="red" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="btn-group" style={{ marginTop: "30px" }}>
                      <Button type="primary" onClick={() => createOrder(1)}>
                        CHECKOUT
                      </Button>
                    </div>
                  </Card>
                </div>
              ) : (
                <div
                  className="bg-white sell-dtl-right"
                  style={{
                    margin: "0px 14px 0px 14px",
                    padding: 14,
                    minHeight: 200
                  }}
                >
                  <Skeleton paragraph={{ rows: 8 }} loading={loadings} active>
                    <Emptymsg
                      emptytitle={"Your cart is empty"}
                      emptydescription=""
                    />
                  </Skeleton>
                </div>
              )}
            </Col>
          </Row>
        )}
      </Content>
    </>
  );
};
export default SellPage;
