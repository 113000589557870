import React, { useEffect } from "react";
import { Layout, Row, Col, Skeleton, Table } from "antd";
import { ApiHelper, getUserData } from "../../../../utils/Helpers";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import Emptymsg from "../../../layout/message/Emptymsg";
import { messages } from "../../../../utils/Common/Messages";
import Credit from "../../../../assets/images/Wallet/wallet-loaded-01.png";
import Debit from "../../../../assets/images/Wallet/paid-for-order-01.png";
import Cashback from "../../../../assets/images/Wallet/cashback-01.png";
// import moment from "moment";
import "./wallet.less";
const { Content } = Layout;

const numberFormat = (walletAmount) => {
  return Number(parseFloat(walletAmount).toFixed(2)).toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    { minimumFractionDigits: 2 }
  );
};

const Transactionsummary = () => {
  const {
    currencySign,
    parentOrganizationId,
    organizationId,
    language,
    organizationTypeId,
    time_gmt
  } = getUserData();
  const lan = language ?? "EN";
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [totalReacord, setTotalReacord] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getWalletHistroy(perPage, page);
      // eslint-disable-next-line
  }, [perPage, page]);
  const nextData = (pagination) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);
    //searchData(pagination.pageSize, pagination.current);
  };
  const getWalletHistroy = async (perPage, page) => {
    setLoading(true);
    setData([]);
    setTotalReacord(0);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.WALLET_HISTORY.service,
      ApiRoutes.WALLET_HISTORY.url,
      ApiRoutes.WALLET_HISTORY.method,
      ApiRoutes.WALLET_HISTORY.authenticate,
      undefined,
      {
        parentOrganizationId: parentOrganizationId,
        organizationId: organizationId,
        limit: perPage,
        offset: page,
        organizationTypeId,
        gmtTime:time_gmt
      }
    );
    if (response.code === 200) {
      if (response.data.total) {
        setData(response.data.data ?? []);
        setTotalReacord(response.data.total);
        setLoading(false);
      } else {
        setData([]);
        setTotalReacord(0);
        setLoading(false);
        //  setNoDatAvailable(true);
      }
    } else {
      setData([]);
      setTotalReacord(0);
      setLoading(false);
    }
  };
  const columns =
    organizationTypeId === 1 || organizationTypeId === "1"
      ? [
          {
            title: "",
            dataIndex: "transactionTypeId",
            key: "transactionTypeId",
            width: "0px",
            className: "pr-0",
            render: (text) => {
              return text === 6 || text === "6" ? (
                <img src={Credit} alt="Icon" width="46" />
              ) : text === 7 || text === "7" ? (
                <img src={Debit} alt="Icon" width="46" />
              ) : text === 8 || text === "8" ? (
                <img src={Cashback} alt="Icon" width="46" />
              ) : (
                "-"
              );
            },
          },
          {
            title: messages.transactionType[lan],
            dataIndex: "pointepayOrderId",
            key: "pointepayOrderId",
            render: (text, record) => (
              <>
                <h3 className="mb-0">
                  {record.transactionTypeId === 6 ||
                  record.transactionTypeId === "6"
                    ? messages.walletloaded[lan]
                    : record.transactionTypeId === 7 ||
                      record.transactionTypeId === "7"
                    ? messages.paidForOrder[lan]
                    : record.transactionTypeId === 8 ||
                      record.transactionTypeId === "8"
                    ? messages.cashback[lan]
                    : "-"}
                </h3>
                <p className="mb-0 fs-08">
                  <span className="orderLabel">{messages.orderId[lan]}:</span>{" "}
                  {text}
                </p>
              </>
            ),
          },
          {
            title: messages.dateTime[lan],
            dataIndex: "createDt",
            key: "createDt",
            // render: (createDt) => <>{moment(createDt, "DD MM YYYY hh:mm:ss")}</>,
          },
          {
            title: messages.amount[lan],
            key: "amount",
            dataIndex: "amount",
            render: (amount, record) => (
              <>
                {record.transactionTypeId === 6 ||
                record.transactionTypeId === "6" ? (
                  <span className="fw-600 plus ">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : record.transactionTypeId === 7 ||
                  record.transactionTypeId === "7" ? (
                  <span className="fw-600 minus">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : record.transactionTypeId === 8 ||
                  record.transactionTypeId === "8" ? (
                  <span className="fw-600 plus ">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : (
                  "-"
                )}
              </>
            ),
          },
        ]
      : [
          {
            title: "",
            dataIndex: "transactionTypeId",
            key: "transactionTypeId",
            width: "0px",
            className: "pr-0",
            render: (text) => {
              return text === 6 || text === "6" ? (
                <img src={Credit} alt="Icon" width="46" />
              ) : text === 7 || text === "7" ? (
                <img src={Debit} alt="Icon" width="46" />
              ) : text === 8 || text === "8" ? (
                <img src={Cashback} alt="Icon" width="46" />
              ) : (
                "-"
              );
            },
          },
          {
            title: messages.organizationName[lan],
            dataIndex: "organizationName",
            key: "organizationName",
          },
          {
            title: messages.transactionType[lan],
            dataIndex: "pointepayOrderId",
            key: "pointepayOrderId",
            render: (text, record) => (
              <>
                <h3 className="mb-0">
                  {record.transactionTypeId === 6 ||
                  record.transactionTypeId === "6"
                    ? messages.walletloaded[lan]
                    : record.transactionTypeId === 7 ||
                      record.transactionTypeId === "7"
                    ? messages.paidForOrder[lan]
                    : record.transactionTypeId === 8 ||
                      record.transactionTypeId === "8"
                    ? messages.cashback[lan]
                    : "-"}
                </h3>
                <p className="mb-0 fs-08">
                  <span className="orderLabel">{messages.orderId[lan]}:</span>{" "}
                  {text}
                </p>
              </>
            ),
          },
          {
            title: messages.dateTime[lan],
            dataIndex: "createDt",
            key: "createDt",
            // render: (createDt) => <>{moment(createDt, "DD MM YYYY hh:mm:ss")}</>,
          },
          {
            title: messages.amount[lan],
            key: "amount",
            dataIndex: "amount",
            render: (amount, record) => (
              <>
                {record.transactionTypeId === 6 ||
                record.transactionTypeId === "6" ? (
                  <span className="fw-600 plus ">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : record.transactionTypeId === 7 ||
                  record.transactionTypeId === "7" ? (
                  <span className="fw-600 minus">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : record.transactionTypeId === 8 ||
                  record.transactionTypeId === "8" ? (
                  <span className="fw-600 plus ">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : (
                  "-"
                )}
              </>
            ),
          },
          {
            title: messages.balance[lan],
            dataIndex: "walletBalance",
            key: "walletBalance",
            align: "right",
            className: "fw-700",
            render: (amount, record) => (
              <>
                {record.transactionTypeId === 6 ||
                record.transactionTypeId === "6" ? (
                  <span className="fw-600 plus ">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : record.transactionTypeId === 7 ||
                  record.transactionTypeId === "7" ? (
                  <span className="fw-600 minus">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : record.transactionTypeId === 8 ||
                  record.transactionTypeId === "8" ? (
                  <span className="fw-600 plus ">{`${currencySign}${numberFormat(
                    amount
                  )}`}</span>
                ) : (
                  "-"
                )}
              </>
            ),
          },
        ];
  return (
    <>
      <Content className="content-body" style={{ minHeight: 200 }}>
        <Row>
          <Col lg={24} xs={24}>
            <div
              className="bg-white"
              style={{
                margin: "14px 14px 0px 14px",
                padding: 14,
                minHeight: 200,
              }}
            >
              <h4 className="text-uppercase">
                {messages.transactionSummary[lan]}
              </h4>
              <Table
                columns={columns}
                rowKey={(record) => record.walletTransactionHistoryId}
                dataSource={data}
                className="walletTable"
                pageIndex={page}
                //scroll={{ x: 1300 }}
                pagination={
                  parseInt(totalReacord) > perPage
                    ? {
                        pageSize: perPage,
                        total: totalReacord,
                        defaultCurrent: page,
                      }
                    : false
                }
                locale={{
                  emptyText: loading ? (
                    <Skeleton
                      paragraph={{ rows: 5 }}
                      loading={loading}
                      active
                    ></Skeleton>
                  ) : (
                    <Emptymsg emptytitle={messages.emptyTitleWallet[lan]} />
                  ),
                }}
                onChange={nextData}
              />
              {/* <Table
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                  className="walletTable"
                /> */}
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
};
export default Transactionsummary;
