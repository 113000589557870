import React from "react";
import { Select } from "antd";
import { messages } from "../../../utils/Common/Messages";
import { getUserData } from "../../../utils/Helpers";
const { Option } = Select;

const Itemssummary = (props) => {
  const lan = getUserData().language ?? "EN";
  const { updateType, selectvalue } = props;
  const selectOnChange = (value) => {
    updateType(value);
  };
  return (
    <Select
      defaultValue={selectvalue}
      style={{ width: 120 }}
      onChange={selectOnChange}
    >
      <Option value="amount">{messages.amount[lan]}</Option>
      <Option value="quantity">{messages.quantity[lan]}</Option>
    </Select>
  );
};
export default Itemssummary;
