import React, { useCallback, useEffect, useState } from "react";
import PaginatedTable from "../../common/PaginatedTable";
import { Col, Input, Row, Select } from 'antd';
import {
    SearchOutlined, EyeOutlined
} from "@ant-design/icons";
import './support.less';
import { Link, useLocation, useHistory } from "react-router-dom";
import {
    ApiHelper,
    message as cMessage,
    getUserData,
  } from "../../../utils/Helpers";
  import { messages } from "../../../utils/Common/Messages";
  import { ApiRoutes } from "../../../utils/Config/ApiRoutes";

import AxiosService from "../../../utils/Config/ApiService";
import SupportLoader from "../../../utils/Config/SupportLoader"
import Nodata from "../../../assets/images/support/nodata.png";

const Support = () => {

    const [pageloader, setpageloader] = useState(true)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [merchantSearchKey, setMerchantSearchKey] = useState('');
const [staffSearchKey, setStaffSearchKey] = useState('');

    let [addToggle, setAddToggle] = useState(false);
    let [viewToggle, setViewToggle] = useState(false);
    let [totalCount, setTotalCount] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(10)
    let [searchKey, setSearchKey] = useState("")
    let [users, setUsers] = useState([]);
    let [staffs, setstaffs] = useState([])
    let [merchants, setmerchants] = useState([])
    let [userid, setuserid] = useState("")
    let [statusFilter, setStatusFilter] = useState("All");

    let [isLoading, setIsLoading] = useState(true)
    let [isloader, setisloader] = useState(false)
    let [params, setparams] = useState("")
    let [isstaff, setisstaff] = useState(false)
    let [ismerchant, setismerchant] = useState(false)
    let [store, setstore] = useState([])
    let {search} = useLocation()
    let history = useHistory()
    //useState is required if we need column filter
    const [column, setColumn] = useState([])
    const location = useLocation()

    const merchantColumn = [
        {
            heading: () => '#',
            cell: (row, i) => {
                return i;
            },
            hidden: false,
            width: "20",
            id: 1
        },
        {
            heading: () => "Date",
            cell: row => {
                const date  = new Date(row['date']).toString().slice(4,15)
                const requiredDate = date.substring(0,6)+","+date.substring(7)
                return requiredDate;
            },
            hidden: false,
            width: "100",
            id: 2
        },
        {
            heading: () => "Onboarders",
            cell: row => row['onboarders'],
            hidden: false,
            width: "120",
            id: 3
        },
        {
            heading: () => "Merchant Organization",
            cell: row => row['organizationName'],
            hidden: false,
            width: "100",
            id: 6
        },
        {
            heading: () => "Country",
            cell: row => row['countryName'],
            hidden: false,
            width: "100",
            id: 7
        },
        {
            heading: () => "Type",
            cell: row => row['type'],
            hidden: false,
            width: "100",
            id: 8
        },
        {
            heading: () => "Status",
            cell: row => {
                
                if(row['active'] === 1){
                    return  <span style={{color : "#488f42" , backgroundColor: "#ebfce3", paddingLeft:"4px",paddingRight : "4px", fontWeight: "600"}}>Active</span>
                  }else if(row['active'] === 0){
                    return <span style={{color : "#eb5f50" , backgroundColor: "#ffeceb", paddingLeft:"4px", paddingRight : "4px",fontWeight: "600"}}>Inactive</span>
                  }
            },
            hidden: false,
            width: "100",
            id: 9
        },

        {
            heading: () => "Actions",
            cell: row => {
                sessionStorage.setItem("ismerchant", ismerchant)
                sessionStorage.setItem("searchKey", merchantSearchKey)
                return <>
                    {
                        row['type']==='Merchant'?<Link to = {ismerchant ? `/support/merchant/${row?.organizationId}` : "/support"} className="btn--small btn--view"><EyeOutlined /></Link>
                        :row['type']==='Location'?<Link to = {ismerchant ? `/support/store/${row?.organizationId}` : "/support"} className="btn--small btn--view"><EyeOutlined /></Link>:<></>
                    }
                </>
            },
            hidden: false,
            width: "20",
            id: 14
        }

    ]

    const staffsColumn = [
        {
            heading: () => '#',
            cell: (row, i) => {
                return i;
            },
            hidden: false,
            width: "20",
            id: 1
        },
        {
            heading: () => "User ID",
            cell: row => row['userid'],
            hidden: true,
            width: "120",
            id: 2
        },
        {
            heading: () => "Username",
            cell: row => row['userName'],
            hidden: false,
            width: "120",
            id: 3
        },
        {
            heading: () => "Email",
            cell: row => row['email'],
            hidden: false,
            width: "100",
            id: 4
        },
        {
            heading: () => "Phone Number",
            cell: row => row['businessPhone'],
            hidden: false,
            width: "100",
            id: 5
        },
        {
            heading: () => "Merchant/Location Name",
            cell: row => row['organizationName'],
            hidden: false,
            width: "100",
            id: 6
        },
        {
            heading: () => "Category",
            cell: row => row['type'],
            hidden: false,
            width: "100",
            id: 7
        },
        {
            heading: () => "Actions",
            cell: row => {
                sessionStorage.setItem("searchKey", staffSearchKey)
                sessionStorage.setItem("isstaff", isstaff)
                return <><Link to= {row?.type === 'Merchant' ? `/support/merchant/${row?.organizationId}` : 
                row?.type === 'Location' ? `/support/store/${row?.organizationId}`:
                row?.type === 'Location Staff' ? `/support/store/staff/${row?.employeeId}`:
                row?.type === 'Admin Staff' ? `/support/adminStaffProfile/${row?.employeeId}`:
                <></>}className="btn--small btn--view"><EyeOutlined /></Link></>
            },
            hidden: false,
            width: "20",
            id: 8
        }

    ]

    const verifyToken = async()=>{
        let query = new URLSearchParams(search)
        let token;
        if(query.get('token'))
        {
            token = query.get('token')
            sessionStorage.setItem('token',query.get('token'))
        }
        else
        {
            token = sessionStorage.getItem('token')
        }

        if(token)
        {
            try {
                let res = await AxiosService.post(`${ApiRoutes.SUPPORT_VERIFY_TOKEN.service}${ApiRoutes.SUPPORT_VERIFY_TOKEN.url}`,{token})
                if(res.status===200)
                {
                    sessionStorage.setItem('userData',JSON.stringify(res.data.userData))
                    sessionStorage.setItem('supportToken',(res.data.token))
                    history.push('/support')
                }
                else
                {
                    
                }
            } catch (error) {
                if(error.response.status===400)
                {
                    alert('Session Expired')
                    history.push('/')
                }
                else
                {
                    alert('Error Occoured. Login Again')
                    history.push('/')
                }
            }
        }
        else
        {
            alert('No token found')
            history.push('/')
        }
    }

    const getStaffsList = useCallback(  async() => {
       
        try{
            const response = await AxiosService.get(`${ApiRoutes.STAFFS.service}${ApiRoutes.STAFFS.url}`,{
                params : {
                    pgno : currentPage,
                    limit : itemsPerPage,
                    searchKey : searchKey
                }
            } 
          );
    
            if(response.status === 200){
                setisloader(false)
                setstaffs(response.data.data)
                setTotalCount(response.data.count)
                setIsLoading(true)
                setpageloader(false)
                // sessionStorage.removeItem("searchKey")
                sessionStorage.removeItem("ismerchant")
                
            }
        }catch(error){
            console.log(error)
        }
    },[currentPage, itemsPerPage, searchKey, isloader, staffSearchKey]

    )
    const getMerchantList = useCallback( async() => {
        try{
            const response = await AxiosService.get(`${ApiRoutes.MERCHANTS.service}${ApiRoutes.MERCHANTS.url}`,{
                params : {
                    pgno : currentPage,
                    limit : itemsPerPage,
                    searchKey : isstaff ? staffSearchKey : ismerchant ? merchantSearchKey : ""
                }
            } 
          );
    
            if(response.status === 200){
                setisloader(false)
                setmerchants(response.data.data)
                setTotalCount(response.data.count)
                setIsLoading(true)
                setpageloader(false)
                sessionStorage.removeItem("isstaff")

                
            }
        }catch(error){
            console.log(error)
        }
    },[currentPage, itemsPerPage, searchKey, isloader, merchantSearchKey])

    useEffect(()=>{
        verifyToken()
    },[])

    useEffect(() => {
        if(searchKey === ""){
            setismerchant(false)
            setisstaff(false)
        } 
        if(ismerchant && searchKey !== ""){
            // setisloader(true)
            getMerchantList()
            setColumn(merchantColumn)

        }else if(isstaff && searchKey !== ""){
            // setisloader(true)
            getStaffsList()
            setColumn(staffsColumn)
        }
        if (ismerchant && merchantSearchKey?.length === 0) {
          sessionStorage.setItem("searchKey", "");
          setismerchant(false)

        }
        if (isstaff && staffSearchKey?.length === 0) {
          sessionStorage.removeItem("searchKey", "");
          setisstaff(false)
        }

        
    },[currentPage, itemsPerPage, staffSearchKey, merchantSearchKey, ismerchant, isstaff, isloader])

    useEffect(() =>{
        if(sessionStorage.getItem("isstaff") && sessionStorage.getItem("searchKey") !== null){
            setStaffSearchKey(sessionStorage.getItem("searchKey"))
            setSearchKey(sessionStorage.getItem("searchKey"))
            setMerchantSearchKey("")

            setColumn(staffsColumn)
            setIsLoading(false)
            setismerchant(false)
            setisstaff(true)
            setpageloader(true)

            setisloader(false)

        }
        if(sessionStorage.getItem("ismerchant") && sessionStorage.getItem("searchKey") !== null){
            setMerchantSearchKey(sessionStorage.getItem("searchKey"))
            setSearchKey(sessionStorage.getItem("searchKey"))
            setStaffSearchKey('')

            setColumn(merchantColumn)
            setIsLoading(false)               
            setismerchant(true)
            setisstaff(false)
            setpageloader(true)

            setisloader(false)

        }
    },[])


    //stores
const pageNumberLimit = 3



// console.log("process.env.REACT_APP_LIVE_URL",process.env.REACT_APP_LIVE_URL)

    return (<div>
 
         <div className="adminContentSection">
        <Row gutter={8}>
            <Col span={10}            >
                {/* <span  style={{  opacity: (ismerchant && !isstaff)  ? "0.5" : ""}} > */}

                <Input size="large"
                disabled ={staffSearchKey?.length !==0}
                id="merchant"
                placeholder="Merchant search by Onboarders, Merchant Organization" 
                prefix={<SearchOutlined />} 
                className="supportSearchBox"
                style={{
                    opacity: !ismerchant && isstaff ? 0.5 : 1
                  }}
             
                value={merchantSearchKey}
                onClick={() =>{
                    // setisloader(true)
                    // setSearchKey("")
                      setTotalCount(0)
                    // getMerchantList()
                }}
                onChange={(e) => {
                    const regex = /^[ ]/g
                    if(regex.test(e.target.value)){
                        e.target.value?.replace(/^[ ]/g,"")
                    }else{
                        setStaffSearchKey('')

                        setColumn(merchantColumn)
                        setIsLoading(false)               
                        setismerchant(true)
                        setisstaff(false)
                        setpageloader(true)
                        setSearchKey(e.target.value)
                        setMerchantSearchKey(e.target.value)
                    }

                }}
                    

                 />
              
            </Col>
            <Col span={10}  >
                {/* <span style={{  opacity: (!ismerchant && isstaff)  ? "0.5" : ""}}> */}

                <Input size="large"
                disabled ={merchantSearchKey?.length !==0}
                 value = {staffSearchKey}
                id="staff"
                placeholder="Staff Search By Username, Email, Phone no" 
                prefix={<SearchOutlined />} 
                className="supportSearchBox" 
                style={{
                    opacity: ismerchant && !isstaff ? 0.5 : 1
                  }}
               
                onClick={(e) =>{
                    // setisloader(true)
                    // setSearchKey("")

                    setTotalCount(0)
                    // getStaffsList()
                }}

                onChange={(e) => {
                    const regex = /^[ ]/g
                    if(regex.test(e.target.value)){
                        e.target.value?.replace(/^[ ]/g,"")
                    }else{
                    setMerchantSearchKey("")

                    setColumn(staffsColumn)
                    setIsLoading(false)
                    setismerchant(false)
                    setisstaff(true)
                    setpageloader(true)
                    setSearchKey(e.target.value)
                    setStaffSearchKey(e.target.value)
                    }
                  
                    
                }}
                />
          
            </Col>
            {/* <Col span={4}>
                    <Select
                           defaultValue={itemsPerPage}
                        style={{ width: '100%' }}
                        className="selectPicker"
                        onChange={(e) => {
                            setItemsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                            setMaxPageNumberLimit(pageNumberLimit);
                            setMinPageNumberLimit(0);
                        }}
                        options={[
                            {
                                value: '10',
                                label: '10',
                            },
                            {
                                value: '20',
                                label: '20',
                            },
                            {
                                value: '50',
                                label: '50',
                            },
                            {
                                value: '100',
                                label: '100',
                            },
                        ]}
                    />
                </Col> */}
            <Col span={4}>
                    <div>

 
        <select
        style={{width : "85%"}}
        defaultValue={itemsPerPage}
        onChange={(e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1);
            setMaxPageNumberLimit(pageNumberLimit);
            setMinPageNumberLimit(0);
        }}
        >
  <option value={5}>5</option>
  <option value={10}>10</option>
  <option value={25}>25</option>
  <option value={50}>50</option>
  <option value={100}>100</option>
</select>
&nbsp; 
</div>
            </Col>

        </Row>
        

        
        {
           (!isstaff && staffSearchKey?.length === 0) && (!ismerchant && merchantSearchKey?.length === 0) ? <div className="text-center p-x8-y16 bg-white mt30"><img src={Nodata} alt='icon'/> </div> : <></> 
        }
        {
           isloader ? <SupportLoader/> :
            ((staffSearchKey?.length !== 0) || ( merchantSearchKey?.length !== 0))? <PaginatedTable
            row={isstaff ? staffs :
                 ismerchant ? merchants : []
            }
            column={column}
            totalCount={totalCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchKey={ staffSearchKey?.length > 0 ? staffSearchKey : merchantSearchKey?.length > 0 ? merchantSearchKey  : ""}
            setSearchKey={setSearchKey}
            isFilterHeader={false}
            isSearch = {false}
            maxPageNumberLimit={maxPageNumberLimit}
            setMaxPageNumberLimit={setMaxPageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            setMinPageNumberLimit={setMinPageNumberLimit}
            isloader = {isloader}
            setpageloader={setpageloader}
            pageloader={pageloader}
            isvisible = {false}
            
        /> : <div style={{ textAlign: "center" }}></div>
        }

    </div>
        
        
    </div>
       
    );
}

export default Support;