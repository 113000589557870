import React from "react";
import { Route, Switch } from "react-router-dom";
import { AppRouter } from "./utils/Config/AppRouter";
import { AppConfig } from "./utils/Config/AppConfig";
import moment from "moment";
import "./assets/css/app.less";


/* Browser history manage */
const AppRoute = ({
  component: Component,
  layout: Layout,
  filterData,
  setFilterData,
  setIsDarkMode,
  isDarkMode,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout setIsDarkMode={setIsDarkMode} isDarkMode={isDarkMode}>
        <Component
          {...props}
          filterData={filterData}
          setFilterData={setFilterData}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
        />
      </Layout>
    )}
  />
);

const App = () => {
  const [filterData, setFilterData] = React.useState({
    startDate: moment(new Date()).format(AppConfig.DATEFOMAT),
    endDate: moment(new Date()).format(AppConfig.DATEFOMAT),
    interval: "summary",
    shiftId: "",
    sId: "",
    employeeId:""
  });
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  return (
    <Switch>
      {AppRouter.map((route, index) => {
        return (
          <AppRoute
            key={index}
            exact={route.exact}
            path={route.path}
            layout={route.layout}
            component={route.component}
            filterData={filterData}
            setFilterData={setFilterData}
            setIsDarkMode={setIsDarkMode}
            isDarkMode={isDarkMode}
          />
        );
      })}
    </Switch>
  );
};

export default App;
