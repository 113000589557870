/* eslint-disable no-lone-blocks */
import { Button, Form, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'

import PaginatedTable from '../../../common/PaginatedTable';
import { Link } from "react-router-dom";
import {
    EyeOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import DisableIcon from '../../../../assets/images/support/ic_outline-disabled-visible.png';
import popupclose from "../../../../assets/images/support/popupclose-icon.svg";
import TextArea from 'antd/lib/input/TextArea';
import AxiosService from '../../../../utils/Config/ApiService';
import SupportLoader from '../../../../utils/Config/SupportLoader';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'antd/lib/form/Form';
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';


export const Staff = ({storeid,parentLoader, settoggle, merchantStatus, organizationId,staffManagement=false}) => {

    const history = useHistory()
    const[isloader, setisloader] = useState(true)
    const [pageloader, setpageloader] = useState(true)
    const [revealloader, setrevealloader] = useState(false)
    const [staffid, setstaffid] = useState()
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [status, setstatus]= useState(undefined)
    let [totalCount, setTotalCount] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(10)
    let [searchKey, setSearchKey] = useState("")
    let [addToggle, setAddToggle] = useState(false);
    let [isLoading, setIsLoading] = useState(true)
    const [storeStaff, setstorestaff] = useState([])
    const [revealToggle, setrevealToggle] = useState(false)
    const [userName, setUserName] = useState();
    const [submitloader,setsubmitloader] = useState(false)
    // let [users, setUsers] = useState([
    //     { username: 'NZmerch136', staffname: 'Bala', email: 'pdangare@spacepointe.com', mobile: '+234-964-957-7123', state: 'Nigeria / ABIA / Aba' },
    //     { username: 'NZmerch136', staffname: 'Bala', email: 'pdangare@spacepointe.com', mobile: '+234-964-957-7123', state: 'Nigeria / ABIA / Aba' },
    // ]);

    
    //useState is required if we need column filter
    const [staff, setstaff] = useState()
    const [resetPasswordToggle, setresetPasswordToggle]=useState(false)
    const [formData, setFormData] = useState({})
    const [resetToggle, setresetToggle] = useState(false)
    const [passwordToggle, setPasswordToggle] = useState(false)
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false)
    const [verifyPassword, setVerifyPassword] = useState(false)
    const [employeeId, setEmployeeId] = useState()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState(false)
    const [conPassValue, setConPassValue]= useState("")
    const [form] = Form.useForm();
    const userData = JSON.parse(sessionStorage.getItem('userData'))
    const supportAdminId = userData.employeeId
  
    const [pin, setPin] = useState()

  const getStaffById = async (employeeId) => {
    try{
      let res = await AxiosService.get(`${ApiRoutes.STAFF.service}${ApiRoutes.STAFF.url}/${employeeId}`)
      if(res.data.statusCode === 200){
        setstaff(res.data.employeeDetails.employeePin)
        setrevealloader(false)
        setrevealToggle(true)


      }
      

    }catch(error){
      console.log(error)
    }
  }
  const handleDisableStaff = async (values) =>{
    try{
        setsubmitloader(true)
        const employeeId = JSON.parse(sessionStorage.getItem('userData')).employeeId
        let res = await AxiosService.put(`${ApiRoutes.STAFF_TOGGLE.service}${ApiRoutes.STAFF_TOGGLE.url}/${staffid}`,{
            supportAdminId :employeeId ,
            reason :values?.reason ,
            status : status

        })
        if(res.status === 200){
            setsubmitloader(false)
            setAddToggle(false)
            setpageloader(true)
            form.resetFields()
            settoggle(prev => !prev)
            getStoreStaffList()
        }
    }catch(error){
        setsubmitloader(false)
        console.log(error)
    }
}
  const getStoreStaffList = async()=>{
    try{
        let res = await AxiosService.get(`${ApiRoutes.STORE_STAFFS.service}${ApiRoutes.STORE_STAFFS.url}/${storeid}`,{
          params : {
            pgno : currentPage,
            limit : itemsPerPage,
            searchKey : searchKey
        }
        })
        if(res.status === 200){
      
            setstorestaff(res.data.data)
            setTotalCount(res.data.count)
            setIsLoading(true)
            setisloader(false)
            setpageloader(false)
        }
    }catch(error){
        console.log(error)
    }
  }

const handleResetPassword = async() =>{
    try{
        const formData = new FormData()
        formData.append('employeeId', Number(employeeId)) ;
        formData.append('organizationId', Number(storeid) ) ;
        formData.append('password', password ) ;
        //to generate store notes
        let data = { 
            "organizationId":Number(storeid),
            "title":"Password Reset",
            "notes":`Password Reset of ${userName}`,
            "createdBy":Number(supportAdminId)
        }
  
          let res  =  await axios.post(`${process.env.REACT_APP_ROOT_URL}/apiv11/auth/changePassword`,formData,
           {
            headers:{
                'Content-Type': 'multipart/form-data',
                authorization: `Basic ${process.env.REACT_APP_PHP_Auth_Token}`
            }
          }
          )

          if(res.data.success === 1){
            let notesRes = await AxiosService.post(`${ApiRoutes.STORE_NOTES.service}${ApiRoutes.STORE_NOTES.url}`,data )
            if(notesRes.status === 200){
                form.resetFields()
                settoggle(prev => !prev)
                setresetToggle(false)
                setresetPasswordToggle(false)
                setsubmitloader(false)
                alert(res?.data?.response?.message)
            }
           
          }else{
            alert("Password reset failed")
            setsubmitloader(false)
          }     
    }catch(error){
        // console.log(error)
        setsubmitloader(false)
    }
}
// console.log("token", process.env.REACT_APP_PHP_Auth_Token)
const handleRevealPin = async(employeeId) =>{
    try{
        setrevealloader(true)
      let res = await AxiosService.post(`${ApiRoutes.STAFF_REVEAL_PIN.service}${ApiRoutes.STAFF_REVEAL_PIN.url}`,{
        "employeeId" : employeeId,
        "supportAdminId" : supportAdminId
      })
      if(res.status === 200){
        setPin(res.data.employeePin)
        settoggle(prev => !prev)
       setrevealloader(false)
       setrevealToggle(true)
      }
    }catch(error){
      console.log(error)
      setrevealloader(false)
    }
  }
    const initialColumn = [
        {
            heading: () => "Username",
            cell: row => row['userName'],
            hidden: false,
            width: "100",
            id: 2
        },
        {
            heading: () => "Staff Name",
            cell: row => {
                return `${row['firstName']} ${row['lastName']}`},
            hidden: false,
            width: "100",
            id: 3
        },
        {
            heading: () => "Email",
            cell: row => row['email'],
            hidden: false,
            width: "100",
            id: 4
        },
        {
            heading: () => "Phone",
            cell: row =>{
                return `${row['businessPhoneCode']} ${row['businessPhoneCode']!== ""  ? "-" : ""} ${row['businessPhone']}`
            } ,
            hidden: false,
            width: "100",
            id: 7
        },
        {
            heading: () => "Pin",
            cell: row => {
               
               const button = <Button
                type="warning" 
                className='btn--large' 
                 onClick={() =>{
                    // setrevealloader(true)
                    handleRevealPin(Number(row['employeeId']))
                   
                }}>
                    {revealloader ? <SupportLoader/> : "Reveal"}
                 
                    </Button>
                return button;
            },
            hidden: false,
            width: "100",
            id: 10
        },
        {
            heading: () => "Password",
            cell: row => {
                return <><Button type="primary" className='mr-3'
                onClick={() =>{
                    setUserName(row['userName'])
                    setresetPasswordToggle(true)
                    setEmployeeId(row.employeeId)
                 
                }}
                >Reset</Button> </>
            },
            hidden: false,
            width: "100",
            id: 11
        },
        {
            heading: () => "Status",
            cell: row => {
                if(row['active'] ===1){
                    
                    return <><Button type="primary" className='mr-3' onClick={() => {
                        setstaffid(row['employeeId'])
                        setAddToggle(true)
                        setstatus(false)
                    }
                    }><img src={DisableIcon} width="17px" height="17px" className='mr-1' /> Disable</Button> </>
                }else if(row['active'] ===0){
                   
                        return <><Button type="primary" className='mr-3' onClick={() => {

                            if(merchantStatus === true){
                            setstaffid(row['employeeId'])
                            setstatus(true)
                            setAddToggle(true)
                        }else{
                            alert("Enable Store Admin to enable staff")
                        } 
                    }}
                            
                            >
                 <img src={DisableIcon} width="17px" height="17px" className='mr-1' /> Enable</Button> </>
                   

                }
                
            },
            hidden: false,
            width: "100",
            id: 9
        },
        {
            heading: () => "Actions",
            cell: row => {
                return <><Link to={`/support/store/staffEdit/${row['employeeId']}`} className="btn--small btn--view bg-primary"><EditOutlined /></Link> <Link to= {`/support/store/staff/${row['employeeId']}`} className="btn--small btn--view"><EyeOutlined /></Link> </>
            },
            hidden: false,
            width: "20",
            id: 14
        }

    ]

    const [column, setColumn] = useState(initialColumn)
    //required to get the filtered column
    let filterOptions = []
    //default checked values of select options
    let filterDefaultValue = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 13 }, { value: 14 }]

    //generate the options of multi select
    column.forEach((e) => {
        let option = {
            label: e.id === 13 ? 'Status' : e.heading(),
            value: e.id
        }
        filterOptions.push(option)
    })

    //to handle the change of the react-multi select
    const handleFilterSelection = (options) => {
        let newColumn = [...column]
        for (let i = 0; i < newColumn.length; i++) {
            let data = options.filter((e) => newColumn[i].id === e.value)
            data.length === 1 ? newColumn[i].hidden = false : newColumn[i].hidden = true
        }
        setColumn(newColumn)
    }
    const handleAddStaff = () =>{
        history.push(`/support/addStaff/${storeid}`)
    }
    useEffect(() =>{ 
        setColumn(initialColumn)
        getStoreStaffList()
      }, [currentPage, itemsPerPage, searchKey, storeid,parentLoader, merchantStatus])
    return (
        <>{
            !isloader?    <PaginatedTable
            row={storeStaff}
            column={column}
            addStaff = {staffManagement}
            handleAddStaff = {handleAddStaff}
            totalCount={totalCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            isFilterHeader={true}
            filterOptions={filterOptions}
            handleFilterSelection={handleFilterSelection}
            filterDefaultValue={filterDefaultValue}
            isMultiSelect= {false}
            isSearch={true}
            maxPageNumberLimit={maxPageNumberLimit}
            setMaxPageNumberLimit={setMaxPageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            setMinPageNumberLimit={setMinPageNumberLimit}
            setpageloader={setpageloader}
            pageloader={pageloader}
        /> : <SupportLoader/>
 }

{addToggle ? (
                <div>
                    <div className="divoverlay">
                        <div className="divoverlayBlock">
                            <div className="popupheaderblock">
                                <h5> Reason </h5>
                                <img
                                    src={popupclose}
                                    alt="Close"
                                    className="cursorPointer"
                                    style={{cursor : "pointer"}}
                                    onClick={() => {
                                        setAddToggle(false)
                                        form.resetFields()
                                    }}
                                />
                            </div>
                            <div className="container-fluid">
                                <div className="overdivScroll">
                                    <div className="row mb40">
                                        <Form
                                        form={form}
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            wrapperCol={{
                                                span: 16,
                                            }}
                                            style={{
                                                maxWidth: 600,
                                                margin: '10px 20px'
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            autoComplete="off"
                                            className='supportForm'
                                            onFinish={handleDisableStaff}
                                        >
                                            <Form.Item
                                                label="Reason"
                                                name="reason"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the reason',
                                                    },
                                                  
                                                ]}
                                            >
                                                <Input
                                                     onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                      
                                                        if (inputValue.startsWith(' ')) {
                                                          form.setFieldsValue({ reason: inputValue.trim() });
                                                        } else {
                                                          form.setFieldsValue({ reason: inputValue });
                                                        }
                                                      }}
                                                placeholder='Enter Reason'/>
                                            </Form.Item>

                                            <Form.Item
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 16,
                                                }}
                                                className='text-right'
                                            >
                                                 <div className='displayFlex justifyflexend alignCenter'>
                                                <Button type="default" className='mr-3 btn--cancel btn--large'
                                                onClick={() => {
                                                    setAddToggle(false)
                                                    form.resetFields()
                                                }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit" 
                                                // onClick={()=>{
                                                //     setsubmitloader(true)
                                                // }}
                                                className=' btn--large'>
                                                   {submitloader ? <SupportLoader/> : "Submit"} 
                                                </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )
        }
{resetPasswordToggle ? (
                <div>
                    <div className="divoverlay">
                        <div className="divoverlayBlock">
                            <div className="popupheaderblock">
                                <h5> Reset Password </h5>
                                <img
                                    src={popupclose}
                                    alt="Close"
                                    className="cursorPointer"
                                    style={{cursor : "pointer"}}
                                    onClick={() => {setresetPasswordToggle(false)
                                    form.resetFields()
                                    }}
                                />
                            </div>
                            <div className="container-fluid">
                                <div className="overdivScroll">
                                    <div className="row mb40">
                                        <Form
                                        form={form}
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            wrapperCol={{
                                                span: 16,
                                            }}
                                            style={{
                                                maxWidth: 600,
                                                margin: '10px 20px'
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            autoComplete="off"
                                            className='supportForm'
                                            onFinish={(values) =>{
                                                setFormData(values)
                                            }}
                                        >
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                type="password"
                                               
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the new password',
                                                    },
                                                ]}
                                            >
                                                <span  style={{display : "flex"}}>
                                                <Input
                                                 type={passwordToggle ?"text": "password"}
                                                 onChange={(e) =>{
                                                    setPassword(e.target.value)
                                                    setVerifyPassword(e.target.value === conPassValue);
                                                 }}
                                                placeholder='Enter New Password'/>
                                                <EyeOutlined
                                                  onClick={() =>{
                                                    setPasswordToggle(prev => !prev)
                                                 }}
                                                 className='passwordIcon'
                                                />

                                                </span>
                                               
                                            </Form.Item>

                                            <Form.Item
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                style={{display : "flex"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the confirm password',
                                                    },
                                                ]}
                                            >
                                                <span style={{display : "flex"}}>
                                                <Input
                                                  type={confirmPasswordToggle ? "text" :"password"}
                                                  name="confirmPassword"
                                                  onChange={(e) => {
                                                    setConfirmPassword(true);
                                                    setConPassValue(e.target.value)
                                                    setVerifyPassword(e.target.value === password);
                                                }}
                                                 placeholder='Enter Confirm Password'/>
                                                 <EyeOutlined 
                                                 onClick={() =>{
                                                    setConfirmPasswordToggle(prev => !prev)
                                                 }} className='passwordIcon'
                                                 />

                                                </span>

                                               {
                                                !verifyPassword && confirmPassword && (
                                                    <span style={{color : "red"}}>Passwords doesnot match</span>
                                                )
                                               }
                                               
                                            </Form.Item>
                                     

                                            <Form.Item
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 16,
                                                }}
                                                className='text-right'
                                            >
                                                 <div className='displayFlex justifyflexend alignCenter'>
                                                <Button type="default" className='mr-3 btn--cancel btn--large'
                                                onClick={() => {
                                                    form.resetFields()
                                                    setresetPasswordToggle(false)}}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit" 
                                                onClick={(e)=>{
                                                    if(verifyPassword){
                                                        setresetToggle(true)
                                                    }else if (!verifyPassword){
                                                        e.preventDefault()
                                                    }
                                                  
                                                }}
                                                className=' btn--large'>
                                                   {/* {submitloader ? <SupportLoader/> : "Submit"}  */}
                                                   Submit
                                                </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )
        }
        {resetToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock divoverlaySmallBlock">
                <div className="container-fluid">
                  <div className="text-center p-x8-y16">
                    <ExclamationCircleOutlined color='#30649B' className='text--primary fs--5'/>
                    <h1 className='m-10 fs-15'>Are you sure?</h1>
                    <p className='m-10 fs--1'>You want to reset password</p>
                    <div className='d-flex alignCenter justify-content-center'>
                    <Button type="primary" className='btn--large btn--cancel m-10' onClick={() => {{
                        form.setFieldsValue({
                            "confirmPassword" : conPassValue
                        })
                        setresetToggle(false)}}}>Cancel</Button>
                    <Button type="primary" className='btn--large m-10' onClick={() => {
                        // eslint-disable-next-line no-lone-blocks
                            {
                                setsubmitloader(true)
                                handleResetPassword()
                            }}
                        }>
                                        {submitloader ? <SupportLoader/> : "OK"} 
                            </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
{revealToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock divoverlaySmallBlock">
                <div className="container-fluid">
                  <div className="text-center p-x8-y16">
                    <h2 className='m-10 fs-15'>Employee Pin</h2>
                    <h3 className='m-10 text--grey'>{pin?.length ? pin : "No data available"}</h3>
                    <Button type="primary" className='btn--large m-10' onClick={() => setrevealToggle(false)}>Ok</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}



        </>
    )
}
