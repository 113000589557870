import React, { useEffect, useState } from 'react'
import { useParams,useHistory,useLocation } from 'react-router-dom'
import { Button, Col, Row } from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import AxiosService from "../../../../utils/Config/ApiService";
import SupportLoader from "../../../../utils/Config/SupportLoader";
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import { getUserData } from '../../../../utils/Helpers';
import axios from 'axios';
function Parameters() {
  const { search } = useLocation();
  const countryId = new URLSearchParams(search).get('countryId')
  const [ currency, setCurrency ] = useState(countryId == 18 ? '₦' : "$")
  const [ productType, setProductType] = useState([])
  const [ merchant, setMerchant ] = useState([])
  const [ shopifyCount, setShopifyCount ] = useState(0)
  const [ loader, setLoader] = useState(false)
  const userData = getUserData();
  const history = useHistory();
  const { id } = useParams();

  const getMerchantParameters = async (value) => {
    setMerchant([]);
    try {
      // let res = await AxiosService.post(`/support/merchant/get-parameter`,{
      //   organizationId: id,
      //   countryId: 1,
      //   productType: value
      // })
      let res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/apiv11/auth/getmerchantparams`,
          {
            organizationId: id,
            countryId: countryId,
            productType: value
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Basic ${process.env.REACT_APP_PHP_Auth_Token}`,
            },
          }
      );

      if(res.status == 200){
        let data = res.data.response;
        let defaultCount = data?.data?.filter(e => e?.selectedValue == true && e?.amount != "0.00")?.map(e => e.metaKey)
        setMerchant(data.data)
        setProductType(data.productTypeList)
        setShopifyCount(defaultCount)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getNeededParams = ( value ) => {
    const data = merchant?.find(e => e?.metaKey == value)
    return data
  }

  const handleCheckBox = (name, check) => {
    console.log(name, check)
    let merchantIndex = merchant?.findIndex(e => e?.metaKey == name)
    let newArr = [...merchant];
    newArr[merchantIndex]["selectedValue"] = check;
    setMerchant(newArr)
  }

  const handleBarInput = (value) => {
    let merchantIndex = merchant?.findIndex(e => e?.metaKey == "BARS")
    let newArr = [...merchant];
    newArr[merchantIndex]["BARS"] = value;
    setMerchant(newArr)
  }

  const handleLogicalMerchantInput = (value) => {
    let merchantIndex = merchant?.findIndex(e => e?.metaKey == "ITU")
    let newArr = [...merchant];
    newArr[merchantIndex]["childList"][0]["ITU_LOGICAL"] = value;
    setMerchant(newArr)
  }

  const handleLevelOneRadio = (name, level) => {
    let merchantIndex = merchant?.findIndex(e => e?.metaKey == name)
    let newArr = [...merchant];
    if(level === 1){
      console.log("HERE 1", newArr[merchantIndex]["childList"][0]["selectedValue"])
      newArr[merchantIndex]["childList"][0]["selectedValue"] = true;
      newArr[merchantIndex]["childList"][1]["selectedValue"] = false;
    }else{
      console.log("HERE 2", newArr[merchantIndex]["childList"][1]["selectedValue"])
      newArr[merchantIndex]["childList"][0]["selectedValue"] = false;
      newArr[merchantIndex]["childList"][1]["selectedValue"] = true;
    }
    setMerchant(newArr)
  }

  const handleLevelTwoRadio = (value) => {
    let merchantIndex = merchant?.findIndex(e => e?.metaKey == "MERCHANT_PROCESSING")
    let newArr = [...merchant];
    if(value === 1){
      newArr[merchantIndex]["childList"][1]["chargeTypeList"][0]["selectedValue"] = true;
      newArr[merchantIndex]["childList"][1]["chargeTypeList"][1]["selectedValue"] = false;
    }else{
      newArr[merchantIndex]["childList"][1]["chargeTypeList"][0]["selectedValue"] = false;
      newArr[merchantIndex]["childList"][1]["chargeTypeList"][1]["selectedValue"] = true;
    }
    setMerchant(newArr)
  }

  const handleLevelThreeInput = (name, value, level) => {
    let merchantIndex = merchant?.findIndex(e => e?.metaKey == "MERCHANT_PROCESSING")
    let newArr = [...merchant];
    if(level === 1){
      newArr[merchantIndex]["childList"][1]["chargeTypeList"][0][name] = value;
    }else{
      newArr[merchantIndex]["childList"][1]["chargeTypeList"][1][name] = value;
    }
    setMerchant(newArr)
  }

  const handleSubmit = () => {
    let errorCount = 0;
    let extraForShopify;
    let totalPriceParameter;

    let parameter = merchant.map(e => ({ "metaKey": e.metaKey, "metaValue": e.selectedValue }))
    totalPriceParameter = merchant.filter(e => e.selectedValue == true && e.amount != "0.00")?.map(e => e.metaKey)
    extraForShopify = merchant.filter(e => e.amount != "0.00")?.map(e => e.metaKey)
    
    const indexFinder = (name,data) => {
      let index = data?.findIndex(e => e?.metaKey == name)
      return index == -1 ? false : index
    }

    if(indexFinder("BARS",parameter)){
      if(merchant[indexFinder("BARS",merchant)]?.selectedValue == true){
      let txtValue = merchant[indexFinder("BARS",merchant)][merchant[indexFinder("BARS",merchant)]["metaKey"]]
      let dummy = [...parameter]
      if(txtValue != "" && txtValue != undefined ){
        dummy[indexFinder("BARS", parameter)]["txtValue"] = txtValue
      }else{
        alert("Needed Pre Auth Amount")
        errorCount++
      }
      parameter = dummy
      }
    }
    
    if(indexFinder("TIP",parameter)){
      if(merchant[indexFinder("TIP",merchant)]?.selectedValue == true){
        let tip = merchant[indexFinder("TIP",merchant)]?.childList?.map((e)=>({ metaKey: e.metaKey, metaValue: e.selectedValue }))
        parameter.push(tip[0])
        parameter.push(tip[1])
      }
    }

    if(indexFinder("ITU",parameter)){
      if(merchant[indexFinder("ITU",merchant)]?.selectedValue == true){
        let itu = merchant[indexFinder("ITU",merchant)]?.childList?.map((e)=>({ metaKey: e.metaKey, metaValue: e.selectedValue }))
        parameter.push(itu[0])
        parameter.push(itu[1])
        let dummy = [...parameter]
        if(merchant[indexFinder("ITU",merchant)]["childList"][0]["selectedValue"] == true){
          let txtValue = merchant[indexFinder("ITU",merchant)]["childList"][0][merchant[indexFinder("ITU",merchant)]["childList"][0]["metaKey"]]
          console.log("TEXT VALUE", txtValue)
          if(txtValue != "" && txtValue != undefined ){
            dummy[indexFinder("ITU_LOGICAL", parameter)]["txtValue"] = txtValue
          }else{
            alert("Needed Logical Merchant ID")
            errorCount++
          }
          parameter = dummy
        }
      }
    }

    if(indexFinder("MERCHANT_PROCESSING",parameter) == 0){
      console.log("CALLED")
      if(merchant[indexFinder("MERCHANT_PROCESSING",merchant)]?.selectedValue == true){
        let mp = merchant[indexFinder("MERCHANT_PROCESSING",merchant)]?.childList?.map((e)=>({ metaKey: e.metaKey, metaValue: e.selectedValue }))
        parameter.push(mp[0])
        parameter.push(mp[1])
        if(merchant[indexFinder("MERCHANT_PROCESSING",merchant)]?.childList[1]?.selectedValue == true){
          let typeList = merchant[indexFinder("MERCHANT_PROCESSING",merchant)]?.childList[1]?.chargeTypeList
          let smp = typeList?.map((e)=>({ metaKey: e.metaKey, metaValue: e.selectedValue }))
          parameter.push(smp[0])
          parameter.push(smp[1])
          if(typeList[0]?.selectedValue == true){
            let txtValue = typeList?.[0][typeList?.[0]["metaKey"]]
            let dummy = [...parameter]
            if(txtValue != "" && txtValue != undefined){
              dummy[indexFinder("SURCHARGE", parameter)]["txtValue"] = txtValue
              delete dummy[indexFinder("CASH_DISCOUNT", parameter)]["txtValue"]
            }else{
              alert("Needed Surcharge")
              errorCount++
            }
            parameter = dummy
          }else if(typeList[1]?.selectedValue == true){
            let txtValue = typeList?.[1][typeList?.[1]["metaKey"]]
            let dummy = [...parameter]
            if(txtValue != "" && txtValue != undefined){
              dummy[indexFinder("CASH_DISCOUNT", parameter)]["txtValue"] = txtValue
              delete dummy[indexFinder("SURCHARGE", parameter)]["txtValue"]
            }else{
              alert("Needed Cash Discount")
              errorCount++
            }
            parameter = dummy
          }
        }
      }
    }

    // Comparing That Should We Get Shopify Id Based On Selected Items 
    let priceValidator = (data) => {
      let count = 0;
      data.forEach(e => {
        if(totalPriceParameter.includes(e)){
          count++
        }
      })
      return count
    }

    if(errorCount == 0){
      if(priceValidator(shopifyCount) < priceValidator(extraForShopify) && countryId==1){
        let shopifyId = prompt("ENTER SHOPIFY ID")
        if(shopifyId){
          console.log("FINAL SHOP",parameter, shopifyId)
          postData(parameter, shopifyId)
        }
      }else{
        console.log("FINAL",parameter)
        postData(parameter, "")
      }
    }
  }

  const postData = async ( parameter, value ) => {
    setLoader(true)
    const employeeId = JSON.parse(sessionStorage.getItem("userData"))?.employeeId
    // let body = {
    //   organizationId: id,
    //   employeeId: employeeId,
    //   parameters: parameter,
    // }
    // if(value !== ""){
    //   body["shopifyId"] = value
    // }
    const formData = new FormData();
    let changedParams = "[" +  parameter.map(e => JSON.stringify(e) + ",").join("") ; //send array as a whole
    let lastComma = changedParams.substring(0, changedParams.length - 1) + "]" // Manipulated

    formData.append("organizationId", id)
    formData.append("employeeId", employeeId)
    formData.append("parameters", lastComma)
    if(value !== ""){
        formData.append("shopifyId",value)
    }
    try {
      // let res = await AxiosService.post(`/support/merchant/post-parameter`,body
      // );

      let res = await axios.post(
        `${process.env.REACT_APP_ROOT_URL}/apiv11/auth/updatemerchantparamsbyid`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Basic ${process.env.REACT_APP_PHP_Auth_Token}`,
          },
        }
      );

      if(res.data.success == 1){
        setLoader(false);
        history.goBack();
      }else{
        setLoader(false);
        alert(res.data.message)
      }
    } catch (error) {
      setLoader(false);
      alert(JSON.stringify(error.response))
      console.log(error)
    }
  }

  useEffect(()=>{
    getMerchantParameters("");
  },[])

  return (
    <div className="adminContentSection">
      <Row gutter={8} className='mb-02'>
        <Col span={24}>
          <div className="displayFlex mt10 mb-05 spaceBetween">
            <Button
              type="warning"
              className="mr-3 btn--large d-flex align-items-center"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </div>
        </Col>
      </Row>
      <Row gutter={8} className='mb-02'>
        <Col span={24}>
          <div className="bg-white p-2 merchantParameterBox">
            <h4 className='fs--1 fw-600'>Merchant Parameters</h4>
            {/* Software Type Has Been Disabled On Click - onClick={()=>getMerchantParameters(e?.metaKey)} */}
            { productType?.length > 0 &&
            <>  
            <p className='mb-05'>Software Type</p> 
            <div className='mb-1'>
              {
                productType.map((e,i) => {
                  return <div key={i}>
                  <input type='radio' name='softwareType' value={e?.metaKey} id={e?.metaKey} defaultChecked={e?.metaValue == "true" ? true : false} disabled/>
                  &nbsp; <label htmlFor={e?.metaKey}>{e?.metaKey}</label><br />
                  </div>
                })
              }
            </div>
            </>
            }
            <p className='mb-05'>Merchant Parameters -- </p>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("MERCHANT_PROCESSING") &&
             <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"MERCHANT_PROCESSING"} value='Merchant Processing' id='merchantProcessing' defaultChecked={getNeededParams("MERCHANT_PROCESSING")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='merchantProcessing'>Merchant Processing ({currency}{getNeededParams("MERCHANT_PROCESSING")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("RESTAURANT") && 
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"RESTAURANT"} value='restaurant' id='restaurant' defaultChecked={getNeededParams("RESTAURANT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='restaurant'>Restaurant ({currency}{getNeededParams("RESTAURANT")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("MERCHANT_PROCESSING") && 
                getNeededParams("MERCHANT_PROCESSING")?.selectedValue == true && (<div>
                <Col span={24} className='mb-02 ml-18'>
                  <input type='radio' defaultChecked={getNeededParams("MERCHANT_PROCESSING")?.childList[0]?.selectedValue} name={"MERCHANT_PROCESSING"} id='noProcessing' onClick={(e)=>handleLevelOneRadio(e.target.name,1)}/> <label htmlFor='noProcessing'>No Processing</label><br />
                  <input type='radio' defaultChecked={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.selectedValue} name={"MERCHANT_PROCESSING"} id='Standard Processing' onClick={(e)=>handleLevelOneRadio(e.target.name,2)}/> <label htmlFor='Standard Processing'>Standard Processing</label>
                </Col>
                {getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.selectedValue == true && (<Col span={24} className='mb-02 ml-36'>
                  <span className='mr-5'><input type='radio' name={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.metaKey} id='Surcharge' defaultChecked={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[0]?.selectedValue} onChange={()=>handleLevelTwoRadio(1)} /> <label htmlFor='Surcharge'>Surcharge</label></span>
                  <span><input type='radio' name={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.metaKey} id='Dual Pricing' defaultChecked={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[1]?.selectedValue} onChange={()=>handleLevelTwoRadio(2)}/> <label htmlFor='Dual Pricing'>Dual Pricing</label></span>
                  {getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[0]?.selectedValue === true && (<div className='mb-1 merchantTextBox'>
                    <label className='text--grey fw-600' htmlFor='creditCardSurcharge'>Credit Card Surcharge</label><br />
                    <input type='text' name={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[0]?.metaKey} defaultValue={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[0][getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[0]?.metaKey]} className='form-control' id='creditCardSurcharge' onChange={(e)=>handleLevelThreeInput(e.target.name, e.target.value, 1)}/>
                  </div>)}
                  {getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[1]?.selectedValue === true && (<div>
                    <div className='d-flex'>
                      <div className='mb-1 mr-5 merchantTextBox'>
                        <label className='text--grey fw-600' htmlFor='Interchange Fee Percentage'>Interchange Fee Percentage</label><br />
                        <input type='text' name={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[1]?.metaKey} defaultValue={getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[1][getNeededParams("MERCHANT_PROCESSING")?.childList[1]?.chargeTypeList[1]?.metaKey]} className='form-control' id='Interchange Fee Percentage' onChange={(e)=>handleLevelThreeInput(e.target.name, e.target.value, 2)}/>
                      </div>
                    </div>
                  </div>)}
                </Col>)}
              </div>)}
            </Row>
            {/* Multi Store Has Been Disabled - onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}*/}
            <Row gutter={8} className='mb-02'>
              { getNeededParams("CHANGE_TO_MULTI_STORE_MANAGEMENT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"CHANGE_TO_MULTI_STORE_MANAGEMENT"} value='Multistore' id='Multistore' defaultChecked={getNeededParams("CHANGE_TO_MULTI_STORE_MANAGEMENT")?.selectedValue} disabled/> <label className='ml-05' htmlFor='Multistore'>Multistore ({currency}{getNeededParams("CHANGE_TO_MULTI_STORE_MANAGEMENT")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("ADD_STAFF_MANAGEMENT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"ADD_STAFF_MANAGEMENT"} value='staffManagement' id='staffManagement' defaultChecked={getNeededParams("ADD_STAFF_MANAGEMENT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='staffManagement'>Staff Management ({currency}{getNeededParams("ADD_STAFF_MANAGEMENT")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("ADD_KARDSTASH") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"ADD_KARDSTASH"} value='kardStash' id='kardStash' defaultChecked={getNeededParams("ADD_KARDSTASH")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='kardStash'>KardStash ({currency}{getNeededParams("ADD_KARDSTASH")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("ADD_CUSTOMER_MANAGEMENT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"ADD_CUSTOMER_MANAGEMENT"} value='customerManagement' id='customerManagement' defaultChecked={getNeededParams("ADD_CUSTOMER_MANAGEMENT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='customerManagement'>Customer Management ({currency}{getNeededParams("ADD_CUSTOMER_MANAGEMENT")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("ADD_DISCOUNT_MANAGEMENT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"ADD_DISCOUNT_MANAGEMENT"} value='discountManagement' id='discountManagement' defaultChecked={getNeededParams("ADD_DISCOUNT_MANAGEMENT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='discountManagement'>Discount Management ({currency}{getNeededParams("ADD_DISCOUNT_MANAGEMENT")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("MASTER_CATALOG") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"MASTER_CATALOG"} value='masterCatalog' id='masterCatalog' defaultChecked={getNeededParams("MASTER_CATALOG")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='masterCatalog' >Master Catalog ({currency}{getNeededParams("MASTER_CATALOG")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("ITU") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"ITU"} value='ITU' id='ITU' defaultChecked={getNeededParams("ITU")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='ITU'>ITU ({currency}{getNeededParams("ITU")?.amount})</label>
                </div>
              </Col>
              }
              {/* {FOOD STAMP PROCESSING IS MISSING FROM API ADDED EDT FOR THAT CASE} */}
              { getNeededParams("EBT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"EBT"} value='foodStampProcessing' id='foodStampProcessing' defaultChecked={getNeededParams("EBT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='foodStampProcessing'>Food Stamp Processing ({currency}{getNeededParams("EBT")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("ITU") &&
              getNeededParams("ITU")?.selectedValue == true && (<div>
                <Col span={24} className='mb-02 ml-18'>
                  <input type='radio' name={"ITU"} id='Logical' defaultChecked={getNeededParams("ITU")?.childList[0]?.selectedValue} onClick={(e)=>handleLevelOneRadio(e.target.name,1)}/> <label htmlFor='Logical'>Logical</label><br />
                  <input type='radio' name={"ITU"} id='Others' defaultValue={getNeededParams("ITU")?.childList[1]?.selectedValue} onClick={(e)=>handleLevelOneRadio(e.target.name,2)}/> <label htmlFor='Others'>Others ( prepay nation & lunex)</label>
                </Col>
                {getNeededParams("ITU")?.childList[0]?.selectedValue == true && (<Col span={24}>
                  <div className='ml-36 mb-1 merchantTextBox'>
                    <label className='text--grey fw-600' htmlFor='logicalMerchantID'>Logical Merchant ID</label><br />
                    <input type='text' name={"ITU_LOGICAL"} defaultValue={getNeededParams("ITU")?.childList[0][getNeededParams("ITU")?.childList[0]?.metaKey]} 
                      onChange={(e)=>handleLogicalMerchantInput(e.target.value)}  className='form-control' id='logicalMerchantID' />
                  </div>
                </Col>)}
              </div>)}
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("MULTI_LANGUAGE_SUPPORT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"MULTI_LANGUAGE_SUPPORT"} value='multiLanguageSupport' id='multiLanguageSupport' defaultChecked={getNeededParams("MULTI_LANGUAGE_SUPPORT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='multiLanguageSupport'>Multi Language Support ({currency}{getNeededParams("MULTI_LANGUAGE_SUPPORT")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("RECORD_VOIDED_TRANSACTION") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"RECORD_VOIDED_TRANSACTION"} value='recordVoidedTransaction' id='recordVoidedTransaction' defaultChecked={getNeededParams("RECORD_VOIDED_TRANSACTION")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='recordVoidedTransaction'>Record Voided Transaction ({currency}{getNeededParams("RECORD_VOIDED_TRANSACTION")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("TIP") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"TIP"} value='Tip' id='Tip' defaultChecked={getNeededParams("TIP")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='Tip' >Tip ({currency}{getNeededParams("TIP")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("GEO_LOCK") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"GEO_LOCK"} value='geoLock' id='geoLock' defaultChecked={getNeededParams("GEO_LOCK")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='geoLock'>Geo lock ({currency}{getNeededParams("GEO_LOCK")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("TIP") &&
              getNeededParams("TIP")?.selectedValue == true && (
                <Col span={24} className='mb-02 ml-18'>
                  <input type='radio' name={"TIP"} id='Employee TIP' defaultChecked={getNeededParams("TIP")?.childList[0]?.selectedValue} onClick={(e)=>handleLevelOneRadio(e.target.name,1)}/> <label htmlFor='Employee TIP'>Employee TIP</label><br />
                  <input type='radio' name={"TIP"} id='Common TIP' defaultChecked={getNeededParams("TIP")?.childList[1]?.selectedValue} onClick={(e)=>handleLevelOneRadio(e.target.name,2)}/> <label htmlFor='Common TIP'>Common TIP</label>
                </Col>)}
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("TIME_CLOCK_MANAGEMENT") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"TIME_CLOCK_MANAGEMENT"} value='timeClockManagement' id='timeClockManagement' defaultChecked={getNeededParams("TIME_CLOCK_MANAGEMENT")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='timeClockManagement'>Time Clock Management ({currency}{getNeededParams("TIME_CLOCK_MANAGEMENT")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("WEB_ACCESS") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"WEB_ACCESS"} value='webAccess' id='webAccess' defaultChecked={getNeededParams("WEB_ACCESS")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='webAccess' >Web Access ({currency}{getNeededParams("WEB_ACCESS")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("LOYALTY") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"LOYALTY"} value='Loyalty' id='Loyalty' defaultChecked={getNeededParams("LOYALTY")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='Loyalty'>Loyalty ({currency}{getNeededParams("LOYALTY")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("PRE_PAID_INVENTORY") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"PRE_PAID_INVENTORY"} value='baseModelwithInventoryManagement' id='baseModelwithInventoryManagement' defaultChecked={getNeededParams("PRE_PAID_INVENTORY")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='baseModelwithInventoryManagement'>Base Model with Inventory Management ({currency}{getNeededParams("PRE_PAID_INVENTORY")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("BARS") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"BARS"} value='barTab' defaultChecked={getNeededParams("BARS")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label htmlFor='barTab' className='ml-05'>Bar Tab ({currency}{getNeededParams("BARS")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("Tabbacco_scan") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"Tabbacco_scan"} value='tobaccoScanning' id='tobaccoScanning' defaultChecked={getNeededParams("Tabbacco_scan")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='tobaccoScanning'>Tobacco Scanning ({currency}{getNeededParams("Tabbacco_scan")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("BARS") &&
               getNeededParams("BARS")?.selectedValue == true && (<Col span={24}>
                <div className='ml-18 mb-1 merchantTextBox'>
                  <label className='text--grey fw-600' htmlFor='preAuthAmount'>Pre Auth Amount</label><br />
                  <input type='text' name={getNeededParams("BARS")?.metaKey} value={getNeededParams("BARS")[getNeededParams("BARS")?.metaKey]} className='form-control' id='preAuthAmount' 
                    onChange={(e)=>handleBarInput(e.target.value)}
                  />
                </div>
              </Col>)}
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("CCTV") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"CCTV"} value='CCTV' id='CCTV' defaultChecked={getNeededParams("CCTV")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='CCTV'>CCTV ({currency}{getNeededParams("CCTV")?.amount})</label>
                </div>
              </Col>
              }
              { getNeededParams("E_COMMERCE") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"E_COMMERCE"} value='ECOMMERCE' id="E-COMMERCE" defaultChecked={getNeededParams("E_COMMERCE")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label htmlFor='E-COMMERCE' className='ml-05'>E-COMMERCE ({currency}{getNeededParams("E_COMMERCE")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
            <Row gutter={8} className='mb-02'>
              { getNeededParams("SPA") &&
              <Col span={12}>
                <div className='d-flex mb-02 alignCenter'>
                  <input type='checkbox' name={"SPA"} value='SPA' id='SPA' defaultChecked={getNeededParams("SPA")?.selectedValue} onClick={(e)=>handleCheckBox(e.target.name, e.target.checked)}/> <label className='ml-05' htmlFor='CCTV'>SPA ({currency}{getNeededParams("SPA")?.amount})</label>
                </div>
              </Col>
              }
            </Row>
          </div>
        </Col>
        <Col>
        </Col>
      </Row>
      <div className="text-right mb-1">
          <Button
            type="warning"
            htmlType="submit"
            className="btn--large"
            onClick={()=>handleSubmit()}
          >
            {loader ? <SupportLoader/> : "Submit"}
          </Button>
        </div>
    </div>
  )
}

export default Parameters