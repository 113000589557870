import React, { useEffect, useState } from "react";
//import Pagetitle from "../../../layout/Pagetitle";
import HorizontalMenu from "../../../layout/Horizontalmenu.js";
import Itemschart from "./Itemschart";
import Itemsummarylist from "./Itemsummary";
import Filter from "../../../common/filter";
import Selectitemsrank from "../../../layout/filter/Itemsrank";
import Selectitemssummary from "../../../layout/filter/Itemssummary";
import {
  ApiHelper,
  message as cMessage,
  getUserData,
} from "../../../../utils/Helpers";
import Emptymsg from "../../../layout/message/Emptymsg";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { messages } from "../../../../utils/Common/Messages";
import { Layout, Row, Col, Skeleton, message } from "antd";
import moment from "moment";

const { Content } = Layout;
//const title = "Item Sales";

const Itemsales = (props) => {
  const defaultCurrency = getUserData().currencySign;
  const { filterData, setFilterData } = props;
  const userData = getUserData();
  const lan = userData.language ?? "EN";
  const metaKey = userData.metaKey;
  // const employeeId = userData ? userData.employeeId : 0;
  const organizationId = userData
    ? userData.organizationTypeId === 1
      ? userData.parentOrganizationId
      : userData.organizationId
    : 0;
  const [loadings, setLoadings] = useState(true);
  const [summaryLoadings, setSummaryLoadings] = useState(true);

  const download = userData.download ?? 0;
  const [noDatAvailable, setNoDatAvailable] = useState(false);
  const [startDate, setStartDate] = useState(filterData.startDate);
  const [productLimit, setProductLimit] = useState("5");
  const [productType, setProductType] = useState("amount");
  const [summaryType, setSummaryType] = useState("amount");
  const [endDate, setEndDate] = useState(filterData.endDate);
  const [interval, setInterval] = useState(filterData.interval);
  const [dateColums, setdateColums] = useState([]);
  const [productSaleData, setProductSaleData] = useState({
    month: [""],
    allData: {
      1: [],
    },
  });
  const [summaryReportData, setSummaryReportData] = useState([]);

  const searchData = async (
    sDate = filterData.startDate,
    eDate = filterData.endDate,
    shiftId = filterData.shiftId,
    type = filterData.interval,
    sId = userData.organizationTypeId === 1
      ? userData.organizationId
      : filterData.sId,
    limit = productLimit,
    summery = 1,
    employeeId = filterData.employeeId,
  ) => {
    console.log(employeeId)
    setFilterData({
      startDate: sDate,
      endDate: eDate,
      interval: type,
      shiftId: shiftId,
      sId: sId,
      gmtTime: getUserData().time_gmt,
      employeeId:employeeId
    });
    limit = limit !== "" ? limit : productLimit;

    setStartDate(sDate);
    setEndDate(eDate);
    setInterval(type);
    setProductLimit(limit);
    setSummaryLoadings(true);
    if (summery === 1) setLoadings(true);
    getSummaryReport(sDate, eDate, shiftId, type, sId,employeeId);
    if (summery === 1) {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ITEMSALEPRODUCT.service,
        ApiRoutes.ITEMSALEPRODUCT.url,
        ApiRoutes.ITEMSALEPRODUCT.method,
        ApiRoutes.ITEMSALEPRODUCT.authenticate,
        {
          limit: limit,
          filter: productType,
          startDt: sDate,
          endDt: eDate,
          organizationId: organizationId,
          storeId: sId ? sId.toString() : sId,
          shiftReport: shiftId,
          interval: type,
          gmtTime: getUserData().time_gmt,
          employeeId:employeeId
        },
        undefined
      );
      if (response.code === 200) {
        if (
          response.data.month.length === 0 ||
          response.data.allData.length === 0 ||
          (response.data.allData[1] && response.data.allData[1].length === 0)
        ) {
          setNoDatAvailable(true);
          setLoadings(false);
        } else {
          setNoDatAvailable(false);
          setProductSaleData({
            allData: response.data.allData,
            month: response.data.month,
          });
          setTimeout(() => setLoadings(false), 500);

          //   // paymentCollected(sDate, eDate);
        }
      } else {
        message.error({
          content: cMessage.reportHourError,
          duration: 2,
        });
      }
    }
  };

  const getSummaryReport = async (
    sDate = "",
    eDate = "",
    shiftId = "",
    type = "",
    sId = "",
    employeeId=""
  ) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUMMARY_REPORT_LIST.service,
      ApiRoutes.SUMMARY_REPORT_LIST.url,
      ApiRoutes.SUMMARY_REPORT_LIST.method,
      ApiRoutes.SUMMARY_REPORT_LIST.authenticate,
      {
        startDt: sDate,
        endDt: eDate,
        organizationId: organizationId,
        storeId: sId ? sId.toString() : sId,
        shiftReport: shiftId,
        interval: type,
        gmtTime: getUserData().time_gmt,
        employeeId:employeeId
      },
      undefined
    );
    if (response.code === 200) {
      setSummaryLoadings(false);
      setdateColums(response.data.allDate);
      setSummaryReportData({
        amountData: response.data.amountData,
        soldData: response.data.soldData,
      });
    } else {
      // message.error({
      //   content: cMessage.reportHourError,
      //   duration: 2,
      // });
    }
  };
  useEffect(() => {
    searchData(startDate, endDate);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {/*<Pagetitle title={title} />*/}
      <HorizontalMenu />
      <Filter
        searchData={searchData}
        organizationId={organizationId}
        pageType="item"
        filterData={filterData}
        setFilterData={setFilterData}
        userData={userData}
        lan={lan}
        currencySign={defaultCurrency}
        download={download}
        metaKey={metaKey}
      />
      {noDatAvailable === false ? (
        <Content className="content-body" style={{ minHeight: 200 }}>
          <Row>
            <Col lg={24} xs={24}>
              <div
                className="bg-white"
                style={{
                  margin: "0px 14px 14px 14px",
                  padding: "14px 14px 0 14px",
                  minHeight: 200,
                }}
              >
                <Skeleton paragraph={{ rows: 7 }} loading={loadings} active>
                  <div className="left-heading-right-button">
                    <h4 className="text-uppercase">{`${
                      messages[moment(startDate).format("MMM")][lan]
                    } ${moment(startDate).format("DD, Y")}-${
                      messages[moment(endDate).format("MMM")][lan]
                    } ${moment(endDate).format("DD, Y")}`}</h4>
                    <span>
                      <Selectitemsrank
                        selectvalue={productLimit}
                        updateLimit={searchData}
                        filterData={filterData}
                        lan={lan}
                      />
                    </span>
                    <span className="space-ml-8">
                      <Selectitemssummary
                        selectvalue={productType}
                        updateType={setProductType}
                        lan={lan}
                      />
                    </span>
                  </div>
                  <Itemschart
                    productSaleData={productSaleData}
                    productType={productType}
                    intervals={interval}
                    productLimit={productLimit}
                  />
                </Skeleton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <div
                className="bg-white"
                style={{ margin: "0px 14px 14px 14px", padding: 14 }}
              >
                <Skeleton
                  paragraph={{ rows: 4 }}
                  loading={summaryLoadings}
                  active
                >
                  <div className="left-heading-right-button">
                    <h4 className="mb-1 text-uppercase">
                      {messages.summaryReport[lan]}
                    </h4>
                    {interval !== "summary" ? (
                      <span className="space-ml-8">
                        <Selectitemssummary
                          selectvalue={summaryType}
                          updateType={setSummaryType}
                          lan={lan}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <Itemsummarylist
                    dataSource={summaryReportData}
                    interval={filterData.interval}
                    dateColums={dateColums}
                    defaultCurrency={defaultCurrency}
                    summaryType={summaryType}
                    lan={lan}
                  />
                </Skeleton>
              </div>
            </Col>
          </Row>
        </Content>
      ) : (
        <Content className="content-body" style={{ minHeight: 200 }}>
          <Row>
            <Col lg={24} xs={24}>
              <div
                className="bg-white"
                style={{
                  margin: "0px 14px 0px 14px",
                  padding: 14,
                  minHeight: 200,
                }}
              >
                <Skeleton paragraph={{ rows: 8 }} loading={loadings} active>
                  <Emptymsg
                    emptytitle={messages.emptyTitle[lan]}
                    emptydescription={messages.emptyDescription[lan]}
                  />
                </Skeleton>
              </div>
            </Col>
          </Row>
        </Content>
      )}
    </>
  );
};
export default Itemsales;
