import React, { useState, useEffect } from "react";
import "../support.less";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AxiosService from "../../../../utils/Config/ApiService";
import SupportLoader from "../../../../utils/Config/SupportLoader";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";

function ReceiptById() {
  const [receipt, setReceipt] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showStatus, setShowStatus] = useState(false);
  const [discount, setDiscount] = useState(0)
  const pointepayOrderId = useParams()?.id;
  const history = useHistory();
  sessionStorage.setItem("activePageKey", "2");
  const handleGetReceiptById = async () => {
    try {
      let res = await AxiosService.get(
        `${ApiRoutes.STORE_RECEIPT.service}${ApiRoutes.STORE_RECEIPT.url}/${pointepayOrderId}`
      );

      if (res.status === 200) {
        if (res?.data?.receiptData) {
          let { orderProductList, orderTaxList, orderProductDiscountList, pointepayOrderDetail } = res.data.receiptData;
          //append taxname in productlist
          const updatedProductList = orderProductList?.map((product) => {
            const matchTax = orderTaxList.find(
              (tax) => tax.orderProductId === product.orderProductId //gives the result obj
            );

            if (matchTax) {
              return {
                ...product,
                taxName: matchTax.taxName,
              };
            } else {
              return {
                ...product,
                taxName: "",
              };
            }
          });
          

          setReceipt({
            ...res.data.receiptData,
            orderProductList: updatedProductList,
          });
          
          setShowStatus(
            updatedProductList.some((element) => element.taxName !== "")
          );

          //generate discount 

          if(orderProductDiscountList.length > 0){
            const discountArr =  orderProductDiscountList.map((cur, i) => {
              return Number(cur.discountAmount)
           })
           
           
           const discount =  discountArr.reduce((cur, acc) => cur + acc, 0)

           setDiscount(Number(discount) + Number(pointepayOrderDetail.cartDiscount))
          }

          if((orderProductDiscountList.length === 0)){
            setDiscount(Number(pointepayOrderDetail.cartDiscount))
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleGenerateShortName = (fullName) => {
    let shortName = "";
    let splittedArray = fullName.split(" ");
    if (splittedArray.length > 1) {
      shortName = splittedArray[0][0] + splittedArray[1][0];
    } else {
      if (splittedArray[0].length > 2) {
        shortName += splittedArray[0][0] + splittedArray[0][1];
      } else {
        shortName = splittedArray[0];
      }
    }
    return shortName.toUpperCase();
  };

  const ConvertToCurrency = (num, sign) => { //adds currency sign and currency format
    const numStr = num?.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return numStr.includes("-") ? "-" + " "+ sign +" "+ numStr.replace("-","")  : sign + " " + numStr
  };

  useEffect(() => {
    handleGetReceiptById();
  }, []);

  return (
    <div>
      <div className="receipt-sec">
        <div id="printHeader" className="col-sm-12 text-right header-sec">
          <div>
            <Button
              type="warning"
              className="mr-3 btn--large d-flex align-items-center"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </div>
          {/*  <a href="javascript:void(0)" className="btn btn-default btn-add w-md mt-2 mb-2 ml-2"><i className="mdi mdi-printer"></i> Print With X Printer</a>
         <a href="javascript:void(0)" className="btn btn-default btn-add mt-2 mb-2 ml-2"><i className="mdi mdi-printer"></i> Print</a>
         <a href="javascript:void(0)" className="btn btn-default btn-add w-md mt-2 mb-2 ml-2" ><i className="mdi mdi-email"></i> Send</a> */}
        </div>
        {isLoading ? (
          <SupportLoader />
        ) : (
          <div id="ReceiptPrint" className="ReceiptPrint body-sec">
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <div className="d-flex pt-4">
                  <div className="flex-fill align-self-center text-center">
                  <img
                    alt="defaultLOGO"
                      width="150px"
                      src="https://ekikart.test.spointe.org/frontend/barcode/generateBarCode?text=21005"
                      className="mb-05"
                    />

                    <h4 className="mb-0 text-center fs-15">
                      {receipt?.pointepayOrderDetail?.pointepayOrderId}
                    </h4>
                  </div>
                  <div className="flex-fill pl-4 border-l-dashed">
                    <div className="d-flex">
                      <div className="flex-fill">
                        <div className="d-flex pt-1 spaceBetween">
                          <p className="mb-0 fs--1 text--grey">Order Date</p>
                        </div>
                        <h5 className="mt-0 mb-3 fs-09">
                          {receipt?.pointepayOrderDetail?.createDt}
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="flex-fill">
                        <p className="mb-0 fs--1 text--grey">Sales Person</p>
                        <h5 className="mt-0 pr-4 fs-09">
                          {receipt?.employeeDetail?.firstName}{" "}
                          {receipt?.employeeDetail?.lastName}
                        </h5>
                      </div>
                      <div className="flex-fill">
                        <p className="mb-0 fs--1 text--grey">Total Amount</p>
                        <h5 className="mt-0 fs-09">
                         
                          {ConvertToCurrency(Number(receipt?.pointepayOrderDetail?.paidAmount), receipt?.organizationDetail?.currencySign )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div className="d-flex mt-3 address-sec">
                  <div className="flex-fill d-flex align-items-center text-center bg-gray mr-3 address-sec-left lite--logo position-relative">

                                        {
                      receipt?.organizationDetail?.imageUrl ?
                       <img
                      alt="dynamicLOGO"
                        width="95%"
                        src={receipt?.organizationDetail?.imageUrl}
                        className="align-self-center"
                      /> : 
                      <img
                      alt="default"
                      className="align-self-center"
                      width="95%"
                      src="https://ekikart.test.spointe.org/assets/retailer/images/logo-blue.svg"
                    />
                    }
                  </div>
                  <div className="flex-fill bg-gray p-3 pl-4 mr-3 address-sec-right">
                    <h4 className="mt-0 text--black1 fs-15">
                      {receipt?.organizationDetail?.organizationName}
                    </h4>
                    <p className="mb-0 fs--1 text--grey">
                      {receipt?.organizationDetail?.addressLine1}{" "}
                      {receipt?.organizationDetail?.zip}
                    </p>
                    <p className="mb-0 fs--1 text--grey">
                      {receipt?.organizationDetail?.cityName},
                      {receipt?.organizationDetail?.stateName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 pl-3 pr-3 mt-3">
              <table className="table receipt-item-table">
                <thead>
                  <tr>
                    <th width="28%">Name</th>
                    <th width="15%">Type</th>
                    <th>QTY</th>
                    <th>Price</th>
                    {showStatus ?  <th>Tax Name</th>: <></>}
                     <th>Status</th> 
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {receipt?.orderProductList?.length > 0 ? (
                    receipt?.orderProductList?.map((product, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <h3 className="m-0 font-weight-normal d-flex align-items-center">
                           {
                            product?.productImage ?
                            <span className="product-name">
                            <img
                              alt="productImage"
                              width="100%"
                              height="75%"
                              src={product?.productImage}
                      />
                      </span>
                            
                            :    
                            <span className="product-name">
                            {handleGenerateShortName(product?.productName)}
                          </span>
                           }
                              <div className="text--grey">
                                <strong>{product?.productName}</strong>
                                {product.pin?<><br/>{product.pin}</>:<></>}
                                {product.cardAction?<><br/>{product.cardAction}</>:<></>}
                              </div>
                            </h3>
                          </td>
                          <td>
                            <span>{product?.productType}</span>
                          </td>
                          <td>{product?.quantity}</td>
                          <td>
                            <span>
                              
                              {ConvertToCurrency(
                                Number(product?.productAmount), receipt?.organizationDetail?.currencySign
                              )}
                            </span>
                          </td>
                          {showStatus ? (
                            <td>
                              <span>{handleGenerateShortName(product?.taxName)}</span>
                            </td>
                          ) : (
                            <></>
                          )}

                          <td>
                            <span>-</span>
                          </td>

                          <td>
                            <strong>
                            
                              {ConvertToCurrency(Number(product?.productAmount), receipt?.organizationDetail?.currencySign)}
                            </strong>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row payment-details-sec m-0 ml-1">
              <div className="col-sm-8">
                <ul className="heading dp mb-0">
                  <li className="text-uppercase">Payment Detail </li>
                </ul>
                <div className="payment-details-inner dp">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <th className="bg--gray fs--1 text--grey">Amount</th>
                        <th className="text-right bg--gray fs--1 text--grey">
                          Date Time
                        </th>
                      </tr>
                      {receipt?.orderPaymentList?.length > 0 ? (
                        receipt?.orderPaymentList?.map((payment, i) => {
                          return (
                            <tr key={i}>
                              <td
                                width="35%"
                                className="align-top text--grey fs--1 bg--gray"
                              >
                                <strong className="text--grey">
                                  {ConvertToCurrency(Number(payment?.amount), receipt?.organizationDetail?.currencySign)}
                                </strong>
                                <br />
                                <span>{payment?.description}</span>
                              </td>
                              <td className="text-right align-top  bg--gray">
                                <small className="fs-09 text--grey">
                                  {payment?.transactionDate}
                                </small>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-4 payment-details-amount-sec pt-4 dp">
                <div className="d-flex">
                  <div className="flex-fill">
                    <h6>Total Sell Amount</h6>
                  </div>
                  <div className="flex-fill">
                    <h6 className="text-right ">

                      {ConvertToCurrency(
                  (      Number(receipt?.pointepayOrderDetail?.totalAmount) -
                          Number(receipt?.pointepayOrderDetail?.totalTaxAmount)), receipt?.organizationDetail?.currencySign
                      )}
                    </h6>
                  </div>
                </div>
                {/* <div className="d-flex">
            <div className="flex-fill">
              <h6>Total Return Amount</h6>
            </div>
            <div className="flex-fill">
              <h6 className="text-right">₦0.00</h6>
            </div>
          </div> */}
                <div className="d-flex border-t-dashed">
                  <div className="flex-fill">
                    <h5>Total Product Amount</h5>
                  </div>
                  <div className="flex-fill">
                    <h4 className="text-right ">

                      {ConvertToCurrency(
                      (  Number(receipt?.pointepayOrderDetail?.totalAmount) -
                          Number(receipt?.pointepayOrderDetail?.totalTaxAmount)), receipt?.organizationDetail?.currencySign
                      )}
                    </h4>
                  </div>
                </div>
                <div className="d-flex border-t-dashed">
                  {Number(receipt?.pointepayOrderDetail?.tipAmount) ? (
                    <>
                      {" "}
                      <div className="flex-fill">
                        <h5>Tip Amount</h5>
                      </div>
                      <div className="flex-fill">
                        <h4 className="text-right ">
                          {ConvertToCurrency(
                            Number(receipt?.pointepayOrderDetail?.tipAmount), receipt?.organizationDetail?.currencySign
                          )}
                        </h4>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-flex ">
                  {receipt?.pointepayOrderDetail?.totalTaxAmount ? (
                    <>
                      <div className="flex-fill">
                        <h5>Tax</h5>
                      </div>
                      <div className="flex-fill">
                        <h4 className="text-right ">

                          {ConvertToCurrency(
                            Number(
                              receipt?.pointepayOrderDetail?.totalTaxAmount
                            ), receipt?.organizationDetail?.currencySign
                          )}
                        </h4>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-sm-12 p-0 tax-details-sec ">
                  {receipt?.orderTaxList?.length > 0 ? (
                    <table
                      width="100%"
                      className="table fs--1"
                      cellpadding="10"
                      cellspacing="10"
                    >
                      <tbody>
                        <tr className="bg-white">
                          <th width="32%" className="fs--1 text--grey">
                            Tax Name
                          </th>{" "}
                          &nbsp;
                          <th className="text--grey">%</th> &nbsp;
                          <th className="text-right text--grey">
                            Taxable Amount
                          </th>{" "}
                          &nbsp;
                          <th className="text-right text--grey">
                            Tax Value
                          </th>{" "}
                          &nbsp;
                        </tr>
                        {receipt?.orderTaxList?.length > 0 ? (
                          receipt?.orderTaxList?.map((tax, i) => {
                            return (
                              <tr key={i}>
                                <td>{tax?.taxName}</td> &nbsp;
                                <td>{tax?.tax}%</td> &nbsp; &nbsp;
                                <td>
                                  {ConvertToCurrency(Number(tax?.productPrice), receipt?.organizationDetail?.currencySign)}
                                </td>{" "}
                                &nbsp;
                                <td>
                                  {ConvertToCurrency(Number(tax?.taxAmount), receipt?.organizationDetail?.currencySign)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <></>
                  )}
                  </div>
                                  <div className="d-flex ">
                                  {(receipt?.orderProductDiscountList?.length > 0 || discount !== 0)? (
                    <>
                      <div className="flex-fill">
                        <h5>Discount</h5>
                      </div>
                      <div className="flex-fill">
                        <h4 className="text-right ">

                          {ConvertToCurrency(Number(discount), receipt?.organizationDetail?.currencySign)}
                          
                        </h4>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
               </div>
                    <div className="col-sm-12 p-0 tax-details-sec ">
                   {receipt?.orderProductDiscountList?.length > 0 ? (
                    <table
                      width="100%"
                      className="table fs--1"
                      cellpadding="10"
                      cellspacing="10"
                    >
                      <tbody>
                        <tr className="bg-white">
                          <th width="32%" className="fs--1 text--grey">
                            Discount Name
                          </th>{" "}
                          &nbsp;
                          <th className="text-right text--grey">
                            %
                          </th>{" "}
                          &nbsp;
                          <th className="text-right text--grey">
                            Discount Amount
                          </th>{" "}
                          &nbsp;
                        </tr>
                        {receipt?.orderProductDiscountList?.length > 0 ? (
                          receipt?.orderProductDiscountList?.map((discount, i) => {
                            return (
                              <tr key={i}>
                                <td>{discount?.discountName}</td> &nbsp;
                                <td className="text-right ">{discount?.discount}%</td> &nbsp; &nbsp;
                                <td className="text-right ">

                                  {ConvertToCurrency(Number(discount?.discountAmount), receipt?.organizationDetail?.currencySign)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <></>
                  )}
                </div>
              
                <div className="d-flex border-t-dashed">
                  <div className="flex-fill">
                    <h5>
                      <strong>Total</strong>
                    </h5>
                  </div>
                  <div className="flex-fill">
                    <h4 className="text-right text--grey">
                      <strong>

                        {ConvertToCurrency(
                          receipt?.pointepayOrderDetail?.paidAmount, receipt?.organizationDetail?.currencySign
                        )}
                      </strong>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <h4 className="mb-0 ">Return Policy:</h4>
                <p className=" ">{receipt?.returnPolicyData?.metavalue}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReceiptById;
