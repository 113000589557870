import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Input, Row, message, DatePicker } from 'antd'
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import PaginatedTable from '../../../common/PaginatedTable';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import Nodata from "../../../../assets/images/support/nodata.png";
import SupportLoader from '../../../../utils/Config/SupportLoader';
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';
import moment from "moment"
const { RangePicker } = DatePicker;


const MerchantList = () => {

    const pageNumberLimit = 3

    const [pageloader, setpageloader] = useState(true)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  
    let [validToken, setValidToken] = useState(false);
    let renderRef = useRef(false);
    const [messageApi, contextHolder] = message.useMessage();
  
    let [totalCount, setTotalCount] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(10)
    let [searchKey, setSearchKey] = useState("")
    let [merchant, setMerchants] = useState([])
  
    let [isloader, setisloader] = useState(true)
  
    let {search} = useLocation()
    let history = useHistory()
  
    let [dates, setDates] = useState([
      new Date().toISOString().split('T')[0],
      new Date().toISOString().split('T')[0]
    ])

    const handleDateChange = (dates) => {
      console.log(dates)
      let startDate = dates[0].format('YYYY-MM-DD')
      let endDate = dates[1].format('YYYY-MM-DD')
      console.log(startDate, endDate)
      setDates([startDate, endDate])
    }

    const column = [
      {
        heading: () => '#',
        cell: (row, i) => {
            return i;
        },
        hidden: false,
        width: "20",
        id: 1
      },
      {
        heading: () => "Creation Date",
        cell: row => row["createdAt"],
        hidden: false,
        width: "100",
        id: 2
      },
      {
        heading: () => "Business Name",
        cell: row => row['legalName'],
        hidden: false,
        width: "120",
        id: 3
      },
      {
        heading: () => "Business Phone Number",
        cell: row => row['businessPhoneNumber'],
        hidden: false,
        width: "100",
        id: 4
      },
      {
        heading: () => "Business Email",
        cell: row => row['businessEmail'],
        hidden: false,
        width: "100",
        id: 5
      },
      {
        heading: () => "Agent Name",
        cell: row => row["agentName"] ?? "-",
        hidden: false,
        width: "100",
        id: 6
      },
      {
        heading: () => "Business State",
        cell: row => row['stateName'],
        hidden: false,
        width: "100",
        id: 7
      },
      {
        heading: () => "Business City",
        cell: row => row['cityName'],
        hidden: false,
        width: "100",
        id: 8
      },
      {
        heading: () => "Actions",
        cell: row => {
          // sessionStorage.setItem("searchKey", searchKey)
          return <>
            {
              <>
              <Link to={`/distributor/merchantApplicationForm/view/${row.id}`} className="btn--small btn--view"><EyeOutlined /></Link>
              </>
            }
          </>
        },
        hidden: false,
        width: "20",
        id: 9
      }
    ]
  
    const verifyToken = async()=>{
      let query = new URLSearchParams(search)
      let token;
  
      if(query.get('token'))
      {
        token = query.get('token')
        sessionStorage.setItem('token',query.get('token'))
      }
      else
      {
        token = sessionStorage.getItem('token')
      }
  
      if(token)
      {
        try {
          let res = await AxiosService.post(`${ApiRoutes.SUPPORT_VERIFY_TOKEN.service}${ApiRoutes.SUPPORT_VERIFY_TOKEN.url}`,{token})
          if(res.status===200)
          {
            sessionStorage.setItem('userData',JSON.stringify(res.data.userData))
            sessionStorage.setItem('supportToken',(res.data.token))
            setValidToken(true);
            history.push('/distributor/merchantApplicationForm')
          }
        } catch (error) {
          if(error.response.status===400){
            alert('Session Expired')
            history.push('/')
          }
          else{
            alert('Error Occoured. Login Again')
            history.push('/')
          }
        }
      }
      else
      {
        alert('No token found')
        history.push('/')
      }
    }  
    
    const getMerchantList = async() => {      
      try{
        let params = {
          pgno : currentPage,
          limit : itemsPerPage,
          searchKey : searchKey,
          startDate: dates[0],
          endDate: dates[1]
        }
        const response = await AxiosService.get(`${ApiRoutes.GET_MERCHANT.service}${ApiRoutes.GET_MERCHANT.url}`,{
          params : params
        });
        if(response.status === 200){
          setisloader(false)
          setMerchants(response.data.data)
          setTotalCount(response.data.count)
          setpageloader(false) 
        }
      }catch(error){
        console.log(error)
        setisloader(false)
        setpageloader(false) 
        messageApi.open({
          type: "error",
          content: error.response.data.error ?? error.response.data.message
        })
      }
    }
  
    useEffect(()=>{
      verifyToken()
    },[])
  
    useEffect(() => {
      if (renderRef.current == true) {
        const delayFn = setTimeout(() => {
          currentPage == 1 ? getMerchantList() : setCurrentPage(1);
        }, 1000);
        return () => clearTimeout(delayFn);
      } else {
        renderRef.current = true;
      }
    }, [searchKey]);
  
    useEffect(() => {
        validToken && getMerchantList();
    },[currentPage, itemsPerPage, dates, validToken])
  
    return (
      <>
      {contextHolder}
      <div className="adminContentSection">
        <Row gutter={8}>
          <Col span={10}>
            <Input 
              size="large"
              id="search"
              placeholder="Search by Business Name, Email, Phone Number, State, City, Agent Name..." 
              prefix={<SearchOutlined />} 
              className="supportSearchBox"
              value={searchKey}
              onClick={() =>{
                setTotalCount(0)
              }}
              onChange={(e) => {
                const regex = /^[ ]/g
                if(regex.test(e.target.value)){
                  e.target.value?.replace(/^[ ]/g,"")
                }else{
                  setpageloader(true)
                  setSearchKey(e.target.value)
                }
              }}
            />              
          </Col>
          <Col span={5}>
            <RangePicker style={{ height: "100%"}} format={'MM-DD-YYYY'} 
              defaultValue={[moment(new Date()), moment(new Date())]}
              onChange={handleDateChange}
            />
          </Col>
          <Col span={4}>
            <div>
              <select style={{width : "85%"}} 
                defaultValue={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                  setMaxPageNumberLimit(pageNumberLimit);
                  setMinPageNumberLimit(0);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </Col>
        </Row> 
          {
            isloader ? <SupportLoader/> :
            (!!merchant.length) ? <PaginatedTable
              row={merchant}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isFilterHeader={false}
              isSearch = {false}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              isloader = {isloader}
              setpageloader={setpageloader}
              pageloader={pageloader}
              isvisible = {false}            
            /> : <div className="text-center p-x8-y16 bg-white mt30"><img src={Nodata} alt='icon'/></div>
          }
      </div>
      </>
    )
}

export default MerchantList