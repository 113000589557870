import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { AppConfig } from "../../../../utils/Config/AppConfig";
import {
  ApiHelper,
  message as cMessage,
  setUserData
} from "../../../../utils/Helpers";
import { message } from "antd";
const icon = {
  hidden: {
    opacity: 1,
    pathLength: 0
  },
  visible: {
    opacity: 1,
    pathLength: 1
  }
};
const Proxylogin = (props) => {
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  const token = query.get("token");
  const page = query.get("page");
  const download = query.get("d") ?? 0;
  const verifyToken = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_TOKEN.service,
      ApiRoutes.VERIFY_TOKEN.url,
      ApiRoutes.VERIFY_TOKEN.method,
      ApiRoutes.VERIFY_TOKEN.authenticate,
      undefined,
      { token: token, csrf_test_name: AppConfig.CSRF_TEST_NAME }
    );
    if (response.code === 200) {
      if (response.data.data) {
        response.data.data.download = download;
        await setUserData(response.data.data);
        if (page === "sale") {
          history.push({
            pathname: `/`
          });
        } else if (page === "item") {
          history.push({
            pathname: `/itemsales`
          });
        } else if (page === "liteSell") {
          history.push({
            pathname: `/sell`
          });
        }
        else if(page==="support"){
          history.push({
            pathname:'/support'
          })
        }
         else {
          history.push({
            pathname: `/`
          });
        }
      } else {
        window.location.href = AppConfig.ROOTURL;
      }
    } else {
      message.error({
        content: cMessage.reportHourError,
        duration: 2
      });
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, [query]);
  console.log("page=========", page);
  return (
    <>
      {page != "liteSell" ? (
        <div className="pageLoader">
          <div className="loaderContainer">
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="185.833px"
              height="140.667px"
              viewBox="0 0 185.833 260.667"
              enable-background="new 0 0 185.833 170.667"
              className="loaderItem"
            >
              <motion.path
                id="Path_3364"
                fill="#21649C"
                d="M110.128,57.04v26.023h45.104v16.08H90.113V2h64.977v16.229h-44.961V57.04z"
                variants={icon}
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 2, repeat: Infinity, ease: "easeInOut" },
                  fill: { duration: 2, repeat: Infinity, ease: [1, 0, 0.8, 1] }
                }}
              />
              <motion.path
                id="Path_3371"
                fill="#FFDA61"
                d="M157.826,112.25l-101.034-2.074L31.903,71.958c0,0-8.297-11.554,11.261-11.848
      c9.899-0.15,102.8-0.292,110.847-0.389V43.968l-40.907-0.173l0,0H20.051c0,0-20.738-1.478-17.758,22.222
      c2.979,23.699,33.165,62.827,40.871,67.564c112.289,6.517,116.736,7.408,116.736,7.408L157.826,112.25z"
                variants={icon}
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 2, repeat: Infinity, ease: "easeInOut" },
                  fill: { duration: 2, repeat: Infinity, ease: [1, 0, 0.8, 1] }
                }}
              />
              <motion.circle
                id="Ellipse_1"
                fill="#21649C"
                cx="89.672"
                cy="159.51"
                r="9.184"
                variants={icon}
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 2, repeat: Infinity, ease: "easeInOut" },
                  fill: { duration: 2, repeat: Infinity, ease: [1, 0, 0.8, 1] }
                }}
              />
              <motion.circle
                id="Ellipse_2"
                fill="#21649C"
                cx="129.968"
                cy="159.51"
                r="9.184"
                variants={icon}
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 2, repeat: Infinity, ease: "easeInOut" },
                  fill: { duration: 2, repeat: Infinity, ease: [1, 0, 0.8, 1] }
                }}
              />
              <g id="Group_5246" transform="translate(41.429 44.695)">
                <motion.path
                  id="Path_3372"
                  fill="#F9D341"
                  d="M104.15,107.16v2.958h34.372l4.741-2.961L104.15,107.16z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: {
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    },
                    fill: {
                      duration: 2,
                      repeat: Infinity,
                      ease: [1, 0, 0.8, 1]
                    }
                  }}
                />
                <motion.path
                  id="Path_3373"
                  fill="#F9D341"
                  d="M104.15,117.233h17.314l2.39-2.963H104.15V117.233z"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: {
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    },
                    fill: {
                      duration: 2,
                      repeat: Infinity,
                      ease: [1, 0, 0.8, 1]
                    }
                  }}
                />
              </g>
            </motion.svg>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Proxylogin;
