import React from "react";
import { Select } from "antd";
import { getUserData } from "../../../utils/Helpers";
import { messages } from "../../../utils/Common/Messages";
const { Option } = Select;

const Selectsummary = (props) => {
  const { data } = props;
  const selectOnChange = (value) => {
    props.filterdata(data.startDate, data.endDate, data.shiftId, value);
  };
  const lan = getUserData().language ?? "EN";
  return (
    <Select
      defaultValue={props.selectvalue}
      style={{ width: 110 }}
      onChange={selectOnChange}
    >
      <Option value="summary">{messages.summary[lan]}</Option>
      <Option value="monthly">{messages.monthly[lan]}</Option>
      <Option value="weekly">{messages.weekly[lan]}</Option>
      <Option value="daily">{messages.daily[lan]}</Option>
    </Select>
  );
};
export default Selectsummary;
