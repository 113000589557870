import React, { useEffect, useState } from "react";
import { Button} from 'antd';
import PaginatedTable from "../../../common/PaginatedTable";
import { Col, Input, Row, Select } from 'antd';
import {
    ArrowLeftOutlined,
    SearchOutlined, EyeOutlined
} from "@ant-design/icons";

import '../support.less';
import { Link, useParams , useHistory} from "react-router-dom";
import AxiosService from "../../../../utils/Config/ApiService";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
const Order = () => {
    const history =  useHistory();
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [pageloader, setpageloader] = useState(true)
    const [shippingStatusId, setorderStatusId] = useState("")
    const [orders, setorders] = useState([])
    const [statusList, setstatusList] = useState([])
    const pageNumberLimit = 3
    let [addToggle, setAddToggle] = useState(false);
    let [viewToggle, setViewToggle] = useState(false);
    let [totalCount, setTotalCount] = useState(0)
    let [currentPage, setCurrentPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(10)
    let [searchKey, setSearchKey] = useState("")
    let [users, setUsers] = useState([
        { MerchantOrderId: '4588', OrganizationName: 'Jesi World', Status: 'In Process' },
        { MerchantOrderId: '4588', OrganizationName: 'Jesi World', Status: 'In Process' }
    ]);
    let [userid, setuserid] = useState("")
    let [statusFilter, setStatusFilter] = useState("All");
    let [isLoading, setIsLoading] = useState(true)

    //useState is required if we need column filter
    const [column, setColumn] = useState([
        {
            heading: () => 'Merchant Order Id',
            cell: row => {
                return row['merchantOrderId']
            },
            hidden: false,
            width: "20",
            id: 1
        },
        {
            heading: () => "Organization Name",
            cell: row => {
                return row['organizationName']
            },
            hidden: false,
            width: "100",
            id: 2
        },
        {
            heading: () => "Status",
            cell: row => {
                return row['shippingStatus'] === null ? "New Order" : row['shippingStatus']
            },
            hidden: false,
            width: "100",
            id: 3
        },
        {
            heading: () => "Actions",
            cell: row => {
                return <><Link to= {`/support/orderDetail/${row['merchantOrderId']}` }className="btn--small btn--view"><EyeOutlined /></Link></>
            },
            hidden: false,
            width: "20",
            id: 4
        }

    ])

    //required to get the filtered column
    let filterOptions = []
    //default checked values of select options
    let filterDefaultValue = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 13 }, { value: 14 }]

    //generate the options of multi select
    column.forEach((e) => {
        let option = {
            label: e.id === 13 ? 'Status' : e.heading(),
            value: e.id
        }
        filterOptions.push(option)
    })

    //to handle the change of the react-multi select
    const handleFilterSelection = (options) => {
        let newColumn = [...column]
        for (let i = 0; i < newColumn.length; i++) {
            let data = options.filter((e) => newColumn[i].id === e.value)
            data.length === 1 ? newColumn[i].hidden = false : newColumn[i].hidden = true
        }
        setColumn(newColumn)
    }

    const organizationId = useParams()?.id
    const handleGetOrders = async() =>{
        try{
            let res = await AxiosService.get(`${ApiRoutes.ORDERS.service}${ApiRoutes.ORDERS.url}/${organizationId}`,{
                params : {
                  pgno : currentPage,
                  limit : itemsPerPage,
                  searchKey : searchKey,
                  shippingStatusId : shippingStatusId
              }})
              if(res.status === 200){
                setorders(res.data.data)
                setpageloader(false)
                setTotalCount(res.data.count)
                setIsLoading(true)
                
              }
        }catch(error){
           console.log(error) 
           setpageloader(false)
        }
    }

    const handleGetStatusList = async() =>{
        try{
            let res = await AxiosService.get(`${ApiRoutes.ORDER_STATUS.service}${ApiRoutes.ORDER_STATUS.url}`)
            if(res.status === 200){
                setstatusList(res.data.data)
            }
        }catch(error){
            console.log(error)
        }
    }
useEffect(() =>{
    handleGetStatusList()
},[])

useEffect(() => {
    handleGetOrders()
},[currentPage, itemsPerPage, searchKey, shippingStatusId])

    return (
        <div className="adminContentSection">
            <Row gutter={8} className='displayFlex mt10 mb-05 '>
            <div >
                <Button type="warning" className='mr-3 btn--large d-flex align-items-center'
                onClick={() => {
                  history.goBack()
                }}
                ><ArrowLeftOutlined /> Back</Button>
              </div>
                <Col span={10}>
                    <Input size="large" placeholder="Search by Merchant Order Id and Organization Name..." 
                                    onChange={(e) => {
                                        const regex = /^[ ]/g
                                        if(regex.test(e.target.value)){
                                            e.target.value?.replace(/^[ ]/g,"")
                                        }else{
                                            setSearchKey(e.target.value)
                                        }}}
                    prefix={<SearchOutlined />} className="supportSearchBox" />
                </Col>
                <Col span={4}>
                    <div>

 
        <select
        style={{width : "85%"}}
        defaultValue={itemsPerPage}
        onChange={(e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1);
            setMaxPageNumberLimit(pageNumberLimit);
            setMinPageNumberLimit(0);
        }}
        >
  <option value={5}>5</option>
  <option value={10}>10</option>
  <option value={25}>25</option>
  <option value={50}>50</option>
  <option value={100}>100</option>
</select>
&nbsp; 
</div>
            </Col>
                <Col span={4}>
                    <select
                    style={{ width: '100%' }}
                    onChange={(e) => {
                        setpageloader(true)
                        setorderStatusId(e.target.value)
                    }}
                    >
                        <option value={""}>All</option>
                        {
                            statusList?.map((ele) =>{
                                return<option key={ele?.shippingStatusId} value={ele?.shippingStatusId}>{ele?.shippingStatus}</option>
                            })
                        }
                    </select>
                    {/* <Select
                        defaultValue="All"
                        style={{ width: '100%' }}
                        className="selectPicker"
                        options={[
                            {
                                value: 'All',
                                label: 'All',
                            }
                        ]}
                    /> */}
                </Col>
            </Row>
            {isLoading ? <PaginatedTable
                row={orders}
                column={column}
                totalCount={totalCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                isFilterHeader={true}
                filterOptions={filterOptions}
                handleFilterSelection={handleFilterSelection}
                filterDefaultValue={filterDefaultValue}
                maxPageNumberLimit={maxPageNumberLimit}
                setMaxPageNumberLimit={setMaxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
                setMinPageNumberLimit={setMinPageNumberLimit}
                setpageloader={setpageloader}
                pageloader={pageloader}
                isvisible={false}
            /> : <div style={{ textAlign: "center" }}>Loading...</div>}
        </div>
    );
}

export default Order;