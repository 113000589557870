import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Skeleton, Table, Button, Select } from "antd";
import { ApiHelper, getToken, getUserData } from "../../../../utils/Helpers";
import HorizontalMenu from "../../../layout/Horizontalmenu";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import Emptymsg from "../../../layout/message/Emptymsg";
import { messages } from "../../../../utils/Common/Messages";
import { AppConfig } from "../../../../utils/Config/AppConfig";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";
import Rangepicker from "../../../layout/filter/Rangepicker";
import moment from "moment";
import "./timeClockManagement.less";
import Email from "./Email";
const { Content } = Layout;
const { Option } = Select;

const TimeClockManagement = () => {
  const { language } = getUserData();
  const userData = getUserData();
  const organizationId = userData ? userData.organizationId : 0;
  const lan = language ?? "EN";
  const [filterData, setFilterData] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    search: '',
    employeeId: '',
    perPage: 10,
    page: 1,
    type:1
  });
  const [data, setData] = React.useState([]);
  const [employeeData, setEmployeeData] = React.useState([]);
  const [totalReacord, setTotalReacord] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [mailModal,setMailModal] = React.useState(false)
  const [editData,setEditData] = React.useState({})
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [filterData]);

  useEffect(() => {
    getEmployeeList();
    // eslint-disable-next-line
  }, []);

  const searchData = (
    sDate = filterData.startDate,
    eDate = filterData.endDate,
  ) => {
    setFilterData({ ...filterData, startDate: sDate, endDate: eDate });
  };

  const nextData = (pagination) => {
    setFilterData({ ...filterData, perPage: pagination.pageSize, page: pagination.current });
  };

  const getData = async () => {
    setLoading(true);
    setData([]);
    setTotalReacord(0);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PAYROLL_LIST.service,
      ApiRoutes.PAYROLL_LIST.url,
      ApiRoutes.PAYROLL_LIST.method,
      ApiRoutes.PAYROLL_LIST.authenticate,
      {
        gmtTime: userData.time_gmt,
        employeeId: filterData.employeeId,
        organizationId: organizationId,
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        search: filterData.search,
        limit: filterData.perPage,
        offset: filterData.page,
        type:filterData.type
      }
    );
    if (response.code === 200) {
      if (response.data.data) {
        setData(response.data.data ?? []);
        setTotalReacord(response.data.total || response.data.data.length);
        setLoading(false);
      } else {
        setData([]);
        setTotalReacord(0);
        setLoading(false);
      }
    } else {
      setData([]);
      setTotalReacord(0);
      setLoading(false);
    }
  };

  const getEmployeeList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.TIME_CLOCK_EMPLOYEE_LIST.service,
      ApiRoutes.TIME_CLOCK_EMPLOYEE_LIST.url,
      ApiRoutes.TIME_CLOCK_EMPLOYEE_LIST.method,
      ApiRoutes.TIME_CLOCK_EMPLOYEE_LIST.authenticate,
      {
        organizationId: organizationId,
      }
    );
    if (response.code === 200) {
      if (response.data.data) {
        setEmployeeData(response.data.data ?? []);
      } else {
        setEmployeeData([]);
      }
    } else {
      setEmployeeData([]);
    }
  };

  const handleSendEmail = async(email,employeeId)=>{
    setEditData({
      employeeId,
      email
    })
    setMailModal(true)
  }

  const handleCancel = () => {
    setMailModal(false);
  };

  const employeeColumns = [
    {
      title: messages.dateRange[lan],
      dataIndex: "dateRange",
      key: "dateRange",
    },
    {
      title: messages.employeeName[lan],
      dataIndex: "name",
      key: "name"
    },
    {
      title: messages.userPin[lan],
      dataIndex: "employeePin",
      key: "employeePin"
    },
    {
      title: messages.ratePerHour[lan],
      key: "salaryPerHour",
      dataIndex: "salaryPerHour",
    },
    {
      title: messages.noOfHoursWorked[lan],
      key: "workingMinutes",
      dataIndex: "workingMinutes"
    },
    {
      title: messages.tip[lan],
      key: "totalTip",
      dataIndex: "totalTip"
    },
    {
      title: messages.totalWages[lan],
      key:"totalWages",
      dataIndex:"totalWages"

    },
    {
      title: messages.totalAmount[lan],
      key:"totalAmount",
      dataIndex:"totalAmount"
    },
    {
      title:messages.mailReport[lan],
      render:(data)=>{
        return <MailOutlined onClick={()=>handleSendEmail(data.email,data.employeeId)}/>
      }
    }
  ];

  const contractorColumns = [
    {
      title: messages.dateRange[lan],
      dataIndex: "dateRange",
      key: "dateRange",
      render:()=>{
        return `${filterData.startDate} - ${filterData.endDate}`
      }
    },
    {
      title: messages.contractorName[lan],
      dataIndex: "name",
      key: "name"
    },
    {
      title: messages.userPin[lan],
      dataIndex: "employeePin",
      key: "employeePin"
    },
    {
      title: messages.serviceRendered[lan],
      key: "totalSale",
      dataIndex: "totalSale"
    },
    {
      title: messages.percentage[lan],
      key: "contractPercent",
      dataIndex: "contractPercent"
    },
    {
      title: messages.tip[lan],
      key: "totalTip",
      dataIndex: "totalTip"
    },
    {
      title: messages.totalWages[lan],
      key:"totalWages",
      dataIndex:"totalWages"

    },
    {
      title: messages.totalAmount[lan],
      key:"totalAmount",
      dataIndex:"totalAmount"
    },
    {
      title:messages.mailReport[lan],
      render:(data)=>{
        return <MailOutlined onClick={()=>handleSendEmail(data.email,data.employeeId)}/>
      }
    }
  ];
  const downloadData = () => {
    let token = getToken()
    window.open(
      `${AppConfig.API_ENDPOINT}${ApiRoutes.PAYROLL_EXPORT.service}${ApiRoutes.PAYROLL_EXPORT.url}?employeeId=${filterData.employeeId}&organizationId=${organizationId}&startDate=${moment(filterData.startDate).format(AppConfig.DATEFOMAT)}&endDate=${moment(filterData.endDate).format(AppConfig.DATEFOMAT)}&search=${filterData.search}&gmtTime=${userData.time_gmt}&type=${filterData.type}&token=${token}`,
      "_blank"
    );
  };

  return (
    <>
      <HorizontalMenu />
      <Row
        className="filterBox"
        style={{ padding: "0 11px 14px 11px", margin: "0px" }}
        gutter={{ xs: 12, lg: 6 }}
      >
        <Col>
          <Rangepicker
            filterdata={searchData}
            startDate={filterData.startDate}
            endDate={filterData.endDate}
            lan={lan}
          />
        </Col>
        <Col xl={5} lg={5} xs={24}>
          <Select
            placeholder={messages.employeeName[lan]}
            showSearch
            allowClear
            showArrow="true"
            className="category"
            dropdownClassName="category"
            style={{ width: '100%' }}
            optionFilterProp="children"
            onChange={(e) => setFilterData({ ...filterData, employeeId: (e !== undefined) ? e : "" })}
          >
            {employeeData.length > 0 &&
              employeeData.map((item, key) => (
                <Option value={parseInt(item.employeeId)} key={key}>
                  {`${item.firstName} ${item.lastName}`}
                </Option>
              ))}
          </Select>
        </Col>
        <Col xl={5} lg={5} xs={24}>
        <Select
            placeholder={"Type"}
            showSearch
            showArrow="true"
            className="category"
            dropdownClassName="category"
            style={{ width: '100%' }}
            optionFilterProp="children"
            defaultValue={filterData.type}
            onChange={(e) => setFilterData({...filterData, type:e})}
          >
            <Option value={1}>Employee</Option>
            <Option value={2}>Contractor</Option>
  
          </Select>
        </Col>
        <Col className="pr-0 ml-auto">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadData}
          >
            {messages.Export[lan]}
          </Button>
        </Col>
      </Row>
      <Content className="content-body" style={{ minHeight: 200 }}>
        <Row>
          <Col lg={24} xs={24}>
            <div
              className="bg-white"
              style={{
                margin: "14px 14px 0px 14px",
                padding: 14,
                minHeight: 200
              }}
            >
              <Table
                columns={filterData.type===1?employeeColumns:contractorColumns}
                rowKey={(record) => record.walletTransactionHistoryId}
                dataSource={data}
                className="timeClockManagementTable"
                pageIndex={filterData.page}
                scroll={{ x: 'max-content' }}
                pagination={
                  {
                    pageSize: filterData.perPage,
                    total: totalReacord,
                    defaultCurrent: filterData.page,
                    pageSizeOptions: ['10', '20', '50'],
                    showSizeChanger: true,
                    locale: { items_per_page: `/ ${messages.page[lan]}` }
                  }
                }
                locale={{
                  emptyText: loading ? (
                    <Skeleton
                      paragraph={{ rows: 5 }}
                      loading={loading}
                      active
                    ></Skeleton>
                  ) : (
                    <Emptymsg
                      emptytitle={messages.emptyTitleTimeClockManagement[lan]}
                    />
                  )
                }}
                onChange={nextData}
              />
            </div>
          </Col>
        </Row>
      </Content>
      {
        mailModal?<>
        <Email
          filterData={filterData}
          editData={editData}
          isModalVisible={mailModal}
          handleCancel={handleCancel}
          lan={lan}
        />
        </>:<></>
      }
    </>
  );
};
export default TimeClockManagement;
