import { EnvironmentOutlined, FlagOutlined, IdcardOutlined, LoginOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ApiRoutes } from '../../../../utils/Config/ApiRoutes';
import AxiosService from '../../../../utils/Config/ApiService';
import QRCode from 'react-qr-code';
import { Spinner } from 'react-bootstrap';
import './distributor.less';
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from 'html2canvas';
import { AgentCard } from '../../../../utils/Common/AgentCard';

const ViewAgent = () => {

  const history = useHistory();
  const params = useParams();

  const [agentDetail, setAgentDetail] = useState(null)
  const [messageApi, contextHolder] = message.useMessage();

  const getAgentDetails = async () => {
    try {
      const response = await AxiosService.get(`${ApiRoutes.AGENT_DETAIL.service}${ApiRoutes.AGENT_DETAIL.url}${params.id}`);
      if (response.status === 200) {
        setAgentDetail(response.data.agentDetails)
      }
    } catch (error) {
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.error ?? error.response.data.message
      })
    }
  }

  const getAgentCard = async () => {
    try {
      const response = await AxiosService.get(`/distributor/agent/qr?id=${agentDetail?.agentUniqueId}`);
      
      if(response?.status == 200){
        const qrBody = AgentCard(response?.data?.data)
  
        const tempElement = document.createElement('div');
        tempElement.innerHTML = qrBody;
        
        function downloadURI(uri, name) {
          var link = document.createElement("a");
          link.download = name;
          link.href = uri;
          link.click();
          document.body.removeChild(tempElement)
        }
  
        const canvas = await html2canvas(document.body.appendChild(tempElement), { scrollX: 0, scrollY: -window.scrollY });
        var myImage = canvas.toDataURL("image/png");
        downloadURI("data:" + myImage, `${agentDetail?.agentUniqueId}.png`);
      }
    } catch (error) {
      console.log(error)
      messageApi.open({
        type: "error",
        content: error.response.data.error ?? error.response.data.message ?? error
      })
    }
  }

  useEffect(() => {
    getAgentDetails();
  }, [])

  return (
    <>
      {contextHolder}
      <div className="text-right mb-1">
        <Button
          type="primary"
          className="mr-3 btn--large"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <Button
          type="warning"
          className="btn--large"
          onClick={() => history.push(`/distributor/agentAdmin/edit/${params?.id}`)}
        >
          Edit
        </Button>
      </div>
      <Row gutter={8} className="mb-05">
        <Col span={8}>
          <div className="profileDetailsLeft bg-white d-flex justify-content-center alignCenter p-0"
          >
            {
              agentDetail?.agentUniqueId ?
                <div className='qrCodeContainer w-100 h-100 py-3'>
                  <div className='qrCode  h-100 d-flex justify-content-center alignCenter'><QRCode value={agentDetail?.agentUniqueId} /></div>
                  <div class="qrCodeDownload">
                    <div className='icon cursor-pointer' onClick={() => getAgentCard()}>
                      <DownloadOutlined />
                    </div>
                  </div>
                </div> :
                <Spinner />
            }
          </div>
        </Col>
        <Col span={16}>
          <div className="profileDetailsRight bg-white">
            <Row gutter={8}>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <IdcardOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Agent Digital ID</p>
                    <p>{agentDetail?.agentUniqueId}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Agent Name</p>
                    <p>{agentDetail?.agentName}</p>
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <PhoneOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Phone</p>
                    <p>
                      {agentDetail?.businessPhoneCode}{agentDetail?.businessPhone}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <FlagOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">
                      Country / State / City Name
                    </p>
                    <p>
                      {agentDetail?.countryName} / {agentDetail?.stateName} / {agentDetail?.cityName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <UserOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Distributor Name</p>
                    <p>
                      {agentDetail?.distributorName}
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <MailOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Email</p>
                    <p>{agentDetail?.agentEmail}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Address</p>
                    <p>{agentDetail?.streetAddress}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <LoginOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Status</p>
                    <p>{agentDetail?.status === 1 ? "Active" : "Inactive"}</p>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="displayFlex">
                  <div className="mr-3">
                    <EnvironmentOutlined />
                  </div>
                  <div>
                    <p className="fw-700 mb-0">Zipcode</p>
                    <p>{agentDetail?.zipcode}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ViewAgent