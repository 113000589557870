import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import loader from '../../assets/images/loading.gif'
// import "./../Common/loader.css"
import Spinner from 'react-bootstrap/Spinner';
const SupportLoader = () => {
    return (
      // <div className="pageLoader" id="loader">
      //   <img src={loader} alt="Loading..." />
      // </div>
      <div>
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
      </div>

    );
  };
  
  export default SupportLoader;
