import { Row, Col, Modal, Skeleton } from "antd";
import React, { useState } from 'react';
import {messages} from '../../../../utils/Common/Messages'

const InventoryDetails = (props) => {
    let {data,loading} = props
    return (
        <>
        <Modal
            title={"Inventory Details"}
            visible={props.isModalVisible}
            footer={null}
            onCancel={props.handleCancel}
        >
        { 
        loading ?
        <div style={{ padding: "10px"}}>
        <Skeleton paragraph={{ rows: 3 }} loading={loading}/>
        </div>
        :  
        <div className="bg-white productViewTable">
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th>Inventory Count</th>
                        <th>Expiration Date</th>
                    </tr>
                </thead>
                <tbody>
                    { data?.map((e) => {
                        return <tr>
                        <td>{e?.currentStock ? parseInt(e?.currentStock) : '0'}</td>
                        <td>{e?.expiryDt ?? "-"}</td>
                    </tr>
                    })}
                </tbody>
            </table>
        </div>
        }
      </Modal>

        </>
    )
} 
export default InventoryDetails