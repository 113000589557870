import { Col, Row, Collapse, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CaretRightOutlined, PlusOutlined , ArrowLeftOutlined} from "@ant-design/icons";
import AxiosService from "../../../../utils/Config/ApiService";
import SupportLoader from "../../../../utils/Config/SupportLoader";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";

const { Panel } = Collapse;

const OrderDetails = () => {
  const [orderDetails, setorderDetails] = useState({})
  const [productDetails, setproductDetails] = useState([])
  const [subPackage, setsubPackage] = useState([])
  const [isloader, setisloader] = useState(true)
  const history = useHistory();
  const orderid = useParams()?.id


  const handleGetOrderById = async () => {
    try {
      let res = await AxiosService.get(`${ApiRoutes.MERCHANT_ORDERS.service}${ApiRoutes.MERCHANT_ORDERS.url}/${orderid}`)
      if(res.status === 200){
        setorderDetails(res.data.orderDetails)
        setproductDetails(res.data.productDetails)
        setsubPackage(res.data.sub_package_product)
        setisloader(false)
      }
    } catch (error) {
      console.log(error);
      setisloader(false)
    }
  };

  useEffect(() =>{
    handleGetOrderById()
  },[])

  return (
    <>
      <div className="adminContentSection">
        {
          isloader ? <SupportLoader/> : 
          <>
          <Row gutter={8}>
          <Col span={24}>
            <div className="displayFlex mt10 mb-05 spaceBetween">
              <h4 className="text-uppercase">Order Status : {orderDetails?.shippingStatus.toUpperCase()}</h4>
         
                <Button type="warning" className='mr-3 btn--large d-flex align-items-center'
                onClick={() => {
                  history.goBack()
                }}
                ><ArrowLeftOutlined /> Back</Button>
              </div>
          </Col>
        </Row>
        <Row gutter={8} className="mb-05">
          <Col span={24}>
            <Collapse
              bordered={true}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition={"right"}
              className="order-collapse"
            >
              <Panel
                header="Order Details"
                key="1"
                className="site-collapse-custom-panel"
              >
                <p>
                  <span className="fw-600 text-grey">Store Name :</span>{" "}
                  <span>{orderDetails?.organizationName}</span>
                </p>
                <p>
                  <span className="fw-600 text-grey">Order ID :</span>{" "}
                  <span>{orderDetails?.merchantOrderId}</span>
                </p>
                <p>
                  <span className="fw-600 text-grey">Amount :</span>{" "}
                  <span>{orderDetails?.amount}</span>
                </p>
                <p>
                  <span className="fw-600 text-grey">Shipping Address :</span>{" "}
                  <span>{orderDetails?.shippingAddress}</span>
                </p>
                <p>
                  <span className="fw-600 text-grey">Contact Person :</span>{" "}
                  <span>{orderDetails?.contactPerson}</span>
                </p>
                <p>
                  <span className="fw-600 text-grey">Contact Phone :</span>{" "}
                  <span>{orderDetails?.businessPhoneCode + "-" + orderDetails?.businessPhone}</span>
                </p>
                <p>
                  <span className="fw-600 text-grey">Contact Email :</span>{" "}
                  <span>{orderDetails?.email}</span>
                </p>
              </Panel>
              <Panel
                header="Product Details"
                key="2"
                className="site-collapse-custom-panel"
              >
              {
                (productDetails?.length > 0) ? 
                productDetails?.map((product, i) => {
                  return <p key={i}>
                    <span className="fw-600 text-grey">Product Name :</span>{" "}
                    <span>{product?.name}</span>{" "}
                    <span className="fw-600 text-grey">Sell Price :</span>{" "}
                    <span>{orderDetails?.currencySign}{product?.sellPrice}</span>{" "}
                    <span className="fw-600 text-grey">Qty :</span>{" "}
                    <span>{product?.productQty}</span>{" "}
                    <span className="fw-600 text-grey">Total Price :</span>{" "}
                    <span>{orderDetails?.currencySign}{product?.productTotalPrice}</span>
                  </p>
                
              
                })

                : <></>
              }
                {
                  (subPackage?.length>0) ? 
                  subPackage?.map((sub, i) => {
                    return <p key={i}>
                
                    <span className="fw-600 text-grey">Product Name :</span>{" "}
                    <span>{sub?.packageName}</span>{" "}
                    <span className="fw-600 text-grey">Product Content :</span>{" "}
                    <span>({sub?.packageContent})</span>{" "}
                    {/* <span className="fw-600 text-grey">Qty :</span>{" "}
                    <span>1</span>{" "} */}
                    <span className="fw-600 text-grey">Total Price :</span>{" "}
                    <span>{orderDetails?.currencySign}{sub?.firstMonthPurchasePrice}</span>
                
                  {/* <Button type="warning" className="d-flex align-items-center">
                    <PlusOutlined />
                  </Button> */}
                </p>
                  })
                  : <></> 
                }
                
              </Panel>
            </Collapse>
          </Col>
        </Row>
          </>
        }
      </div>
    </>
  );
};
export default OrderDetails;
