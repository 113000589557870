/* eslint-disable no-lone-blocks */
import { Button, Col, Row, Form, Input } from 'antd';
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import popupclose from "../../../../../assets/images/support/popupclose-icon.svg";
import {
  PhoneOutlined,
  ShopOutlined,
  FlagOutlined,
  EnvironmentOutlined,
  MailOutlined,
  UserOutlined,
  LoginOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined ,
  EyeOutlined
} from "@ant-design/icons";
import AxiosService from '../../../../../utils/Config/ApiService';
import SupportLoader from '../../../../../utils/Config/SupportLoader';
import axios from 'axios';
import { ApiRoutes } from '../../../../../utils/Config/ApiRoutes';


const StaffProfile = () => {
  let [addToggle, setAddToggle] = useState(false);
  // let [resetToggle, setResetToggle] = useState(false);
  let [isloader, setisloader] = useState(true)

  const [staff, setstaff] = useState({})
  const history = useHistory()
  const staffId = useParams()?.id;
  const [country, setcountry] = useState("")
  const [state, setstate] = useState("")
  const [city, setcity] =  useState("")

  const [resetPasswordToggle, setresetPasswordToggle]=useState(false)
  const [formData, setFormData] = useState({})
  const [resetToggle, setresetToggle] = useState(false)
  const [passwordToggle, setPasswordToggle] = useState(false)
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false)
  const [verifyPassword, setVerifyPassword] = useState(false)
  // const [employeeId, setEmployeeId] = useState()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [conPassValue, setConPassValue]= useState("")
  const [submitloader,setsubmitloader] = useState(false)
  const [pin, setPin] = useState()
  const [form] = Form.useForm();
  const userData = JSON.parse(sessionStorage.getItem('userData'))
  const supportAdminId = userData.employeeId
  const [revealLoader, setRevealLoader] = useState(false)
  sessionStorage.setItem('activePageKey',"1")

  const getStaffById = async () => {
    try{
      let res = await AxiosService.get(`${ApiRoutes.STAFF.service}${ApiRoutes.STAFF.url}/${staffId}`)
      if(res.data.statusCode === 200){
        setstaff(res.data.employeeDetails)
        setisloader(false)
      }
      

    }catch(error){
      console.log(error)
    }
  }

  const handleRevealPin = async() =>{
    try{
      let res = await AxiosService.post(`${ApiRoutes.STAFF_REVEAL_PIN.service}${ApiRoutes.STAFF_REVEAL_PIN.url}`,{
        "employeeId" : staffId,
        "supportAdminId" : supportAdminId
      }
      )
      if(res.status === 200){
       setPin(res.data.employeePin)
       setAddToggle(true)
       setRevealLoader(false)
      }
    }catch(error){
      console.log(error)
      setRevealLoader(false)
    }
  }
  const handleResetPassword = async() =>{
    try{
      const formData = new FormData()
      formData.append('employeeId', Number(staffId)) ;
      formData.append('organizationId', Number(staff?.organizationId) ) ;
      formData.append('password', password ) ;
              //to generate store notes
              let data = { 
                "organizationId":Number(staff?.organizationId),
                "title":"Password Reset",
                "notes":`Password Reset of ${staff?.userName}`,
                "createdBy":Number(supportAdminId)
            }

      let res  =  await axios.post(`${process.env.REACT_APP_ROOT_URL}/apiv11/auth/changePassword`,formData,
      {
       headers:{
           'Content-Type': 'multipart/form-data',
           authorization: `Basic ${process.env.REACT_APP_PHP_Auth_Token}`
       }
      }
      )
        
      if(res.data.success === 1){
        let notesRes = await AxiosService.post(`${ApiRoutes.STORE_NOTES.service}${ApiRoutes.STORE_NOTES.url}`,data )
        if(notesRes.status === 200){
          form.resetFields()
          setresetToggle(false)
          setresetPasswordToggle(false)
          setsubmitloader(false)
          alert(res?.data?.response?.message)
        }

      }else{
        alert("Password reset failed")
        // form.resetFields()
        // setresetToggle(false)
        setsubmitloader(false)
        // setresetPasswordToggle(false)
      }

    }catch(error){
        // console.log(error)
        setsubmitloader(false)
    }
}
console.log("token", `${process.env.REACT_APP_PHP_Auth_Token}`)
  useEffect(() =>{
    getStaffById()
  },[])
  return (
    <>
      <div className="adminContentSection">
        <Row gutter={8}>
          <Col span={24}>
            <div className='displayFlex mt10 mb-05 spaceBetween'>
              <div>
                <Button type="warning" className='mr-3 btn--large d-flex align-items-center'
                onClick={() => {
                  history.goBack()
                }}
                ><ArrowLeftOutlined /> Back</Button>
              </div>
              <div>
                <Button type="primary" className='mr-3 btn--large' onClick={() => {
                  history.push(`/support/store/staffEdit/${staffId}`)
                }}>Edit</Button>
                <Button type="primary" className='mr-3 btn--large' onClick={() => setresetPasswordToggle(true)}>Reset</Button>
                <Button
                 type="primary"
                  className='btn--large' 
                  onClick={() => {{
                 setRevealLoader(true)
                  handleRevealPin()
                  }}}>{revealLoader ? <SupportLoader/> : "Reveal"}</Button>
              </div>

            </div>
          </Col>
        </Row>
{
  isloader ? <Row>
  <SupportLoader/>
  </Row>
 : 
  <Row gutter={8}>
  <Col span={8}>
    <div className='profileDetailsLeft bg-white'>
      <div className='shortname'>
        {staff?.firstName?.slice(0,1).toUpperCase()}{staff?.lastName?.slice(0,1).toUpperCase()}
      </div>
      <div className='fullname'>
      {staff?.firstName} {staff?.lastName}
      </div>
    </div>
  </Col>
  <Col span={16}>
    <div className='profileDetailsRight bg-white'>
      <Row gutter={8}>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <ShopOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Merchant Name</p>
            <p onClick={() => history.push(`/support/store/${staff['organizationId']}`)} style={{cursor : "pointer", color : "blue"}}>{staff?.organizationName} </p>
              {/* <Link to= {`/support/merchant/store/${staff['organizationId']}`} className="btn--small btn--view"><EyeOutlined /></Link> */}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <PhoneOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Phone</p>
              <p>{staff?.businessPhoneCode+"-"+staff?.businessPhone}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <FlagOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Country / State / City Name</p>
              <p>{staff?.countryName?staff?.countryName:"-" } { " / " } {staff?.stateName !== null ? staff?.stateName : "-" }  {" / "} {staff?.cityName !== null ? staff?.cityName : "-" }</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <UserOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Username</p>
              <p>{staff?.userName}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <MailOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Email</p>
              <p>{staff?.email}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <EnvironmentOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Address</p>
              <p>{staff?.addressLine1}</p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className='displayFlex'>
            <div className='mr-3'>
              <LoginOutlined />
            </div>
            <div>
              <p className='fw-700 mb-0'>Organization Pin</p>
              <p>{staff?.organizationLoginId ? staff?.organizationLoginId : "--"}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Col>
</Row>
}
        {addToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock divoverlaySmallBlock">
                <div className="container-fluid">
                  <div className="text-center p-x8-y16">
                    <h2 className='m-10 fs-15'>Employee Pin</h2>
                    <h3 className='m-10 text--grey'>{pin?.length  ? pin : "No data available"}</h3>
                    <Button type="primary" className='btn--large m-10' onClick={() => setAddToggle(false)}>Ok</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

{resetPasswordToggle ? (
                <div>
                    <div className="divoverlay">
                        <div className="divoverlayBlock">
                            <div className="popupheaderblock">
                                <h5> Reset Password </h5>
                                <img
                                    src={popupclose}
                                    alt="Close"
                                    className="cursorPointer"
                                    style={{cursor : "pointer"}}
                                    onClick={() => {setresetPasswordToggle(false)
                                    form.resetFields()
                                    }}
                                />
                            </div>
                            <div className="container-fluid">
                                <div className="overdivScroll">
                                    <div className="row mb40">
                                        <Form
                                        form={form}
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            wrapperCol={{
                                                span: 16,
                                            }}
                                            style={{
                                                maxWidth: 600,
                                                margin: '10px 20px'
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            autoComplete="off"
                                            className='supportForm'
                                            onFinish={(values) =>{
                                                setFormData(values)
                                            }}
                                        >
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                type="password"
                                               
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the new password',
                                                    },
                                                ]}
                                            >
                                                <span  style={{display : "flex"}}>
                                                <Input
                                                 type={passwordToggle ?"text": "password"}
                                                 onChange={(e) =>{
                                                    setPassword(e.target.value)
                                              
                                                    setVerifyPassword(e.target.value === conPassValue);
                                                 }}
                                                placeholder='Enter New Password'/>
                                                <EyeOutlined
                                                  onClick={() =>{
                                                    setPasswordToggle(prev => !prev)
                                                 }} className='passwordIcon'
                                                />
                                                </span>
                                               
                                            </Form.Item>

                                            <Form.Item
                                                label="Confirm Password"
                                                name="confirm Password"
                                                style={{display : "flex"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the confirm password',
                                                    },
                                                ]}
                                            >
                                                <span style={{display : "flex"}}>
                                                <Input
                                                  type={confirmPasswordToggle ? "text" :"password"}
                                                  name="confirm Password"
                                                  onChange={(e) => {
                                                    setConfirmPassword(true);
                                                    setConPassValue(e.target.value);
                                                    setVerifyPassword(e.target.value === password);
                                                }}
                                                 placeholder='Enter Confirm Password'/>
                                                 <EyeOutlined 
                                                 onClick={() =>{
                                                    setConfirmPasswordToggle(prev => !prev)
                                                 }} className='passwordIcon'
                                                 />

                                                </span>

                                               {
                                                !verifyPassword && confirmPassword && (
                                                    <span style={{color : "red"}}>Passwords doesnot match</span>
                                                )
                                               }
                                               
                                            </Form.Item>
                                     

                                            <Form.Item
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 16,
                                                }}
                                                className='text-right'
                                            >
                                                 <div className='displayFlex justifyflexend alignCenter'>
                                                <Button type="default" className='mr-3 btn--cancel btn--large'
                                                onClick={() => {
                                                    form.resetFields()
                                                    setresetPasswordToggle(false)}}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button type="primary" htmlType="submit" 
                                                onClick={(e)=>{
                                                    if(verifyPassword){
                                                        setresetToggle(true)
                                                    }else if (!verifyPassword){
                                                        e.preventDefault()
                                                    }
                                                  
                                                }}
                                                className=' btn--large'>
                                                   {/* {submitloader ? <SupportLoader/> : "Submit"}  */}
                                                   Submit
                                                </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )
        }
        {resetToggle ? (
          <div>
            <div className="divoverlay">
              <div className="divoverlayBlock divoverlaySmallBlock">
                <div className="container-fluid">
                  <div className="text-center p-x8-y16">
                    <ExclamationCircleOutlined color='#30649B' className='text--primary fs--5'/>
                    <h1 className='m-10 fs-15'>Are you sure?</h1>
                    <p className='m-10 fs--1'>You want to reset password</p>
                    <div className='d-flex alignCenter justify-content-center'>
                    <Button type="primary" className='btn--large btn--cancel m-10' onClick={() => {{
                        form.setFieldsValue({
                            "confirm Password" : conPassValue
                        })
                        setresetToggle(false)}}}>Cancel</Button>
                    <Button type="primary" className='btn--large m-10' onClick={() => {
                        // eslint-disable-next-line no-lone-blocks
                            {
                                setsubmitloader(true)
                                handleResetPassword()
                            }}
                        }>
                                       {submitloader ? <SupportLoader/> : "OK"} 
                            </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export default StaffProfile